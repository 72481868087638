import axios from 'axios';
/**
 * @author José R. Gutierrez
 * @param {string} params.user
 * @param {string} params.password
 */
export function doLogin(params) {
  return axios.post(process.env.VUE_APP_LOGIN_API_URL, { ...params });
}

/**
 * @author Ricardo Manzano
 * @param {string} params.user
 * @param {string} params.password
 */
export function doLoginGS(params) {
  return axios.post(process.env.VUE_APP_LOGIN_API_URL_GS, { ...params });
}

/**
 * @author Ricardo Manzano
 * @param {string} params.user
 * @param {string} params.password
 */
export function doLogin3pv(params) {
  return axios.post(process.env.VUE_APP_LOGIN_API_URL_3PV, { ...params });
}

/**
 * @author José R. Gutierrez
 */
export function doLogout(is3pv) {
  return axios.post(is3pv ? process.env.VUE_APP_LOGOUT_API_URL_3PV : process.env.VUE_APP_LOGOUT_API_URL);
}
