import axios from 'axios';
import token from '@/data/user/token';

export async function fetchLastTermsVersion() {
  try {
    const response = await axios.get('https://wqy8tz8yuc.execute-api.us-west-2.amazonaws.com/prod/terms/0', {
      headers: { Authorization: token.free },
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
}
