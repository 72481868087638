import { fetchRetry } from '@/utils/api/fetchRetry';

/**
 * @author Ricardo Manzano
 */
export async function getClashes({ game }) {
  try {
    const url = `https://y515cvq1mg.execute-api.us-east-2.amazonaws.com/prod/last-confrontations/games/${game}`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getBetPerResult() {
  try {
    // const url = `https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays`;
    const url = `https://3ph9itfr6f.execute-api.us-east-2.amazonaws.com/qa/parlays`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getFeaturedPlayers(season_id, category_id, home_team, away_team) {
  try {
    const url = `https://8grl16o77j.execute-api.us-west-2.amazonaws.com/prod/topsBySeasonAndCategory/${season_id}/${category_id}?ld=1&t=${home_team}&t=${away_team}`;
    // const url = `https://1g4bghouwc.execute-api.us-east-2.amazonaws.com/qa/topsBySeasonAndCategory/${season_id}/${category_id}?ld=1&t=${home_team}&t=${away_team}`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getFacetoFace(season_id, team_id) {
  try {
    const url = `https://075dav9ek5.execute-api.us-east-2.amazonaws.com/qa/statisticsTeamBySeason/${season_id}/${team_id}?ld=1`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchTeamStatsBySeason({ seasonId, teamId, query }) {
  try {
    const url = `https://075dav9ek5.execute-api.us-east-2.amazonaws.com/qa/statisticsTeamBySeason/${seasonId}/${teamId}?${query}&ld=1`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchLastFiveGames({ gameId }) {
  try {
    const url = `https://jqwd6497md.execute-api.us-east-2.amazonaws.com/prod/last-five-games/${gameId}`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getBetParlayFree() {
  try {
    const url = `https://4bxp9xv53l.execute-api.us-east-2.amazonaws.com/prod/breakdowns/parlay/tournaments/1`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTipsParlayFree() {
  try {
    const url = `https://4bxp9xv53l.execute-api.us-east-2.amazonaws.com/prod/breakdowns/tips/tournaments/1`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchParlayPremium() {
  try {
    const url = 'https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/v3/parlays';
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getBetsByTypeApi(game, type) {
  try {
    const url = `https://hslar0fqod.execute-api.us-east-2.amazonaws.com/prod/games/${game}/markets?btc=${type}`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchParlays() {
  try {
    // https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays
    const url = `https://3ph9itfr6f.execute-api.us-east-2.amazonaws.com/qa/parlays`;
    const response = await fetchRetry({ url });
    return response && response.data ? response.data : [];
  } catch (e) {
    return Promise.reject(e);
  }
}
