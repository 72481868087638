import addMinutes from 'date-fns/addMinutes';
import { doLogin, doLogout, doLoginGS, doLogin3pv } from '@/utils/api/login';
import token from '@/data/user/token';
import router from '@/router';
import axios from 'axios';

// Time To Live minutes for the user's session. 120 minutes = 2 hours.
const TTL = 120;
const state = {
  user: {
    name: null,
    token: null,
    premiumAccount: false,
    golstats: 0,
    sessionExpiration: null,
    isB2CUser: false,
    provider: 1,
  },
  isGS1: false,
  isGS1Account: '',
  is3pv: false,
  urlGS1: '',
  pymErr: false,
  lastRouteBeforeLogout: null,
};

const mutations = {
  setLastRouteBeforeLogout(state, lastRouteBeforeLogout) {
    if (lastRouteBeforeLogout.name !== 'payment-methods') {
      state.lastRouteBeforeLogout = lastRouteBeforeLogout;
    }
  },
  setUser(state, user) {
    state.user = user;
    axios.defaults.headers.common['Authorization'] = user.token;
  },
  setIsGS1(state, data) {
    if (data.type === 1) {
      state.isGS1 = data.val;
      state.isGS1Account = data.acount;
      window.localStorage.setItem('isGS1', data.val);
      window.localStorage.setItem('isGS1Account', data.acount);
    } else {
      if (data.val != null) {
        state.isGS1 = data.val;
      }
      if (data.url != null) {
        state.urlGS1 = data.url;
      }
      if (data.acount != null) {
        state.isGS1Account = data.acount;
      }
    }
  },
  setIs3pv(state, data) {
    if (data.type == 1) {
      state.is3pv = data.val;
      window.localStorage.setItem('is3pv', data.val);
    } else {
      if (data.val != null) {
        state.is3pv = data.val;
      }
    }
  },
  setResetIsGS1(state) {
    state.isGS1 = false;
    state.urlGS1 = '';
    state.isGS1Account = '';
  },
  setResetIs3pv(state) {
    state.is3pv = false;
  },
  setPymErr(state, pymErr) {
    state.pymErr = pymErr;
  },
};

const actions = {
  /**
   * @author Alejandro
   * @param {Object} context
   * @param {Object} params
   * @param {Object} params.form
   * @param {string} params.form.user
   * @param {string} params.form.password
   * @return {Promise<boolean>}
   */
  async doLogin({ dispatch }, params) {
    let isLoggedInGS2 = false;
    try {
      if (typeof params.loggedInGS2 !== 'undefined') {
        isLoggedInGS2 = params.loggedInGS2;
      }
      const result = await doLogin(params.form);
      const loginData = result.data;
      if (![1, 4].includes(loginData.user_type)) {
        await dispatch('prepareStoreUserInfoLocal', result.data);
        const ALERT = {
          type: 'info',
          message: 'Se ha iniciado correctamente la sesi&oacute;n.',
        };
        await dispatch('alertGeneral/setAlert', ALERT, { root: true });
      }
      return loginData;
    } catch (e) {
      if (!isLoggedInGS2) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message,
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
      }
      return false;
    }
  },
  /**
   * @author Alejandro
   * @param {Object} context
   * @param {Object} params
   * @param {string} params.userEncoded
   * @param {string} params.login.user
   * @param {string} params.login.password
   * @return {Promise<boolean>}
   */
  async doLoginGS2(/*{ commit, state},*/ _, params) {
    try {
      const response = await doLoginGS(params.login);
      // await commit('setIsGS1', { val: true, data: params.login, type: 1 });
      // state.isGS1Account = params.name;
      // window.localStorage.setItem('gs2User', params.userEncoded);
      // window.localStorage.setItem('isGS1Account', params.login.user);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  /**
   * @author Ricardo
   * @param {Object} context
   * @param {Object} params
   * @param {string} params.user
   * @param {string} params.password
   * @return {Promise<boolean>}
   */
  async doLogin3pv({ dispatch, commit }, params) {
    try {
      const result = await doLogin3pv(params);
      if (result.data) {
        await dispatch('prepareStoreUser3pvInfoLocal', result.data);
        await commit('setIs3pv', { val: true, type: 1 });
      }
      const ALERT = {
        type: 'info',
        message: 'Se ha iniciado correctamente la sesi&oacute;n.',
      };
      await dispatch('alertGeneral/setAlert', ALERT, { root: true });
      return true;
    } catch (e) {
      const ALERT = {
        type: 'error',
        message: 'El usuario no existe',
      };
      await dispatch('alertGeneral/setAlert', ALERT, { root: true });
      return false;
    }
  },
  /**
   * @author José R. Gutierrez
   * @param commit
   * @param dispatch
   * @param state
   * @param {Object} payload
   * @param {boolean} payload.reloadPage
   * @param {boolean} payload.is3pvLogout
   * @param {boolean} payload.isGS2Logout
   * @returns {Promise<void>}
   */
  async doLogout(
    { commit, dispatch, state },
    payload = { reloadPage: false, is3pvLogout: false, requestStatus: 0, isGS2Logout: false },
  ) {
    await commit('setIsSectionLoading', true, { root: true });
    try {
      if (
        !payload.isGS2Logout &&
        axios.defaults.headers.common['Authorization'] !== token.free &&
        payload.requestStatus !== 403
      ) {
        await doLogout(payload.is3pvLogout);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      const USER = {
        name: null,
        token: null,
        premiumAccount: false,
        sessionExpiration: null,
        isB2CUser: false,
        provider: 1,
      };
      window.localStorage.setItem('user', window.btoa(JSON.stringify(USER)));
      window.localStorage.removeItem('is3pv');
      window.localStorage.removeItem('isGS1');
      window.localStorage.removeItem('urlGS1');
      window.localStorage.removeItem('gs2User');
      window.localStorage.removeItem('isGS2User');
      window.localStorage.removeItem('gs1UserP1');
      window.localStorage.removeItem('gs1UserP2');
      window.localStorage.removeItem('isGS1User');
      window.localStorage.removeItem('sysType');
      await commit('setUser', USER);
      await commit('setResetIsGS1');
      await dispatch('deleteUserInfo');
      axios.defaults.headers.common['Authorization'] = token.free;
      const ALERT = {
        type: 'info',
        message: 'Has salido correctamente.',
      };
      if (!payload.isGSLogout && router.app._route.name !== 'calendarGS') {
        if (payload.is3pvLogout) {
          if (localStorage.getItem('widget-team')) {
            router.replace({ name: 'widget', params: { team: localStorage.getItem('widget-team') }, replace: true });
          } else {
            await router.replace({ name: 'viewAll' });
          }
        } else if (
          (!state.lastRouteBeforeLogout || !state.lastRouteBeforeLogout.meta.stayOnLogout) &&
          router.app._route.path !== '/'
        ) {
          await router.replace({ name: 'viewAll' });
        } else if (router.app._route.name === 'payment-methods') {
          await router.replace({ name: state.lastRouteBeforeLogout.name });
        } else if (payload.reloadPage) {
          window.location.reload();
        }
      }
      await dispatch('alertGeneral/setAlert', ALERT, { root: true });
      await commit('setIsSectionLoading', false, { root: true });
    }
  },
  prepareStoreUserInfoLocal({ dispatch, commit }, data) {
    const INFO = data.account_name.split('_');
    const USER_NAME = INFO[0];
    const DATE = INFO.slice(-1)[0];
    const TODAY = new Date();
    const YEAR = TODAY.getFullYear().toString();
    const PREMIUM_ACCOUNT = DATE.length === 6 || DATE.search(YEAR) === 0;
    const USER = {
      name: USER_NAME,
      token: data.token,
      provider: data.provider,
      golstats: data.user_type,
      // todo: change true to Premium_account variable
      premiumAccount: PREMIUM_ACCOUNT,
      sessionExpiration: addMinutes(new Date(), TTL).getTime(),
      isB2CUser: DATE.length === 8,
    };
    if (data.pym_err === 1) commit('setPymErr', true);
    dispatch('storeUserInfoLocal', USER);
  },
  prepareStoreUser3pvInfoLocal({ dispatch, commit }, data) {
    const USER = {
      name: data.name,
      token: data.token,
      // todo: change true to Premium_account variable
      sessionExpiration: addMinutes(new Date(), TTL).getTime(),
      link: data.link,
      id: data.id,
      isB2CUser: false,
    };
    if (data.pym_err === 1) commit('setPymErr', true);
    dispatch('storeUserInfoLocal', USER);
  },
  async loginPostRegister({ dispatch, commit }, data) {
    await commit('setIsSectionLoading', true, { root: true });
    const USER = {
      name: data.name,
      token: data.token,
      premiumAccount: true,
      sessionExpiration: addMinutes(new Date(), TTL).getTime(),
      isB2CUser: true,
      provider: data.provider,
    };
    await dispatch('storeUserInfoLocal', USER);
    await commit('auth/SET_AUTH_COMPONENT', null, { root: true });
    await commit('setIsSectionLoading', false, { root: true });
  },
  storeUserInfoLocal({ commit }, user) {
    window.localStorage.setItem('user', window.btoa(JSON.stringify(user)));
    commit('setUser', user);
  },
  deleteUserInfo({ commit }) {
    const USER = {
      name: null,
      token: null,
      premiumAccount: false,
      sessionExpiration: null,
      isB2CUser: false,
      provider: 1,
    };
    window.localStorage.removeItem('user');
    commit('setPymErr', false);
    commit('setUser', USER);
    commit('profileGeneral/updateCards', [], { root: true });
    commit('profileGeneral/setCurrentPlanId', null, { root: true });
    commit('profileGeneral/setProfilePlans', [], { root: true });
  },
  getUserInfoLocal({ commit }) {
    let user = window.localStorage.getItem('user');
    if (user) {
      user = JSON.parse(window.atob(user));
      commit('setUser', user);
    } else {
      axios.defaults.headers.common['Authorization'] = token.free;
    }
  },
  getDataUserGS1({ commit }) {
    let isGS1 = window.localStorage.getItem('isGS1');
    let urlGS1 = window.localStorage.getItem('urlGS1');
    let urlGS1Acount = window.localStorage.getItem('isGS1Account');
    commit('setIsGS1', { val: isGS1, url: urlGS1, acount: urlGS1Acount, type: 0 });
  },
  getDataUser3pv({ commit }) {
    let user = window.localStorage.getItem('user');
    let is3pv = window.localStorage.getItem('is3pv');
    if (is3pv) {
      if (is3pv === 'true') {
        if (user) {
          router.push({ name: 'main3pv' }, null, { shallow: true });
          commit('setIs3pv', { val: is3pv, type: 0 });
        } else {
          router.push({ name: 'login3pv' }, null, { shallow: true });
        }
      }
      // else { temporal
      //   router.push({ name: 'login3pv' }, null, { shallow: true });
      // }
    }
  },
};

const getters = {
  seasonId(state) {
    return state.user ? state.user.season : 1401;
  },
  isUserSessionLive(state) {
    return state.user ? state.user.sessionExpiration >= new Date().getTime() : false;
  },
  getPremiumAccount(state) {
    return state.user.premiumAccount;
  },
  getIsGolstats(state) {
    return state.user.golstats;
  },
  isLoggedIn(state) {
    return Boolean(state.user.token);
  },
  isB2CUser(state) {
    return state.user.isB2CUser;
  },
  isGS1(state) {
    return state.isGS1 || window.localStorage.getItem('isGS1');
  },
  isGS1Account(state) {
    return state.isGS1Account;
  },
  urlGS1(state) {
    return state.urlGS1;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
