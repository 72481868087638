/**
 * @author José Rafael Gutierrez
 * @param play
 * @param indexes
 * @param categories
 * @param isReceived
 * @returns {*[]}
 */
export function playStructure(play, indexes, categories, isReceived = false) {
  const CATEGORIES = isReceived ? 'categoriesOpponent' : 'categoryValue';
  const KEY = isReceived ? 'categoryKeyOpponent' : 'categoryKey';
  const CATEGORY = categories.find(cat => cat[CATEGORIES].includes(play[indexes.categoryId]));
  const CATEGORY_ID = CATEGORY ? CATEGORY[KEY] : null;
  return [
    CATEGORY_ID,
    play[indexes.teamId],
    play[indexes.matchResult],
    play[indexes.playingAs],
    play[indexes.matchLapse],
    play[indexes.section],
    play[indexes.total],
  ];
}
