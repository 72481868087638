/**
 * @author Fernando Mellone
 * @description Creates an object based on "headers" as keys and "filters" filters values
 * @param {Array<string>} headers each item represents a key
 * @param {Array<number>} play each item represents a value
 * @return {Object}
 */
export const formatPlay = (headers, play) => {
  let formattedPlay = {};
  headers.forEach((header, index) => {
    if (index == 3) {
      if (play[0] == 35 || play[0] == 54) {
        if (play[index] == 2) {
          play[index] = 1;
        } else {
          play[index] = 2;
        }
      }
    }
    if (index == 2) {
      if (play[0] == 35 || play[0] == 54) {
        if (play[index] == 1) {
          play[index] = 2;
        } else {
          if (play[index] == 2) {
            play[index] = 1;
          }
        }
      }
    }
    formattedPlay[header] = play[index];
  });
  return formattedPlay;
};

/**
 * @author Fernando Mellone
 * @param {Array<string>} plays
 * @param {Array<number>} filters
 * @return {Object}
 */
export const filterPlays = (plays, filters) => {
  let result = plays.filter(play => {
    const playingAs = filters.playingAs.includes(play.playing_as);
    const matchResult = filters.matchResult.includes(play.match_result);
    const matchLapse = filters.sections.includes(play.section);

    const allFilters = playingAs && matchResult && matchLapse;
    return allFilters;
  });

  return result;
};
