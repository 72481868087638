import { fetchRetry } from '@/utils/api/fetchRetry';

export async function getLeaguePlaysBySeason({ seasonId }) {
  try {
    const response = await fetchRetry({
      url: `https://iwxfzq6e2h.execute-api.us-west-2.amazonaws.com/prod/statisticsLeagueBySeason/${seasonId}`,
    });
    return response.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getLeagueStatisticsBySeason({ seasonId }) {
  try {
    const response = await fetchRetry({
      url: `https://7a8s1p4177.execute-api.us-west-2.amazonaws.com/prod/statisticsGroupedBySeason/${seasonId}`,
    });
    return response.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getLeagueStatisticsGroupedBySeason({ seasonId }) {
  try {
    const response = await fetchRetry({
      url: `https://sqf4rkvoge.execute-api.us-west-2.amazonaws.com/prod/statisticsGroupedBySeason/${seasonId}`,
    });
    return response.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
