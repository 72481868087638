import NGTeamData from '@/utils/classes/NGTeamData';
/**
 * @author Alejandro
 * @description This class contains the data structure for each next games's subcategory
 */
export default class NGSubcategoryData {
  id = '';
  generalId = 0;
  // This names refers to 0 = accomplished and 1 = received.
  names = {
    ['0-0']: '',
    ['1-1']: '',
    ['0-1']: '',
    ['1-0']: '',
  };
  mentions = {
    ['0-0']: '',
    ['1-1']: '',
    ['0-1']: '',
    ['1-0']: '',
  };
  accomplishedCategoryIds = [];
  receivedCategoryIds = [];
  generalCategoriesIds = { accomplished: 0, received: 0 };
  specialMention = '';
  homeTeam = new NGTeamData();
  visitingTeam = new NGTeamData();
  isLeagueDataCalculated = false;
  leagueAccomplishedMax = 0;
  leagueReceivedMax = 0;
  leagueAccomplishedAvg = 0;
  leagueReceivedAvg = 0;
  leagueAccomplishedAccumulator = 0;
  leagueReceivedAccumulator = 0;
  homeTeamType = 'homeTeam';
  visitingTeamType = 'visitingTeam';
  betOnly = false;

  /**
   * @author Alejandro
   * @param {Array<(number|string)>} accomplishedCategoryIds - The accomplished category ids to be evaluated on each play,
   * can be a number or a string in the form of 'categoryId_r' e.g. '35_r', _r refers to rival plays.
   * @param {Array<(number|string)>} receivedCategoryIds - The received category ids to be evaluated on each play,
   * can be a number or a string in the form of 'categoryId_r' e.g. '35_r', _r refers to rival plays.
   * @param {{accomplished: number, received: number}} generalCategoriesIds - Categories ids thad wrap accomplishedCategoryIds
   * and receivedCategoryIds
   * @param {string} accomplishedName - Name to be displayed when selected options are accomplished for both teams
   * @param {string} receivedName - Name to be displayed when selected options are received for both teams
   * @param {string} accomplishedReceivedName - Name to be displayed when selected options are accomplished by local team
   * and received by visitor team
   * @param {string} receivedAccomplishedName - Name to be displayed when selected options are received by local team
   * and accomplished by visitor team
   * @param {number} generalId - id to filter category
   * @param {boolean} betOnly - flag to display subcategory
   */
  constructor(
    accomplishedCategoryIds,
    receivedCategoryIds,
    generalCategoriesIds,
    accomplishedName,
    receivedName,
    accomplishedReceivedName,
    receivedAccomplishedName,
    generalId,
    betOnly,
  ) {
    this.id = `${generalCategoriesIds.accomplished}-${generalCategoriesIds.received}`;
    this.accomplishedCategoryIds = accomplishedCategoryIds || [];
    this.receivedCategoryIds = receivedCategoryIds || [];
    this.generalCategoriesIds = generalCategoriesIds || { accomplished: 0, received: 0 };
    this.names['0-0'] = accomplishedName || '';
    this.names['1-1'] = receivedName || '';
    this.names['0-1'] = accomplishedReceivedName || '';
    this.names['1-0'] = receivedAccomplishedName || '';
    this.generalId = generalId || 0;
    this.betOnly = betOnly || false;
  }

  /**
   * @author Alejandro
   * @description Add league plays
   * @param {Array<Array>} currentSeasonPlays
   * @param {Object} leaguePlayIndex
   * @param {number} totalSeasonFinishedGames
   */
  addLeaguePlays(currentSeasonPlays, leaguePlayIndex, totalSeasonFinishedGames) {
    // division by zero does not exist or if the league's plays for this category has
    // been calculated already return
    if (currentSeasonPlays.length === 0 || !totalSeasonFinishedGames || this.isLeagueDataCalculated) return;
    currentSeasonPlays.forEach(play => {
      if (this.isAccomplishedLeaguePlay(play[leaguePlayIndex.categoryId])) {
        this.leagueAccomplishedAccumulator += play[leaguePlayIndex.total];
      }
      if (this.isReceivedLeaguePlay(play[leaguePlayIndex.categoryId])) {
        this.leagueReceivedAccumulator += play[leaguePlayIndex.total];
      }
    });
    this.leagueAccomplishedAvg = (this.leagueAccomplishedAccumulator / (2 * totalSeasonFinishedGames)).toFixed(2);
    this.leagueAccomplishedMax = Math.round(this.leagueAccomplishedAvg * 1.5);
    this.leagueReceivedAvg = (this.leagueReceivedAccumulator / (2 * totalSeasonFinishedGames)).toFixed(2);
    this.leagueReceivedMax = Math.round(this.leagueReceivedAvg * 1.5);
    this.isLeagueDataCalculated = true;
  }

  /**
   * Add special mentions for this subcategory instance.
   * @param {number} categoryIdLocal
   * @param {number} categoryIdVisit
   * @param {string} specialMention
   */
  addSpecialMention(categoryIdLocal, categoryIdVisit, specialMention) {
    let mentionKey = '-';
    for (const [key, value] of Object.entries(this.generalCategoriesIds)) {
      if (categoryIdLocal === value) {
        mentionKey = `${key === 'accomplished' ? '0' : '1'}${mentionKey}`;
      }
      if (categoryIdVisit === value) {
        mentionKey = `${mentionKey}${key === 'accomplished' ? '0' : '1'}`;
      }
    }
    if (mentionKey.length === 3) this.mentions[mentionKey] = specialMention;
  }

  resetMentions() {
    this.mentions = {
      ['0-0']: '',
      ['1-1']: '',
      ['0-1']: '',
      ['1-0']: '',
    };
  }

  /**
   * @author Alejandro
   * @description Add home or visiting team plays
   * @param {string} teamType
   * @param {number} currentTeamId
   * @param {Array<Array>} teamPlays
   * @param {Object} teamPlayIndex
   * @param {number} totalTeamFinishedGames
   */
  addTeamPlays(teamType, currentTeamId, teamPlays, teamPlayIndex, totalTeamFinishedGames) {
    // If there is not finished games, then there is not data to calculate
    if (!teamPlays || !totalTeamFinishedGames || (teamType !== this.homeTeamType && teamType !== this.visitingTeamType))
      return;
    teamPlays.forEach(play => {
      if (this.isAccomplishedPlay(play[teamPlayIndex.categoryId])) {
        this[teamType].increaseAccomplishedByOne(play[teamPlayIndex.section], play[teamPlayIndex.total]);
      } else if (this.isReceivedPlay(play[teamPlayIndex.categoryId])) {
        this[teamType].increaseReceivedByOne(play[teamPlayIndex.section], play[teamPlayIndex.total]);
      }
    });
    this[teamType].calculateAvg(totalTeamFinishedGames);
  }

  /**
   * @author Alejandro
   * @description Add live play for home and visiting teams
   * @param {string} teamType
   * @param {number} currentTeamId
   * @param {Array} livePlay
   * @param {Object} teamPlayIndex
   * @param {Boolean} isLive
   * @param {Boolean} isReceived
   */
  // eslint-disable-next-line no-unused-vars
  addLivePlay(teamType, currentTeamId, livePlay, teamPlayIndex, isLive = false, isReceived = false) {
    if (teamType === this.homeTeamType || teamType === this.visitingTeamType) {
      if (this.isAccomplishedPlay(livePlay[teamPlayIndex.categoryId]) && !isReceived) {
        this[teamType].increaseAccomplishedByOne(livePlay[teamPlayIndex.section], livePlay[teamPlayIndex.total], true);
      } else if (this.isReceivedPlay(livePlay[teamPlayIndex.categoryId]) && isReceived) {
        this[teamType].increaseReceivedByOne(livePlay[teamPlayIndex.section], livePlay[teamPlayIndex.total], isLive);
      }
    }
  }

  /**
   * @author Alejandro
   * @description Subtract a live play for home o visiting team
   * @param {string} teamType
   * @param {number} currentTeamId
   * @param {Array} livePlay
   * @param {Object} teamPlayIndex
   * @param {Boolean} isReceived
   */
  subtractLivePlay(teamType, currentTeamId, livePlay, teamPlayIndex, isReceived) {
    if (teamType === this.homeTeamType || teamType === this.visitingTeamType) {
      if (this.isAccomplishedPlay(livePlay[teamPlayIndex.categoryId]) && !isReceived) {
        this[teamType].decreaseAccomplishedLiveByOne(livePlay[teamPlayIndex.section], livePlay[teamPlayIndex.total]);
      } else if (this.isReceivedPlay(livePlay[teamPlayIndex.categoryId]) && isReceived) {
        this[teamType].decreaseReceivedLiveByOne(livePlay[teamPlayIndex.section], livePlay[teamPlayIndex.total]);
      }
    }
  }

  /**
   * @author José Rafael Gutierrez
   * @param playCategoryId
   * @returns {boolean}
   */
  isAccomplishedLeaguePlay(playCategoryId) {
    const accomplished = this.receivedCategoryIds[0] === 40 ? this.receivedCategoryIds : this.accomplishedCategoryIds;
    return Boolean(accomplished.find(categoryId => playCategoryId === categoryId));
  }

  /**
   * @author José Rafael Gutierrez
   * @param playCategoryId
   * @returns {boolean}
   */
  isReceivedLeaguePlay(playCategoryId) {
    return Boolean(this.receivedCategoryIds.find(categoryId => playCategoryId === categoryId));
  }

  /**
   * @author Alejandro
   * @description There are some categories inside accomplishedCategoryIds in format categoryId and r, e.g. '35_r', these categories
   * with '_r' refers to rival plays. So these method look for those plays an compare it's team id.
   * @param {number} playCategoryId - Category id from the play to add
   * @return {*}
   */
  isAccomplishedPlay(playCategoryId) {
    return this.generalCategoriesIds.accomplished === playCategoryId;
  }

  /**
   * @author Alejandro
   * @description There are some categories inside receivedCategoryIds in format categoryId and r, e.g. '35_r', these categories
   * with '_r' refers to rival plays. So these method look for those plays an compare it's team id.
   * @param {number} playCategoryId - Category id from the play to add
   * @return {*}
   */
  isReceivedPlay(playCategoryId) {
    return this.generalCategoriesIds.received === playCategoryId;
  }

  /**
   *
   * @param {Array<Array<number>>} rankingData
   * @param {Object} indexesLS
   * @param {Object} homeTeamFilter
   * @param {Object} visitingTeamFilter
   * @param {Array<Object>} teams
   * @param {boolean} isPremiumAccount
   */
  calcRankingLeague(rankingData, indexesLS, homeTeamFilter, visitingTeamFilter, teams, isPremiumAccount = false) {
    this[this.homeTeamType].calculateRanking(
      rankingData,
      indexesLS,
      homeTeamFilter,
      this.generalCategoriesIds.accomplished,
      this.generalCategoriesIds.received,
      teams,
      isPremiumAccount,
      this.homeTeamType,
    );
    this[this.visitingTeamType].calculateRanking(
      rankingData,
      indexesLS,
      visitingTeamFilter,
      this.generalCategoriesIds.accomplished,
      this.generalCategoriesIds.received,
      teams,
      isPremiumAccount,
      this.visitingTeamType,
    );
  }

  resetData() {
    this.homeTeam = new NGTeamData();
    this.visitingTeam = new NGTeamData();
    this.isLeagueDataCalculated = false;
    this.leagueAccomplishedMax = 0;
    this.leagueReceivedMax = 0;
    this.leagueAccomplishedAvg = 0;
    this.leagueReceivedAvg = 0;
    this.leagueAccomplishedAccumulator = 0;
    this.leagueReceivedAccumulator = 0;
  }

  isInGeneralCategory(categoryId) {
    return this.generalCategoriesIds.accomplished === categoryId || this.generalCategoriesIds.received === categoryId;
  }
}
