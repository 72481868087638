import { getPlayValueIndexesFromArray } from '@/utils/misc';

/**
 * Defines the index position of each value inside a team stat play.
 */
const teamStatPlayValues = ['categoryId', 'teamId', 'matchResult', 'playingAs', 'matchLapse', 'section', 'total'];

let teamStatPlayIndex = null;

export default function getTeamStatPlayIndexes() {
  if (!teamStatPlayIndex) {
    teamStatPlayIndex = getPlayValueIndexesFromArray(teamStatPlayValues);
  }
  return teamStatPlayIndex;
}
