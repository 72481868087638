/**
 * @author Alejandro
 * @description Filter a play list by location and result
 * @param {Array} playList - The play list to filter
 * @param {Array<number>} playingAs - Playing as home team or visiting team or both
 * @param {Array<number>} selectedResults - The game result, win, loss, draw or all of three
 * @param {Array<number>} selectedSections - The selected time sections
 * @param {Object} playValuesIndex - Contains index position for the play values
 * @return {Array} - The filtered play list
 */
export function filterByGameLocationsResultsAndSections(
  playList,
  playingAs,
  selectedResults,
  selectedSections,
  playValuesIndex,
) {
  // Check if all options are selected to avoid filter iteration
  if (playingAs.length === 2 && selectedResults.length === 3 && selectedSections.length === 6) {
    return playList;
  }
  return playList.filter(play => {
    return (
      playingAs.includes(play[playValuesIndex.playingAs]) &&
      selectedResults.includes(play[playValuesIndex.matchResult]) &&
      selectedSections.includes(play[playValuesIndex.section])
    );
  });
}
