import {
  createPaymentMethod,
  getPaymentMethods,
  updatePaymentMethod,
  deletePaymentMethod,
} from '@/utils/api/paymentMethods';
import { getUserData, getPlans, changePlan } from '@/utils/api/profile';
import cardsInfo from '@/data/cardsInfo';
import planStyles from '@/data/profile/planStyles';

export default {
  namespaced: true,
  state: {
    cards: [],
    showChangePrimaryPaymentMethod: true,
    cardsInfo: cardsInfo,
    currentPlanId: null,
    plans: [],
    styles: planStyles,
    planVisibilityProfile: [0, 2],
    colorsConfig: {
      bet_house: '',
      main_logo: '',
      secondary_logo: '',
      show_logo: false,
      url_base: '',
    },
  },
  getters: {
    mainCard: state => state.cards.find(card => card.main === 1) || {},
    otherCards: state => state.cards.filter(card => card.main !== 1) || [],
    getAllPlans: state => state.plans,
    getProfilePlans: state => {
      return state.plans
        .filter(plan => state.planVisibilityProfile.includes(plan.visibility))
        .map(plan => {
          return {
            id: plan.id,
            title: plan.subtitle,
            type: plan.title,
            description: plan.description,
            additionalInfo: plan.additional_comments,
            price: plan.price,
            currency: plan.currency,
            style: state.styles[plan.style],
            btnName: plan.id === 0 ? 'Cancelar Premium' : 'Seleccionar',
            isCurrentPlan: state.currentPlanId === plan.id,
          };
        });
    },
    hasNoPlans: state => !state.plans.length,
  },
  mutations: {
    setColorsConfig(state, colorsConfig) {
      state.colorsConfig = colorsConfig;
    },
    updateCards(state, cards) {
      state.cards = cards.map(card => {
        const PRE = card.exp_month.toString().length === 1 ? '0' : '';
        const EXP = `${PRE}${card.exp_month}/${card.exp_year}`;
        const CARD_INFO = state.cardsInfo.find(cardInfo => cardInfo.card === card.card_type);
        return {
          id: card.id,
          type: CARD_INFO ? CARD_INFO.type : card.card_type.toUpperCase(),
          lastFourt: card.last_4,
          expiration: EXP,
          icon: CARD_INFO ? CARD_INFO.icon : '',
          main: card.type,
        };
      });
    },
    setShowChangePrimaryPaymentMethod(state, show) {
      state.showChangePrimaryPaymentMethod = show;
    },
    setCurrentPlanId(state, currentPlanId) {
      state.currentPlanId = currentPlanId;
    },
    setProfilePlans(state, plans) {
      state.plans = plans;
    },
  },
  actions: {
    async getUserInfo() {
      try {
        const {
          data: { user_info },
        } = await getUserData();
        return user_info;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getUserData({ commit, dispatch }) {
      await commit('setIsSectionLoading', true, { root: true });
      try {
        const response = await getUserData();
        commit('setCurrentPlanId', response.data.current_plan.id);
      } catch (e) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
      } finally {
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
    async getPaymentMethods({ commit, dispatch }) {
      await commit('setIsSectionLoading', true, { root: true });
      try {
        const response = await getPaymentMethods();
        commit('updateCards', response.data);
      } catch (e) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
      } finally {
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
    async addPaymentMethod({ rootState, commit, dispatch }, isPrimary) {
      await commit('setIsSectionLoading', true, { root: true });
      const typeUserProvider = rootState['loginGeneral'].user.provider;
      var tokenCardProvider = null;
      if (typeUserProvider == 1) {
        tokenCardProvider = rootState['registerGeneral'].token_card;
      } else {
        tokenCardProvider = rootState['registerGeneral'].tokenCard;
      }
      const body = {
        token_card: tokenCardProvider,
        is_main: isPrimary ? 1 : 0,
      };

      try {
        const response = await createPaymentMethod(body);
        commit('updateCards', response.data);
        const ALERT = {
          type: 'success',
          message: 'Tu método de pago ha sido registrado exitosamente.',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
      } catch (e) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        return Promise.reject(e);
      } finally {
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
    async setPaymentMethodPrimary({ commit, dispatch }, id) {
      await commit('setShowChangePrimaryPaymentMethod', false);
      await commit('setIsSectionLoading', true, { root: true });
      try {
        const response = await updatePaymentMethod(id);
        const ALERT = {
          type: 'success',
          message: 'Tu método de pago principal ha sido actualizado exitosamente.',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        commit('updateCards', response.data);
      } catch (e) {
        const ALERT_ERROR = {
          type: 'error',
          message: e.response.data.message,
        };
        dispatch('alertGeneral/setAlert', ALERT_ERROR, { root: true });
        return Promise.reject(e);
      } finally {
        await commit('setShowChangePrimaryPaymentMethod', true);
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
    async deletePaymentMethod({ commit, dispatch }, id) {
      await commit('setShowChangePrimaryPaymentMethod', false);
      await commit('setIsSectionLoading', true, { root: true });
      try {
        const response = await deletePaymentMethod(id);
        const ALERT = {
          type: 'success',
          message: 'Tu método de pago ha sido eliminado exitosamente.',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        commit('updateCards', response.data);
      } catch (e) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        return Promise.reject(e);
      } finally {
        await commit('setShowChangePrimaryPaymentMethod', true);
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
    async getPlans({ commit }) {
      await commit('setIsSectionLoading', true, { root: true });
      const response = await getPlans();
      await commit('setProfilePlans', response.data);
      await commit('setIsSectionLoading', false, { root: true });
    },
    async changePlan({ state, dispatch, commit }, body) {
      await commit('setIsSectionLoading', false, { root: true });
      let message = 'Tu suscripción ha sido cancelada exitosamente.';
      if (body.plan_id !== 0) {
        const CURRENT_PLAN = state.plans.find(plan => plan.id === state.currentPlanId);
        const NAME_OLD_PLAN = `${CURRENT_PLAN.title} ${CURRENT_PLAN.subtitle}`;
        const NEW_PLAN = state.plans.find(plan => plan.id === body.plan_id);
        const NAME_NEW_PLAN = `${NEW_PLAN.title} ${NEW_PLAN.subtitle}`;
        message = `Tu suscripción ${NAME_OLD_PLAN} ha sido cambiada a ${NAME_NEW_PLAN} exitosamente`;
      }
      try {
        await changePlan(body);
        const ALERT = {
          type: 'success',
          message: message,
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        await dispatch('getUserData');
      } catch (e) {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        return Promise.reject(e);
      } finally {
        await commit('setIsSectionLoading', false, { root: true });
      }
    },
  },
};
