import { emailValidation, changePassword, tokenValidation } from '@/utils/api/recover';

const state = {
  successSend: 'none',
  msg: '',
  email: '',
  msgAlert: '',
};

const getters = {
  getStatusSendEmail(state) {
    return state.successSend;
  },
  getEmail(state) {
    return state.email;
  },
  getMsg(state) {
    return state.msg;
  },
  getAlert(state) {
    return state.msgAlert;
  },
};

const mutations = {
  setStatusSendEmail(state, status) {
    state.successSend = status;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setMsg(state, msg) {
    state.msg = msg;
  },
  setAlert(state, alert) {
    state.msgAlert = alert;
  },
};

const actions = {
  async emailSend({ dispatch, commit }, params) {
    await commit('setIsSectionLoading', true, { root: true });
    try {
      const result = await emailValidation(params);
      if (result.data != null) {
        if (result.data.message == 1) {
          await commit('setStatusSendEmail', 'success');
        } else {
          const ALERT = {
            type: 'error',
            message: 'No existe una cuenta vinculada al correo que proporcionaste.',
          };
          dispatch('alertGeneral/setAlert', ALERT, { root: true });
          await commit('setStatusSendEmail', 'error');
        }
      } else {
        const ALERT = {
          type: 'error',
          message: 'No existe una cuenta vinculada al correo que proporcionaste.',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        await commit('setStatusSendEmail', 'error');
      }
    } catch (e) {
      commit('setIsSectionLoading', false, { root: true });
    } finally {
      commit('setIsSectionLoading', false, { root: true });
    }
  },

  async validateToken({ dispatch, commit }, params) {
    await commit('setIsSectionLoading', true, { root: true });
    try {
      const result = await tokenValidation(params);
      if (result.data.email) {
        await commit('setEmail', result.data.email);
      } else {
        const ALERT = {
          type: 'error',
          message: result.data.message,
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        await commit('setAlert', result.data.message);
      }
    } catch (e) {
      commit('setIsSectionLoading', false, { root: true });
    } finally {
      commit('setIsSectionLoading', false, { root: true });
    }
  },

  async changePassword({ commit }, params) {
    await commit('setIsSectionLoading', true, { root: true });
    try {
      const result = await changePassword(params);
      await commit('setMsg', result.data.message);
    } catch (e) {
      commit('setIsSectionLoading', false, { root: true });
    } finally {
      commit('setIsSectionLoading', false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
