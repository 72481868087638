import { fetchLastTermsVersion } from '@/utils/api/terms';
import StoreNames from '@/utils/classes/StoreNames';

export default {
  namespaced: true,
  state: {
    termsVersion: { t_id: 1, term: 'V1.0.0', isDefault: true },
  },
  getters: {
    getTermsVersion: state => state.termsVersion,
  },
  mutations: {
    setTermsVersion(state, termsVersion) {
      state.termsVersion = termsVersion;
    },
  },
  actions: {
    async fetchLastTermsVersion({ commit }, storeValue = true) {
      try {
        let term = await fetchLastTermsVersion();
        if (storeValue && term) {
          commit(termsStoreNames.getStoreNames(true).mutations.SET_TERMS_VERSION, term);
        }
        return term;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};

const names = {
  getters: {
    GET_TERMS_VERSION: `getTermsVersion`,
  },
  mutations: {
    SET_TERMS_VERSION: `setTermsVersion`,
  },
  actions: {
    FETCH_LAST_TERMS_VERSION: `fetchLastTermsVersion`,
  },
};
export const termsStoreNames = new StoreNames(names, 'terms/');
