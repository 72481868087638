import axios from 'axios';
import { fetchRetry } from '@/utils/api/fetchRetry';

/**
 * @author José R. Gutierrez
 */
export function getUserData() {
  return axios.get(process.env.VUE_APP_USER_DETAILS_API_URL);
}

/**
 * @author José R. Gutierrez
 */
export function getPlans() {
  return axios.get(process.env.VUE_APP_PLANS_API_URL);
}

/**
 * @author José R. Gutierrez
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function changePlan(body) {
  return axios.put(process.env.VUE_APP_CHANGE_PLAN_API_URL, body);
}

/**
 * @author Alejandro Martinez
 * @param {Object} body
 * @param {string} body.nombre
 * @param {string} body.email
 * @param {string} body.motivo
 * @param {string} body.descripcion
 * @param {string} body.recaptcha
 * @return {Promise<AxiosResponse<any>>}
 */
export function sendComment(body) {
  return axios.post('https://fyf5ln4d86.execute-api.us-west-2.amazonaws.com/prod/contact-us', body);
}

export async function fetchColorsConfigByTeam(teamName = 'testgolstats') {
  try {
    const url = `https://2dp6unm5we.execute-api.us-west-2.amazonaws.com/prod/custom_views/${teamName}`;
    const response = await fetchRetry({ url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
