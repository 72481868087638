export const normalPlayer = [
  {
    name: 'Goles',
    color: '#4A90E2',
    points: [1, 2, 3],
    categories: [12, 55, 35, 54],
    invert: [35, 54],
    negative: false,
  },
  {
    name: 'Tiros a gol',
    color: '#FF8000',
    points: [1, 2, 3],
    categories: [12, 55, 35, 54, 510],
  },
];
