import { getLeaguePlaysBySeason } from '@/utils/api/league';
import { getGamesBySeason, fetchGamesByTournament } from '@/utils/api/games';
import { getTeamsBySeason } from '@/utils/api/teams';
const seasonType1Abbreviations = {
  PO1: 'CFI',
  PO2: 'CFV',
  PO3: 'SFI',
  PO4: 'SFV',
  PO5: 'FI',
  PO6: 'FV',
};

/**
 * This store must have all global league data, like all league games per season,
 * all league plays by season, all league teams per season, all league players by season, etc.
 */
export default {
  namespaced: true,
  state: {
    seasonPlays: [],
    currentSeasonGames: [],
    currentSeasonTeams: [],
    currentTournamentGames: {},
    selectedRound: '1',
    fullTournamentSchedule: {},
    arrayTournaments: [],
    objectSeasonsByRegularSeason: {},
    objectSeasonSelected: {},
    objectTournamentSelected: {
      seasons: [],
    },
    numberSeasonIdSelected: 0,
    booleanIsTournamentsModalOpen: false,
    booleanAreLiveGames: false,
    booleanIsLiveGamesModalOpen: false,
    arrayTeamsBySeason: [],
  },
  getters: {
    booleanIsTournamentsSelectedCup(state) {
      return !!state.objectTournamentSelected.tournament_type;
    },
    arraySeasonsByRegularSeason(state) {
      return state.objectSeasonsByRegularSeason[state.objectSeasonSelected.id] || [];
    },
    seasonPlays: state => state.seasonPlays,
    nextGamesSchedule(state) {
      const schedule = state.currentSeasonGames.reduce((accumulator, game) => {
        const gameTmp = { ...game };
        gameTmp.home_team = state.currentSeasonTeams.find(team => team.team_id === game.home_team);
        gameTmp.visiting_team = state.currentSeasonTeams.find(team => team.team_id === game.visiting_team);
        if (!accumulator[game.matchday_abbreviation]) {
          accumulator[game.matchday_abbreviation] = {
            [game.game_status === 3 ? 'canceled' : game.date]: [gameTmp],
          };
        } else if (!accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date]) {
          accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date] = [gameTmp];
        } else {
          accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date].push(gameTmp);
        }
        return accumulator;
      }, {});
      for (let [k, v] of Object.entries(schedule)) {
        schedule[k] = Object.entries(v).map(([k2, v2]) => {
          return {
            date: k2,
            games: v2,
          };
        });
        schedule[k].sort((a, b) => {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
        });
      }
      return schedule;
    },
    fullTournamentSchedule(state) {
      const seasonsSchedules =
        state.currentTournamentGames && state.currentTournamentGames.seasonsGames
          ? state.currentTournamentGames.seasonsGames.map(seasonAndGames => {
              const schedule = seasonAndGames.games.reduce((accumulator, game) => {
                const gameTmp = { ...game };
                gameTmp.home_team = state.currentSeasonTeams.find(team => team.team_id === game.home_team);
                gameTmp.visiting_team = state.currentSeasonTeams.find(team => team.team_id === game.visiting_team);
                if (!accumulator[game.matchday_abbreviation]) {
                  accumulator[game.matchday_abbreviation] = {
                    [game.game_status === 3 ? 'canceled' : game.date]: [gameTmp],
                  };
                } else if (!accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date]) {
                  accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date] = [gameTmp];
                } else {
                  accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date].push(
                    gameTmp,
                  );
                }
                return accumulator;
              }, {});
              for (let [k, v] of Object.entries(schedule)) {
                schedule[k] = Object.entries(v).map(([k2, v2]) => {
                  return {
                    date: k2,
                    games: v2,
                  };
                });
                schedule[k].sort((a, b) => {
                  return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                });
              }
              return {
                type: seasonAndGames.type,
                weight: seasonAndGames.type === 0 ? 0 : seasonAndGames.type === 2 ? 1 : 2,
                schedule,
              };
            })
          : [];
      seasonsSchedules.sort((a, b) => a.weight - b.weight);
      const fullTournamentSchedule = seasonsSchedules.reduce((accumulator, seasonSchedule) => {
        for (const [k, v] of Object.entries(seasonSchedule.schedule)) {
          if (seasonSchedule.type === 0) {
            accumulator[Number(k.replace('M', '')).toString()] = v;
          } else if (seasonSchedule.type === 2) {
            accumulator[`R${Number(k.replace('PO', ''))}`] = v;
          } else if (seasonSchedule.type === 1) {
            accumulator[seasonType1Abbreviations[k]] = v;
          }
        }
        return accumulator;
      }, {});
      return fullTournamentSchedule;
    },
    roundsAndEndOfLastGame(state, getters) {
      const gameDuration = 110 * 60 * 1000;
      return Object.entries(getters.fullTournamentSchedule).reduce((accumulator, [k, v]) => {
        accumulator[k] = [];
        v.forEach(day => {
          day.games.forEach(game => {
            const endOfGame = new Date(game.date_time_utc).getTime() + gameDuration;
            accumulator[k].push(endOfGame);
          });
        });
        return accumulator;
      }, {});
    },
    /**
     * @author Alejandro
     * @description Obtains the round closest to the current date
     * @return {string} returns the round's abbreviation
     */
    closestMatchDay(state, getters) {
      const entries = Object.entries(getters.roundsAndEndOfLastGame);
      if (entries.length === 0) return '1';
      const currentTime = new Date().getTime();
      const closestRound = entries.reduce(
        (accumulator, [k, v]) => {
          let timeDifferenceGlobal = null;
          v.forEach(endOfGame => {
            const timeDifference = endOfGame - currentTime;
            if (timeDifference >= 0 && (timeDifferenceGlobal === null || timeDifference < timeDifferenceGlobal)) {
              timeDifferenceGlobal = timeDifference;
            }
          });
          if (
            timeDifferenceGlobal &&
            (accumulator.shortestTime === null || timeDifferenceGlobal < accumulator.shortestTime)
          ) {
            accumulator.round = k;
            accumulator.shortestTime = timeDifferenceGlobal;
          }
          return accumulator;
        },
        { round: '', shortestTime: null },
      );

      return closestRound.shortestTime !== null ? closestRound.round : entries[entries.length - 1][0];
    },
  },
  mutations: {
    setBooleanIsLiveGamesModalOpen(state, booleanIsLiveGamesModalOpen) {
      state.booleanIsLiveGamesModalOpen = booleanIsLiveGamesModalOpen;
    },
    setBooleanAreLiveGames(state, booleanAreLiveGames) {
      state.booleanAreLiveGames = booleanAreLiveGames;
    },
    setArrayTeamsBySeason(state, arrayTeamsBySeason) {
      state.arrayTeamsBySeason = arrayTeamsBySeason;
    },
    setArrayTournaments(state, arrayTournaments) {
      state.arrayTournaments = arrayTournaments;
    },
    setObjectSeasonsByRegularSeason(state, objectSeasonsByRegularSeason) {
      state.objectSeasonsByRegularSeason = objectSeasonsByRegularSeason;
    },
    setObjectTournamentSelected(state, objectTournamentSelected) {
      state.objectTournamentSelected = objectTournamentSelected;
    },
    setObjectSeasonSelected(state, objectSeasonSelected) {
      state.objectSeasonSelected = objectSeasonSelected;
      state.numberSeasonIdSelected = objectSeasonSelected.id || 0;
    },
    setBooleanIsTournamentsModalOpen(state, booleanIsTournamentsModalOpen) {
      state.booleanIsTournamentsModalOpen = booleanIsTournamentsModalOpen;
    },
    updateSeasonPlays(state, seasonPlays) {
      state.seasonPlays = seasonPlays;
    },
    updateCurrentSeasonTeams(state, currentSeasonTeams) {
      state.currentSeasonTeams = currentSeasonTeams;
    },
    updateCurrentSeasonGames(state, currentSeasonGames) {
      state.currentSeasonGames = currentSeasonGames;
    },
    updateSelectedRound(state, selectedRound) {
      state.selectedRound = selectedRound;
    },
    setCurrentTournamentGames(state, { tournament, seasonsGames }) {
      state.currentTournamentGames = {
        ...tournament,
        seasonsGames,
      };
    },
  },
  actions: {
    async getSeasonPlays({ commit, rootGetters }) {
      try {
        const seasonPlays = await getLeaguePlaysBySeason({ seasonId: rootGetters['nextGeneral/getSeasonId'] });
        commit('updateSeasonPlays', seasonPlays);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getCurrentSeasonGames({ commit, rootGetters }) {
      try {
        const { games, season_id, past_season_id } = await getGamesBySeason({
          seasonId: rootGetters['nextGeneral/getSeasonId'],
        });
        games.forEach(game => {
          game.season_id = season_id;
        });
        commit('nextGeneral/setPastSeasonId', past_season_id, { root: true });
        commit('updateCurrentSeasonGames', games);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getCurrentSeasonTeams({ commit, rootGetters }, { seasonId }) {
      try {
        const response = await getTeamsBySeason({ seasonId: seasonId || rootGetters['nextGeneral/getSeasonId'] });
        commit('updateCurrentSeasonTeams', response.data.teams);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getCurrentTournamentGames({ commit, rootState, dispatch }, { tournamentId }) {
      try {
        // data contains all seasons with games
        const { tournament, data } = await fetchGamesByTournament({ tournamentId: tournamentId });
        let regularSeasonId = 0;
        data.forEach(season => {
          const { type } = rootState['general'].tournamentSelected.seasons.find(
            tSeason => tSeason.id === season.season_id,
          );
          if (type === 0) {
            regularSeasonId = season.season_id;
          }
          season.type = type;
          season.games.forEach(game => {
            game.season_id = season.season_id;
          });
        });
        await dispatch('getCurrentSeasonTeams', { seasonId: regularSeasonId });
        commit('setCurrentTournamentGames', { tournament, seasonsGames: data });
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
