import axios from 'axios';

/**
 * @author José R. Gutierrez
 * @param {Object} body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function validateEmail(body) {
  return axios.post('https://rxkrnb7xia.execute-api.us-west-2.amazonaws.com/prod/email-validation', body);
}

/**
 * @author José R. Gutierrez
 * @param {Object} body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function registerPayment(body) {
  return axios.post(process.env.VUE_APP_PAYMENT_API_URL, body);
}

/**
 * @author José R. Gutierrez
 * @param {Object} body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function registerUser(body) {
  return axios.post('https://ijsdwsk7b8.execute-api.us-west-2.amazonaws.com/prod/create-account', body);
}

/**
 * @author Cristhian Gómez
 * @param {Object} body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function validateRegisteredUser(body) {
  return axios.post('https://ieag7hte2h.execute-api.us-west-2.amazonaws.com/prod/validate-in-progress-user', body);
}

/**
 * @author Ricardo Manzano
 */
export function checkoutTokens() {
  return axios.post(process.env.VUE_APP_CHECKOUT_ID_URL);
}
