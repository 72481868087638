export const cornersGoalsCategories = {
  goals: {
    raw: [12, 35, 54, 55],
    general: [1],
  },
  corners: {
    raw: [31, 32],
    general: [53],
  },
};
