import { getLeaguePlaysBySeason } from '@/utils/api/league';
import {
  getTeamsBySeasonV2 as getTeamsBySeason,
  getTeamStatisticsBySeason,
  getTeamStatisticsByGame,
} from '@/utils/api/teams';
import { getBetDefaultFilter } from '@/utils/api/tips';
import {
  getCurrentLiveGamePlays,
  getGamesBySeasonV2 as getGamesBySeason,
  getGamesBySeason as getGamesBySeasonSeason,
  getSpecialMentionsByGame,
} from '@/utils/api/games';
import leaguePlayIndexes from '@/data/leaguePlayIndexes';
import teamStatsPlayIndexes from '@/data/team/teamStatsPlayIndexes';
import nextGamesDataStructure from '@/data/next/nextGamesDataStructure';
import { filterOptions } from '@/data/next/options';
import { areTwoArraysEquals, getTeamFilterOptions } from '@/utils/misc';
import { filterByGameLocationsResultsAndSections } from '@/utils/playsFiltering';
import { getLeagueStatisticsGroupedBySeason } from '@/utils/api/league';
import leagueStatisticsIndexes from '@/data/leagueStatisticsIndexes';
import teamLivePlayIndexes from '@/data/next/teamLivePlayIndexes';
import { playStructure } from '@/data/next/teamLivePlayStructure';
import { gamesStructureInfo } from '@/data/next/gamesStructureInfo';
import { receivedLivePlay } from '@/data/next/receivedLivePlay';
import { duplicate } from '@/data/next/duplicatePlay';

const tournaments = [
  {
    name: 'Liga MX',
    id: 1,
  },
  {
    name: 'Liga MX Femenil',
    id: 1001,
  },
  {
    name: 'Liga Expansión MX',
    id: 1071,
  },
];

/**
 * This store has the purpose of containing all the global information of
 * the match analysis and the information related to both teams
 */
export default {
  namespaced: true,
  state: {
    seasonId: 0,
    pastSeasonId: 0,
    usePastSeasonId: false,
    isSocketConnected: false,
    indexesLP: leaguePlayIndexes(),
    indexesTSP: teamStatsPlayIndexes(),
    indexesLS: leagueStatisticsIndexes(),
    indexesTLP: teamLivePlayIndexes(),
    nextGamesDataStructure: nextGamesDataStructure,
    currentSeasonGames: [],
    currentSeasonTeams: [],
    gameForAnalysis: {},
    showPostMatch: false,
    homeResultGame: null,
    awayResultGame: null,
    selectedAnalysis: {},
    selectedStatisticTypeBlock: 1,
    gameId: null,
    game: {},
    games: [],
    homeTeamPlays: [],
    visitingTeamPlays: [],
    currentSeasonPlays: [],
    livePlays: new Map(),
    totalSeasonFinishedGames: 0,
    totalHomeTeamFinishedGames: 0,
    totalVisitingTeamFinishedGames: 0,
    homeGames: [],
    homeTeamInfo: {},
    awayTeamInfo: {},
    visitingGames: [],
    idAssetSelected: 1,
    isLive: false,
    menuOptionActive: 'stats',
    showLiveData: false,
    showAdvanceFilter: false,
    homeTeamFilter: {
      teamId: 0,
      statistics: 0,
      playingAs: [],
      matchResult: [],
      sections: [],
      requiresPlaysFiltering: true,
    },
    visitingTeamFilter: {
      teamId: 0,
      statistics: 0,
      playingAs: [],
      matchResult: [],
      sections: [],
      requiresPlaysFiltering: true,
    },
    oldSeasonId: 0,
    statistics: [],
    isRankingCalculating: true,
    categoriesMap: [],
    defaultFilter: null,
    filterAux: null,
    betSubcategories: [],
    betDescription: {},
    regularSeasonName: '',
    betSubcategoriesObjects: [],
    currentTournamentName: '',
    toBackRouteName: 'home',
  },
  getters: {
    betSubcategoriesIds(state) {
      return state.betSubcategories.map(subcategory => subcategory.id);
    },
    showPostMatchData: state => {
      return state.showPostMatch;
    },
    getHomeResultGame: state => {
      return state.homeResultGame;
    },
    getAwayResultGame: state => {
      return state.awayResultGame;
    },
    getSeasonId: state => {
      return state.seasonId;
    },
    isLive: state => {
      state.isLive = Boolean(state.gameForAnalysis.is_live);
      return state.isLive;
    },
    showLive: state => {
      return state.gameForAnalysis.game_status === 4 && state.gameForAnalysis.is_live;
    },
    menuOptionActive: state => {
      return state.menuOptionActive;
    },
    showLiveData: state => {
      return state.showLiveData;
    },
    showAdvanceFilter: state => {
      return state.showAdvanceFilter;
    },
    idAssetSelected: state => {
      return state.idAssetSelected;
    },
    gameId: state => {
      return state.gameId;
    },
    game: state => {
      return state.game;
    },
    homeTeam(state) {
      return state.homeTeamInfo;
    },
    visitingTeam(state) {
      return state.awayTeamInfo;
    },
    rankingData(state) {
      return state.statistics;
    },
    homeTeamStatisticsType(state) {
      return state.homeTeamFilter.statistics ? 'received' : 'accomplished';
    },
    visitingTeamStatisticsType(state) {
      return state.visitingTeamFilter.statistics ? 'received' : 'accomplished';
    },
    subcategoryName(state) {
      return `${state.homeTeamFilter.statistics}-${state.visitingTeamFilter.statistics}`;
    },
    statisticsTypes(state) {
      return filterOptions.filter(option => {
        return option.parentAnalysis.includes(state.selectedAnalysis.id);
      });
    },
    homeTeamFilteredPlays(state) {
      if (state.homeTeamFilter.requiresPlaysFiltering) {
        const filteredPlays = filterByGameLocationsResultsAndSections(
          state.homeTeamPlays,
          state.homeTeamFilter.playingAs,
          state.homeTeamFilter.matchResult,
          state.homeTeamFilter.sections,
          state.indexesTSP,
        );
        return filteredPlays;
      }
    },
    visitingTeamFilteredPlays(state) {
      if (state.visitingTeamFilter.requiresPlaysFiltering) {
        const filteredPlays = filterByGameLocationsResultsAndSections(
          state.visitingTeamPlays,
          state.visitingTeamFilter.playingAs,
          state.visitingTeamFilter.matchResult,
          state.visitingTeamFilter.sections,
          state.indexesTSP,
        );
        return filteredPlays;
      }
    },
    currentSeasonTeamsIds(state) {
      return state.currentSeasonTeams.map(team => team.team_id);
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @return {boolean|boolean}
     */
    canFilledStructure(state) {
      return (
        // !state.isRankingCalculating &&
        state.homeTeamFilter.requiresPlaysFiltering || state.visitingTeamFilter.requiresPlaysFiltering
      );
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @returns {number}
     */
    totalFilteredHomeGames(state) {
      return state.homeGames
        .filter(games => state.homeTeamFilter.playingAs.includes(games[0]))
        .filter(games => state.homeTeamFilter.matchResult.includes(games[1]))
        .reduce((total, games) => total + games[2], 0);
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @returns {number}
     */
    totalFilteredVisitingGames(state) {
      return state.visitingGames
        .filter(games => state.visitingTeamFilter.playingAs.includes(games[0]))
        .filter(games => state.visitingTeamFilter.matchResult.includes(games[1]))
        .reduce((total, games) => total + games[2], 0);
    },
    /**
     * @author Alejandro
     * @description Fill all statistic data for each subcategory
     */
    filledDataStructure(state, getters) {
      if (/*getters.canFilledStructure*/ state.currentSeasonPlays) {
        for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
          for (const subCategory of Object.values(mainCategory.subcategories)) {
            if (state.homeTeamFilter.requiresPlaysFiltering) subCategory.homeTeam.resetTotals();
            if (state.visitingTeamFilter.requiresPlaysFiltering) subCategory.visitingTeam.resetTotals();
            subCategory.addLeaguePlays(state.currentSeasonPlays, state.indexesLP, state.totalSeasonFinishedGames);
            subCategory.addTeamPlays(
              subCategory.homeTeamType,
              state.gameForAnalysis.home_team,
              getters.homeTeamFilteredPlays,
              state.indexesTSP,
              state.showPostMatch ? 1 : getters.totalFilteredHomeGames,
            );
            subCategory.addTeamPlays(
              subCategory.visitingTeamType,
              state.gameForAnalysis.visiting_team,
              getters.visitingTeamFilteredPlays,
              state.indexesTSP,
              state.showPostMatch ? 1 : getters.totalFilteredVisitingGames,
            );
          }
        }
      }
      return state.nextGamesDataStructure;
    },
    statisticData(state, getters) {
      for (const mainCategory of Object.values(getters.filledDataStructure)) {
        if (
          mainCategory.parentInfo.parentAnalysis.includes(state.selectedAnalysis.id) &&
          mainCategory.parentInfo.analysisTypeBlocks.includes(state.selectedStatisticTypeBlock)
        ) {
          return mainCategory.subcategories;
        }
      }
      return {};
    },
    accomplished(state) {
      return state.homeTeamFilter.statistics === 0;
    },
  },
  mutations: {
    setCurrentTournamentName(state, tournamentName) {
      state.currentTournamentName = tournamentName;
    },
    setBetSubcategoriesObjects(state, betSubcategoriesObjects) {
      state.betSubcategoriesObjects = betSubcategoriesObjects;
    },
    setRegularSeasonName(state, regularSeasonName) {
      state.regularSeasonName = regularSeasonName;
    },
    setBetSubcategories(state, betSubcategories) {
      state.betSubcategories = betSubcategories;
    },
    setBetDescription(state, betDescription) {
      state.betDescription = betDescription;
    },
    setSeasonId(state, seasonId) {
      state.seasonId = Number(seasonId);
    },
    setHome(state, data) {
      state.homeTeamInfo = data;
    },
    setAway(state, data) {
      state.awayTeamInfo = data;
    },
    setMenuOptionActive(state, option) {
      state.menuOptionActive = option;
    },
    setPastSeasonId(state, pastSeasonId) {
      state.pastSeasonId = pastSeasonId;
    },
    setUsePastSeasonId(state, usePastSeasonId) {
      state.usePastSeasonId = usePastSeasonId;
      if (usePastSeasonId) {
        state.oldSeasonId = state.pastSeasonId;
      }
    },
    updateShowPostMatchData(state, showPostMatch) {
      state.showPostMatch = showPostMatch;
    },
    setHomeResultGame(state, data) {
      state.homeResultGame = data;
    },
    setAwayResultGame(state, data) {
      state.awayResultGame = data;
    },
    updateIsSocketConnected(state, isSocketConnected) {
      state.isSocketConnected = isSocketConnected;
      if (!isSocketConnected && state.gameForAnalysis.is_live) {
        this._vm.$socket.client.emit('subscribe', { channel: `game.${state.gameForAnalysis.game_id}` });
      }
    },
    updateCurrentSeasonTeams(state, currentSeasonTeams) {
      state.currentSeasonTeams = currentSeasonTeams;
      state.currentSeasonTeams.forEach(team => {
        team.playedGames = state.currentSeasonGames.reduce((accumulator, game) => {
          if (game.game_status === 1 && (game.home_team === team.team_id || game.visiting_team === team.team_id)) {
            const scoreResult = game.score[0] - game.score[1];
            const isHomeTeam = game.home_team === team.team_id;
            accumulator.push({
              playingAs: isHomeTeam ? 1 : 2,
              matchResult: scoreResult === 0 ? 3 : isHomeTeam ? (scoreResult > 0 ? 1 : 2) : scoreResult < 0 ? 1 : 2,
            });
          }
          return accumulator;
        }, []);
      });
    },
    updateCurrentSeasonGames(state, currentSeasonGames) {
      state.currentSeasonGames = currentSeasonGames;
    },
    updateGameId(state, gameId) {
      state.gameId = gameId;
    },
    updateGameForAnalysis(state, gameForAnalysis) {
      state.gameForAnalysis = gameForAnalysis;
      state.homeTeamFilter.teamId = state.gameForAnalysis.home_team;
      state.visitingTeamFilter.teamId = state.gameForAnalysis.visiting_team;
      if (state.gameForAnalysis.is_live === 1 && state.isSocketConnected) {
        this._vm.$socket.client.emit('subscribe', { channel: `game.${state.gameForAnalysis.game_id}` });
      }
    },
    updateSelectedAnalysis(state, selectedAnalysis) {
      state.selectedAnalysis = selectedAnalysis;
    },
    updateShowLiveData(state, showLiveData) {
      state.showLiveData = showLiveData;
    },
    toggleShowLiveData(state) {
      state.showLiveData = !state.showLiveData;
    },
    toggleAdvanceFilter(state) {
      state.showAdvanceFilter = !state.showAdvanceFilter;
    },
    updateIdAssetSelected(state, idAssetSelected) {
      state.idAssetSelected = idAssetSelected;
    },
    updateStats(state, currentSeasonPlays) {
      state.currentSeasonPlays = currentSeasonPlays;
    },
    updateFilterAux(state) {
      if (!state.filterAux) {
        state.filterAux = {
          homeTeamFilter: { ...state.homeTeamFilter },
          visitingTeamFilter: { ...state.visitingTeamFilter },
        };
      }
    },
    updateFilters(state, { filters, needsFormat = false }) {
      if (needsFormat) {
        if (!filters || !filters.local || !filters.local.statistics || !filters.visit || !filters.visit.statistics) {
          return;
        }
        state.homeTeamFilter = getTeamFilterOptions(
          state.homeTeamFilter,
          filters.local,
          state.gameForAnalysis.home_team,
        );
        state.visitingTeamFilter = getTeamFilterOptions(
          state.visitingTeamFilter,
          filters.visit,
          state.gameForAnalysis.visiting_team,
        );
        // filters.local.statistics and filters.visit.statistics always must had in the same block
        if (state.selectedStatisticTypeBlock !== filters.local.statistics.block) {
          state.selectedStatisticTypeBlock = filters.local.statistics.block;
        }
      } else {
        state.homeTeamFilter = { ...filters.homeTeamFilter };
        state.visitingTeamFilter = { ...filters.visitingTeamFilter };
      }
    },
    updateFinishedGames(state, games) {
      state.totalSeasonFinishedGames = games;
    },
    updateHomeTeamPlays(state, statistics) {
      state.homeTeamPlays = statistics;
    },
    updateVisitingTeamPlays(state, statistics) {
      state.visitingTeamPlays = statistics;
    },
    addSpecialMentions(state, specialMentions) {
      const mainCategories = Object.values(state.nextGamesDataStructure);
      // Clear all old mentions before fill new mentions
      mainCategories.forEach(mainCategory => {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.resetMentions();
        }
      });
      // Fill all new mentions
      mainCategories.forEach(mainCategory => {
        for (const specialMention of specialMentions) {
          for (const subCategory of Object.values(mainCategory.subcategories)) {
            subCategory.addSpecialMention(specialMention[0], specialMention[1], specialMention[2]);
          }
        }
      });
    },
    setDefaultFilter(state, defaultFilter) {
      state.defaultFilter = {
        homeTeamFilter: { ...defaultFilter.homeTeamFilter, teamId: state.gameForAnalysis.home_team },
        visitingTeamFilter: { ...defaultFilter.awayTeamFilter, teamId: state.gameForAnalysis.visiting_team },
      };
      // state.defaultFilter.homeTeamFilter.teamId = state.gameForAnalysis.home_team;
      // state.defaultFilter.visitingTeamFilter.teamId = state.gameForAnalysis.visiting_team;
    },
    addLiveGamePlay(state, newLivePlay) {
      const isReceived = Boolean(newLivePlay[state.indexesTLP.length]);
      if (!state.livePlays.has(newLivePlay[state.indexesTLP.playId])) {
        state.livePlays.set(newLivePlay[state.indexesTLP.playId], newLivePlay);
        const PLAY = playStructure(newLivePlay, state.indexesTLP, state.categoriesMap, isReceived);
        const DUPLICATE = duplicate(newLivePlay, PLAY, state.indexesTLP);
        for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
          for (const subCategory of Object.values(mainCategory.subcategories)) {
            subCategory.addLivePlay(
              state.gameForAnalysis.home_team === PLAY[state.indexesTSP.teamId] ? 'homeTeam' : 'visitingTeam',
              PLAY[state.indexesTSP.teamId],
              PLAY,
              state.indexesTSP,
              state.isLive,
              isReceived,
            );
            if (DUPLICATE) {
              subCategory.addLivePlay(
                state.gameForAnalysis.home_team === DUPLICATE[state.indexesTSP.teamId] ? 'homeTeam' : 'visitingTeam',
                DUPLICATE[state.indexesTSP.teamId],
                DUPLICATE,
                state.indexesTSP,
                state.isLive,
                isReceived,
              );
            }
          }
        }
      }
    },
    updateLiveGamePlay(state, newLivePlay) {
      const isReceived = Boolean(newLivePlay[state.indexesTLP.length]);
      const currentLivePlay = state.livePlays.get(newLivePlay[state.indexesTLP.playId]);
      if (areTwoArraysEquals(currentLivePlay, newLivePlay)) return;
      let currentLivePlayTeamType = '';
      const newLivePlayTeamType =
        state.gameForAnalysis.home_team === newLivePlay[state.indexesTLP.teamId] ? 'homeTeam' : 'visitingTeam';
      if (currentLivePlay) {
        currentLivePlayTeamType =
          state.gameForAnalysis.home_team === currentLivePlay[state.indexesTLP.teamId] ? 'homeTeam' : 'visitingTeam';
      }
      // Subtract current live play from categories before to add new livePlay
      const CURRENT_LIVE_PLAY = playStructure(currentLivePlay, state.indexesTLP, state.categoriesMap);
      const DUPLICATE_LIVE_PLAY = duplicate(currentLivePlay, CURRENT_LIVE_PLAY, state.indexesTLP);
      const PLAY = playStructure(newLivePlay, state.indexesTLP, state.categoriesMap);
      const DUPLICATE = duplicate(newLivePlay, PLAY, state.indexesTLP);
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          if (currentLivePlay) {
            subCategory.subtractLivePlay(
              currentLivePlayTeamType,
              CURRENT_LIVE_PLAY[state.indexesTSP.teamId],
              CURRENT_LIVE_PLAY,
              state.indexesTSP,
              isReceived,
            );
            if (DUPLICATE_LIVE_PLAY) {
              subCategory.subtractLivePlay(
                currentLivePlayTeamType,
                DUPLICATE_LIVE_PLAY[state.indexesTSP.teamId],
                DUPLICATE_LIVE_PLAY,
                state.indexesTSP,
                isReceived,
              );
            }
          }
          subCategory.addLivePlay(
            newLivePlayTeamType,
            PLAY[state.indexesTSP.teamId],
            PLAY,
            state.indexesTSP,
            state.isLive,
            isReceived,
          );
          if (DUPLICATE) {
            subCategory.addLivePlay(
              newLivePlayTeamType,
              DUPLICATE[state.indexesTSP.teamId],
              DUPLICATE,
              state.indexesTSP,
              state.isLive,
              isReceived,
            );
          }
        }
      }
      state.livePlays.set(newLivePlay[state.indexesTLP.playId], newLivePlay);
    },
    deleteLiveGamePlay(state, newLivePlay) {
      const isReceived = Boolean(newLivePlay[state.indexesTLP.length]);
      const currentLivePlay = state.livePlays.get(newLivePlay[state.indexesTLP.playId]);
      let currentLivePlayTeamType = '';
      if (currentLivePlay) {
        currentLivePlayTeamType =
          state.gameForAnalysis.home_team === currentLivePlay[state.indexesTLP.teamId] ? 'homeTeam' : 'visitingTeam';
        state.livePlays.delete(currentLivePlay[state.indexesTLP.playId]);
        // Subtract current live play from subcategories
        const CURRENT_LIVE_PLAY = playStructure(currentLivePlay, state.indexesTLP, state.categoriesMap, isReceived);
        const DUPLICATE = duplicate(newLivePlay, CURRENT_LIVE_PLAY, state.indexesTLP);
        for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
          for (const subCategory of Object.values(mainCategory.subcategories)) {
            subCategory.subtractLivePlay(
              currentLivePlayTeamType,
              CURRENT_LIVE_PLAY[state.indexesTSP.teamId],
              CURRENT_LIVE_PLAY,
              state.indexesTSP,
              isReceived,
            );
            if (DUPLICATE) {
              subCategory.subtractLivePlay(
                currentLivePlayTeamType,
                DUPLICATE[state.indexesTSP.teamId],
                DUPLICATE,
                state.indexesTSP,
                isReceived,
              );
            }
          }
        }
      }
    },
    clearLiveTotals(state) {
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.homeTeam.resetTotalsLive();
          subCategory.visitingTeam.resetTotalsLive();
        }
      }
    },
    updateSeasonStatistics(state, statistics) {
      state.statistics = statistics;
    },
    updateOldSeasonId(state, seasonId) {
      state.oldSeasonId = seasonId;
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     * @param {Object} payload.getters
     * @param {boolean} payload.isPremiumAccount
     */
    addRankingInSubcategories(state, { getters, isPremiumAccount }) {
      if (!(!!state.homeTeamFilter.teamId && !!state.visitingTeamFilter.teamId)) return;
      state.isRankingCalculating = false;
      state.currentSeasonTeams.forEach(team => {
        team.homeTeamFilterGames = team.playedGames.filter(game => {
          return (
            state.homeTeamFilter.playingAs.includes(game.playingAs) &&
            state.homeTeamFilter.matchResult.includes(game.matchResult)
          );
        }).length;
        team.visitingTeamFilterGames = team.playedGames.filter(game => {
          return (
            state.visitingTeamFilter.playingAs.includes(game.playingAs) &&
            state.visitingTeamFilter.matchResult.includes(game.matchResult)
          );
        }).length;
      });

      const subcategories = [];
      const subcategoriesData = new Map();
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subcategories.push(subCategory);
        }
      }
      getters.rankingData.forEach(data => {
        for (const subcategory of subcategories) {
          if (subcategory.isInGeneralCategory(data[state.indexesLS.categoryId])) {
            if (subcategoriesData.has(subcategory.id)) {
              const array = subcategoriesData.get(subcategory.id);
              array.push(data);
            } else {
              subcategoriesData.set(subcategory.id, [data]);
            }
            break;
          }
        }
      });
      subcategories.forEach(subcategory => {
        subcategory.calcRankingLeague(
          subcategoriesData.get(subcategory.id),
          state.indexesLS,
          state.homeTeamFilter,
          state.visitingTeamFilter,
          state.currentSeasonTeams,
          isPremiumAccount,
        );
      });
      state.isRankingCalculating = false;
    },
    prepareCleanAllStatistics(state) {
      state.homeTeamFilter.requiresPlaysFiltering = true;
      state.visitingTeamFilter.requiresPlaysFiltering = true;
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @param games
     */
    setHomeGames(state, games) {
      state.homeGames = gamesStructureInfo(games);
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @param games
     */
    setVisitingGames(state, games) {
      state.visitingGames = gamesStructureInfo(games);
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     */
    generateCategoriesMap(state) {
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          state.categoriesMap.push({
            categoryKey: subCategory.generalCategoriesIds.accomplished,
            categoryValue: subCategory.accomplishedCategoryIds,
            categoryKeyOpponent: subCategory.generalCategoriesIds.received,
            categoriesOpponent: subCategory.receivedCategoryIds,
          });
        }
      }
    },
    resetNextGamesDataStructure(state) {
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.resetData();
        }
      }
    },
  },
  actions: {
    turnOnOffDefaultFilters({ state, commit }, { command }) {
      if (command === 'on') {
        commit('updateFilters', {
          filters: state.defaultFilter,
          needsFormat: false,
        });
      } else {
        commit('updateFilters', {
          filters: state.filterAux,
          needsFormat: false,
        });
      }
    },
    /**
     * @author Alejandro
     * @description This action is called from the view NextMatches.vue when it's created
     * and checks for the state required data
     * @param state
     * @param dispatch
     * @param commit
     * @param {string} gameId
     * @param {boolean} isTeamsAnalysis
     * @param {boolean} isLoginChanged
     * @return {Promise<never>}
     */
    async setAndGetStatsPostMatch({ commit }, params) {
      try {
        const statistics = await getTeamStatisticsByGame({ gameId: params.gameId });
        if (statistics) {
          var statsHome = [];
          var statsAway = [];
          var gamesHome = {
            season: 0,
            total: 0,
            visit: {
              total: 0,
              draw: 0,
              win: 0,
              lose: 0,
            },
            played: 0,
            local: {
              total: 0,
              draw: 0,
              win: 0,
              lose: 0,
            },
          };
          var gamesAway = {
            season: 0,
            total: 0,
            visit: {
              total: 0,
              draw: 0,
              win: 0,
              lose: 0,
            },
            played: 0,
            local: {
              total: 0,
              draw: 0,
              win: 0,
              lose: 0,
            },
          };
          if (Object.keys(statistics.data).length !== 0) {
            statsHome = statistics.data.data.filter(stats => stats[1] == params.homeTeam);
            statsAway = statistics.data.data.filter(stats => stats[1] == params.awayTeam);
            gamesHome = statistics.data.games;
            gamesAway = statistics.data.games;
          }
          commit('updateHomeTeamPlays', statsHome);
          commit('updateVisitingTeamPlays', statsAway);
          commit('setHomeGames', gamesHome);
          commit('setVisitingGames', gamesAway);
        } else {
          commit('updateHomeTeamPlays', []);
          commit('updateVisitingTeamPlays', []);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fillStatisticDataPostMatch({ commit, dispatch /* getters, rootGetters*/ }, params) {
      commit('prepareCleanAllStatistics');
      commit('updateHomeTeamPlays', []);
      commit('updateVisitingTeamPlays', []);
      commit('setIsSectionLoading', true, { root: true });
      try {
        let promises = [];
        commit('updateGameForAnalysis', params.gameInfo);
        promises.push(
          dispatch('setAndGetStatsPostMatch', {
            gameId: params.game,
            homeTeam: params.homeTeam,
            awayTeam: params.awayTeam,
          }),
        );
        await Promise.all(promises);
      } catch (e) {
        throw new Error(e);
      } finally {
        commit('setIsSectionLoading', false, { root: true });
      }
    },
    async checkState(
      { state, dispatch, commit },
      {
        gameId,
        isTeamsAnalysis,
        currentSeasonId,
        isLoginChanged = false,
        defaultFilter = null,
        gameInfo = null,
        isCupGame = false,
        isNewGameId = false,
        isNewSeasonId = false,
      },
    ) {
      try {
        let gameinfo = {};
        let isDifferentSeason = state.seasonId !== Number(currentSeasonId);
        const promises = [];
        commit('setIsSectionLoading', true, { root: true });
        commit('setUsePastSeasonId', false);
        if (isDifferentSeason) {
          commit('setSeasonId', currentSeasonId);
        }
        commit('updateGameId', Number(gameId));
        if (state.oldSeasonId !== state.seasonId) {
          commit('resetNextGamesDataStructure');
        }
        if (
          isNewGameId ||
          isLoginChanged ||
          state.currentSeasonGames.length === 0 ||
          state.usePastSeasonId ||
          isDifferentSeason
        ) {
          const games = await dispatch('getCurrentSeasonGames', Number(gameId));
          commit('leagueGeneral/updateCurrentSeasonGames', games, { root: true });
          gameinfo = games.find(game => game.game_id === Number(gameId));
        } else if (!isDifferentSeason) {
          gameinfo = state.currentSeasonGames.find(game => game.game_id === Number(gameId));
        }
        if (!gameinfo && gameInfo) {
          gameinfo = gameInfo;
        }
        if (gameinfo) {
          commit('updateGameForAnalysis', gameinfo);
        }
        if (gameinfo.game_status !== 1) {
          if (!isCupGame) {
            await dispatch('checkTeamsData', { gameId, isFromBet: !!defaultFilter });
          }
          if (
            isNewSeasonId ||
            isLoginChanged ||
            state.oldSeasonId !== state.seasonId ||
            state.statistics.length === 0
          ) {
            promises.push(dispatch('getSeasonStatistics'));
          }
          if (isLoginChanged || state.categoriesMap.length === 0) {
            commit('generateCategoriesMap');
          }
        }

        if (state.usePastSeasonId) {
          var gamesLastSeason = await dispatch('getCurrentSeasonGamesLast');
          commit('leagueGeneral/updateCurrentSeasonGames', gamesLastSeason, { root: true });
        }
        if (isLoginChanged || state.currentSeasonTeams.length === 0) {
          promises.push(dispatch('getCurrentSeasonTeams'));
        }
        commit('setHomeResultGame', gameinfo.score[0]);
        commit('setAwayResultGame', gameinfo.score[1]);
        if (isTeamsAnalysis) {
          if (gameinfo.game_status !== 1) {
            await dispatch('fillStatisticData', { gameId, gameinfo, promises, defaultFilter });
            commit('updateShowPostMatchData', false);
          } else {
            commit('updateStats', []);
            commit('updateShowPostMatchData', true);
            promises.push(
              dispatch('fillStatisticDataPostMatch', {
                game: gameId,
                homeTeam: gameinfo.home_team,
                awayTeam: gameinfo.visiting_team,
                gameInfo: gameinfo,
              }),
            );
            await Promise.all(promises);
          }
        } else {
          commit('setIsSectionLoading', false, { root: true });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author Alejandro
     * @description This action is called from checkState action only, and fill all required data
     * for the game's analysis
     * @param state
     * @param commit
     * @param dispatch
     * @param getters
     * @param rootGetters
     * @param gameId
     * @return {Promise<never>}
     */
    async fillStatisticData(
      { commit, dispatch, getters, rootGetters, state },
      { gameId, gameinfo, promises = [], defaultFilter = null },
    ) {
      let result = null;
      try {
        commit('setIsSectionLoading', true, { root: true });
        commit('prepareCleanAllStatistics');
        commit('updateHomeTeamPlays', []);
        commit('updateVisitingTeamPlays', []);
        // promises;
        // Update gameForAnalysis before get current live game's plays.
        commit('updateGameForAnalysis', gameinfo);
        if (gameinfo.is_live) {
          promises.push(dispatch('getCurrentLiveGamePlays', gameId));
        }
        promises.push(dispatch('getSpecialMentionsByGame', gameId));
        promises.push(dispatch('getHomeTeamPlays'));
        promises.push(dispatch('getVisitingTeamPlays'));
        promises.push(dispatch('getCurrentSeasonPlays'));
        if (defaultFilter) {
          promises.push(getBetDefaultFilter(defaultFilter));
        }
        result = await Promise.all(promises);
      } catch (e) {
        throw new Error(e);
      } finally {
        setTimeout(() => {
          commit('addRankingInSubcategories', {
            getters,
            isPremiumAccount: rootGetters['loginGeneral/getPremiumAccount'],
          });
          if (defaultFilter) {
            const [defaultFilters] = result.pop();
            if (defaultFilters) {
              commit('setBetSubcategories', defaultFilters.categories);
              commit('setBetDescription', defaultFilters.sectionDescription);
              commit('setDefaultFilter', defaultFilters.params);
              commit('updateFilterAux');
              commit('updateFilters', {
                filters: state.defaultFilter,
                needsFormat: false,
              });
              dispatch('updateRankingInSubcategories');
            }
          }
        }, 100);
        commit('setIsSectionLoading', false, { root: true });
      }
    },
    async checkTeamsData({ state, dispatch, rootState, commit }, { gameId, isFromBet }) {
      try {
        const gameIdNumber = Number(gameId);
        if (rootState['general'].tournaments && rootState['general'].tournaments.length) {
          await dispatch('general/fetchTournaments', null, { root: true });
          const currentTournament = rootState['general'].tournaments.find(tournament => {
            for (const season of tournament.seasons) {
              if (season.id === state.seasonId) {
                return true;
              }
            }
            return false;
          });
          commit('general/setTournamentSelected', currentTournament || {}, { root: true });
        }
        if (state.pastSeasonId) {
          commit('nextGeneral/setPastSeasonId', state.pastSeasonId, { root: true });

          const gameInfo = rootState['leagueGeneral'].currentSeasonGames.find(game => game.game_id === gameIdNumber);
          if (gameInfo) {
            commit('updateGameForAnalysis', gameInfo);
          }
          await Promise.all([dispatch('getHomeTeamPlays'), dispatch('getVisitingTeamPlays')]);

          commit(
            'setUsePastSeasonId',
            isFromBet ||
              ((!state.homeTeamPlays || state.homeTeamPlays.length === 0) &&
                (!state.visitingTeamPlays || state.visitingTeamPlays.length === 0)),
          );
        } else {
          commit('setUsePastSeasonId', false);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @description Get all specials mentions by current gameId
     * @param commit
     * @param gameId
     * @return {Promise<never>}
     */
    async getSpecialMentionsByGame({ commit }, gameId) {
      try {
        const specialMentions = await getSpecialMentionsByGame({ gameId: gameId });
        commit('addSpecialMentions', specialMentions);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author Alejandro
     * @description Get all league's games in the current season
     * @param commit
     * @param state
     * @return {Promise<never>}
     */
    async getCurrentSeasonGames({ commit, state }, gameId) {
      try {
        const { teams, games, past_season_id, tournament_id } = await getGamesBySeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
          gameId: gameId,
        });
        const tournament = tournaments.find(tournament => tournament.id === tournament_id);
        if (tournament) {
          commit('setCurrentTournamentName', tournament.name);
        }
        commit('setHome', teams[0]);
        commit('setAway', teams[1]);
        if (!state.usePastSeasonId) {
          commit('setPastSeasonId', past_season_id);
        }
        await commit('updateCurrentSeasonGames', games);

        if (games && Array.isArray(games)) {
          commit(
            'updateFinishedGames',
            games.reduce((accumulator, game) => {
              if (game.game_status === 1) {
                // eslint-disable-next-line no-param-reassign
                accumulator += 1;
              }
              return accumulator;
            }, 0),
          );
        }
        return games;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author Ricardo
     * @description Get all league's games in the last season
     * @param commit
     * @param state
     * @return {Promise<never>}
     */
    async getCurrentSeasonGamesLast({ commit, state }) {
      try {
        var { games, past_season_id } = await getGamesBySeasonSeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
        });
        if (!state.usePastSeasonId) {
          commit('setPastSeasonId', past_season_id);
        }
        await commit('updateCurrentSeasonGames', games);

        if (games && Array.isArray(games)) {
          commit(
            'updateFinishedGames',
            games.reduce((accumulator, game) => {
              if (game.game_status === 1) {
                // eslint-disable-next-line no-param-reassign
                accumulator += 1;
              }
              return accumulator;
            }, 0),
          );
        }
        return games;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author Alejandro
     * @description Get all league's teams in the current season
     * @param commit
     * @param state
     * @return {Promise<never>}
     */
    async getCurrentSeasonTeams({ commit, state }) {
      try {
        // TODO: Debe ir el id de la temporada obtenido dinámicamente.
        // const response = await getTeamsBySeason({ seasonId: rootGetters['loginGeneral/seasonId'] });
        const response = await getTeamsBySeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
        });
        commit('updateCurrentSeasonTeams', response.data.teams);
        // eslint-disable-next-line no-empty
      } catch {}
    },
    /**
     * @author José Rafael Gutierrez
     * @description Get all league's statistics in the current season
     * @param commit
     * @param state
     * @param rootGetters
     * @return {Promise<never>}
     */
    async getSeasonStatistics({ commit, state }) {
      try {
        // const statistics = await getLeagueStatisticsBySeason({ seasonId: rootGetters['filterGeneral/getSeasonId'] });
        const seasonId = state.usePastSeasonId ? state.pastSeasonId : state.seasonId;
        const statistics = await getLeagueStatisticsGroupedBySeason({
          seasonId,
        });
        commit('updateSeasonStatistics', statistics);
        commit('updateOldSeasonId', seasonId);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     * @param commit
     * @param getters
     * @param rootGetters
     * @return {Promise<void>}
     */
    // todo: Change this action to mutation, this has not async code
    updateRankingInSubcategories({ state, commit, getters, rootGetters }) {
      if (state.currentSeasonGames.length > 0) {
        commit('addRankingInSubcategories', {
          getters,
          isPremiumAccount: rootGetters['loginGeneral/getPremiumAccount'],
        });
      }
    },
    /** Obtiene las juagadas equipo Local */
    async getHomeTeamPlays({ commit, state }) {
      try {
        const statistics = await getTeamStatisticsBySeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
          teamId: state.gameForAnalysis.home_team,
        });
        if (statistics.data) {
          commit('updateHomeTeamPlays', statistics.data.data);
          commit('setHomeGames', statistics.data.games);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /** Obtiene las juagadas equipo Visitante */
    async getVisitingTeamPlays({ commit, state }) {
      try {
        const statistics = await getTeamStatisticsBySeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
          teamId: state.gameForAnalysis.visiting_team,
        });
        if (statistics.data) {
          commit('updateVisitingTeamPlays', statistics.data.data);
          commit('setVisitingGames', statistics.data.games);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /** Obtiene las estadísticas generales */
    async getCurrentSeasonPlays({ commit, state }) {
      try {
        // TODO: seasonId debe ser dinámico.
        // const currentSeasonPlays = await getLeaguePlaysBySeason({ seasonId: rootGetters['loginGeneral/seasonId'] });
        const currentSeasonPlays = await getLeaguePlaysBySeason({
          seasonId: state.usePastSeasonId ? state.pastSeasonId : state.seasonId,
        });
        commit('updateStats', currentSeasonPlays);
      } catch (e) {
        return Promise.reject(new Error(e));
      }
    },
    /**
     * @author Alejandro
     * @description This action gets all the plays in the current live game before
     * the socket connection is established.
     * @param commit
     * @param state
     * @param gameId
     * @return {Promise<void>}
     */
    async getCurrentLiveGamePlays({ state, commit }, gameId) {
      try {
        const plays = await getCurrentLiveGamePlays({ gameId });
        plays.forEach(play => {
          if (
            state.gameForAnalysis.game_id === play[state.indexesTLP.gameId] &&
            (state.gameForAnalysis.home_team === play[state.indexesTLP.teamId] ||
              state.gameForAnalysis.visiting_team === play[state.indexesTLP.teamId])
          ) {
            commit('addLiveGamePlay', play);
            let receivedPlay = receivedLivePlay(
              [...play],
              state.indexesTLP,
              state.gameForAnalysis,
              state.categoriesMap,
            );
            if (receivedPlay[state.indexesTLP.categoryId]) commit('addLiveGamePlay', receivedPlay);
          }
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    /**
     * @author Alejandro
     * @description This action listen for new incoming game's play by socket connection
     * @param commit
     * @param state
     * @param {Array} play
     */
    socket_new({ state, commit }, play) {
      const newAccomplishedLivePlay = play[1];
      if (
        state.gameForAnalysis.home_team === newAccomplishedLivePlay[state.indexesTLP.teamId] ||
        state.gameForAnalysis.visiting_team === newAccomplishedLivePlay[state.indexesTLP.teamId]
      ) {
        commit('addLiveGamePlay', newAccomplishedLivePlay);
        let newReceivedLivePlay = receivedLivePlay(
          [...newAccomplishedLivePlay],
          state.indexesTLP,
          state.gameForAnalysis,
          state.categoriesMap,
        );
        if (newReceivedLivePlay[state.indexesTLP.categoryId]) commit('addLiveGamePlay', newReceivedLivePlay);
      }
    },
    /**
     * @author Alejandro
     * @description This action listen for new incoming game's play to be updated by socket connection
     * @param commit
     * @param state
     * @param {Array} play
     */
    socket_update({ state, commit }, play) {
      const newAccomplishedLivePlay = play[1];
      if (
        state.gameForAnalysis.game_id === newAccomplishedLivePlay[state.indexesTLP.gameId] &&
        (state.gameForAnalysis.home_team === newAccomplishedLivePlay[state.indexesTLP.teamId] ||
          state.gameForAnalysis.visitingTeam === newAccomplishedLivePlay[state.indexesTLP.teamId])
      ) {
        commit('updateLiveGamePlay', newAccomplishedLivePlay);
        let newReceivedLivePlay = receivedLivePlay(
          [...newAccomplishedLivePlay],
          state.indexesTLP,
          state.gameForAnalysis,
          state.categoriesMap,
        );
        if (newReceivedLivePlay[state.indexesTLP.categoryId]) commit('addLiveGamePlay', newReceivedLivePlay);
      }
    },
    /**
     * @author Alejandro
     * @description This action listen for new incoming game's play to be deleted by socket connection
     * @param commit
     * @param state
     * @param {Array} play
     */
    socket_delete({ state, commit }, play) {
      const newAccomplishedLivePlay = play[1];
      if (
        state.gameForAnalysis.game_id === newAccomplishedLivePlay[state.indexesTLP.gameId] &&
        (state.gameForAnalysis.home_team === newAccomplishedLivePlay[state.indexesTLP.teamId] ||
          state.gameForAnalysis.visiting_team === newAccomplishedLivePlay[state.indexesTLP.teamId])
      ) {
        commit('deleteLiveGamePlay', newAccomplishedLivePlay);
        let newReceivedLivePlay = receivedLivePlay(
          [...newAccomplishedLivePlay],
          state.indexesTLP,
          state.gameForAnalysis,
          state.categoriesMap,
        );
        if (newReceivedLivePlay[state.indexesTLP.categoryId]) commit('addLiveGamePlay', newReceivedLivePlay);
      }
    },
    /**
     * @author Alejandro
     * @description Unsubscribe to live game channel, and clear state data related to live game
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @param commit
     */
    // eslint-disable-next-line no-unused-vars
    exitChannel({ state, getters, rootState, rootGetters, commit }) {
      const PREMIUM_ACCOUNT = rootGetters['loginGeneral/getPremiumAccount'];
      if (getters.isLive && PREMIUM_ACCOUNT) {
        commit('updateShowLiveData', false);
        commit('clearLiveTotals');
        state.livePlays.clear();
        this._vm.$socket.client.emit('unsubscribe', { channel: `game.${state.gameForAnalysis.game_id}` });
      }
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     */
    cleanStats({ state }) {
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.homeTeam.resetTotals();
          subCategory.visitingTeam.resetTotals();
          subCategory.isBetMainSubcategory = false;
        }
      }
      const mainCategories = Object.values(state.nextGamesDataStructure);
      // Clear all old mentions before fill new mentions
      mainCategories.forEach(mainCategory => {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.resetMentions();
        }
      });
      state.currentSeasonPlays = null;
      state.homeTeamPlays = [];
      state.visitingTeamPlays = [];
      state.homeTeamFilter.requiresPlaysFiltering = true;
      state.visitingTeamFilter.requiresPlaysFiltering = true;
      state.isRankingCalculating = true;
    },
    /**
     * @author José Rafael Gutierrez
     * @param state
     */
    cleanAllStats({ state }) {
      for (const mainCategory of Object.values(state.nextGamesDataStructure)) {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.homeTeam.resetTotals();
          subCategory.visitingTeam.resetTotals();
          subCategory.isBetMainSubcategory = false;
        }
      }
      const mainCategories = Object.values(state.nextGamesDataStructure);
      // Clear all old mentions before fill new mentions
      mainCategories.forEach(mainCategory => {
        for (const subCategory of Object.values(mainCategory.subcategories)) {
          subCategory.resetMentions();
        }
      });
      state.currentSeasonGames = [];
      state.currentSeasonTeams = [];
      state.gameForAnalysis = {};
      state.homeTeamPlays = [];
      state.visitingTeamPlays = [];
      state.currentSeasonPlays = null;
      state.totalSeasonFinishedGames = 0;
      state.totalHomeTeamFinishedGames = 0;
      state.totalVisitingTeamFinishedGames = 0;
      state.homeGames = [];
      state.showPostMatch = false;
      state.homeResultGame = null;
      state.awayResultGame = null;
      state.visitingGames = [];
      state.idAssetSelected = 1;
      state.showAdvanceFilter = false;
      state.selectedAnalysis = {};
      state.homeTeamFilter = {
        teamId: 0,
        statistics: 0,
        playingAs: [],
        matchResult: [],
        sections: [],
        requiresPlaysFiltering: true,
      };
      state.visitingTeamFilter = {
        teamId: 0,
        statistics: 0,
        playingAs: [],
        matchResult: [],
        sections: [],
        requiresPlaysFiltering: true,
      };
      state.isRankingCalculating = true;
    },
  },
};
