/**
 * Opciones para el Menu - Selector de Vistas
 */
const viewOptions = [
  { id: 1, label: 'RESUMEN', component: 'Resumen', src: '/icons/icn-resumen', show: true },
  { id: 2, label: 'BARRAS', component: 'Barras', src: '/icons/icn-barras', show: true },
  { id: 3, label: 'DISTRIBUCION', component: 'Distribucion', src: '/icons/icn-spider', show: true },
  { id: 4, label: 'FUERZA', component: 'Fuerza', src: '/icons/icn-fuerza', show: true },
  { id: 5, label: 'TABLA', component: 'Tabla', src: '/icons/icn-tabla', show: true },
  // { id: 6, label: 'CANCHA', component: 'Cancha', src: '/icons/icn-cancha', show: true },
  {
    id: 7,
    label: 'TENDENCIA LIVE',
    component: 'TendenciaLive',
    src: '/icons/icn-tendencia',
    show: true,
    banner: 'Popup_LiveTrend_OP',
  },
  // { id: 8, label: 'GOLSTATS LIVE', component: 'GolstatsLive', src: '/icons/icn-golstats-live', show: true },
  { id: 9, label: 'xG', component: 'Xg', src: '/icons/icn_xg', show: true, banner: 'Popup_xG_OP' },
];

/**
 * Opciones para los tipos de filtros
 */
const filterOptions = [
  { parentAnalysis: [1, 2, 3], value: 0, text: 'Realizado', block: 1 },
  { parentAnalysis: [1, 2, 3], value: 1, text: 'Recibido', block: 1 },
  { parentAnalysis: [1], value: 0, text: 'Posesion', block: 2 },
  { parentAnalysis: [1, 2], value: 0, text: 'Origen de gol realizado', block: 3 },
  { parentAnalysis: [1, 2], value: 1, text: 'Origen de gol recibido', block: 3 },
];

const analysisTypeOptions = [
  {
    name: 'Resultado de partido',
    id: 1,
  },
  {
    name: 'Goles',
    id: 2,
  },
  {
    name: 'Tiros de esquina',
    id: 3,
  },
];

exports.viewOptions = viewOptions;
exports.filterOptions = filterOptions;
exports.analysisTypeOptions = analysisTypeOptions;
