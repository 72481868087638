import axios from 'axios';

/**
 * Allows retrying a failed get request.
 * @author José Rafael Gutierrez
 * @param url
 * @param options
 * @param retries
 * @return {Promise<AxiosResponse<T>>}
 */
export async function fetchRetry({ url, options = {}, retries = 3 }) {
  return await axios
    .get(url, options)
    .then(response => response)
    .catch(e => {
      if (retries > 0) return fetchRetry({ url: url, options: options, retries: retries - 1 });
      return e;
    });
}
