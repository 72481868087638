import io from 'socket.io-client';
const socket = io('https://golstats-live-server-sockets.azurewebsites.net/', {
  autoConnect: false,
  transports: ['websocket'],
});

export function getSocket() {
  return socket;
}

export function connectSocket() {
  if (socket.disconnected) socket.connect();
}

export function disconnectSocket() {
  if (socket.connected) socket.disconnect();
}

export function connectChannel(channel) {
  if (socket.connected) {
    socket.emit('subscribe', {
      channel: channel,
    });
  }
}
