/**
 * @author José Rafael Gutierrez
 * @param newLivePlay
 * @param play
 * @param indexesTLP
 * @returns {*[]}
 */
export function duplicate(newLivePlay, play, indexesTLP) {
  let duplicate = [...play];
  if (newLivePlay[indexesTLP.categoryId] === 2) {
    duplicate[0] = 14;
  }
  if (newLivePlay[indexesTLP.categoryId] === 20) {
    duplicate[0] = 15;
  }
  return [14, 15].includes(duplicate[0]) ? duplicate : null;
}
