/**
 * @author José Rafael Gutierrez
 * @param games
 * @returns {(number|*)[][]}
 */
export function gamesStructureInfo(games) {
  return [
    [1, 1, games.local.win],
    [1, 2, games.local.lose],
    [1, 3, games.local.draw],
    [2, 1, games.visit.win],
    [2, 2, games.visit.lose],
    [2, 3, games.visit.draw],
  ];
}
