import { getStatsTops } from '@/utils/api/tops';
import { formatStats } from '@/utils/tops';
const state = {
  formatStats: [],
  topsTeams: {},
  topsPlayers: {},
  category: null,
  playersInfo: {},
  teamInfo: {},
  type: 'team',
  data_headers: [
    'team_id',
    'player_id',
    'playing_as',
    'match_result',
    'section',
    'total',
    'origen_x',
    'origen_y',
    'destino_x',
    'destino_y',
  ],
};

const getters = {
  top: state => {
    if (state.formatStats.length > 0 && state.category != null) {
      var topTeam = {};
      var topPlayer = {};
      var sortTopTeam = [];
      var sortTopPlayer = [];
      var selectedPlays = state.formatStats[state.category];
      selectedPlays.data.map(play => {
        if (topTeam[play.team_id]) {
          topTeam[play.team_id]['total'] = topTeam[play.team_id]['total'] + play.total;
        } else {
          topTeam[play.team_id] = {};
          topTeam[play.team_id]['total'] = play.total;
          topTeam[play.team_id]['name'] = state.teamInfo[play.team_id].name;
          topTeam[play.team_id]['img'] = state.teamInfo[play.team_id].img;
        }
        if (topPlayer[play.player_id]) {
          topPlayer[play.player_id]['total'] = topPlayer[play.player_id]['total'] + play.total;
        } else {
          topPlayer[play.player_id] = {};
          topPlayer[play.player_id]['total'] = play.total;
          if (state.playersInfo[play.player_id]) {
            topPlayer[play.player_id]['name'] = state.playersInfo[play.player_id].name;
            topPlayer[play.player_id]['img'] = state.playersInfo[play.player_id].img;
          } else {
            topPlayer[play.player_id]['name'] = '-';
            topPlayer[play.player_id]['img'] = 'SIN IMAGEN';
          }
        }
      });
      for (const idTeam in topTeam) {
        sortTopTeam.push(topTeam[idTeam]);
      }
      for (const idPlayer in topPlayer) {
        sortTopPlayer.push(topPlayer[idPlayer]);
      }
      sortTopTeam.sort((a, b) => (a.total < b.total ? 1 : -1));
      sortTopPlayer.sort((a, b) => (a.total < b.total ? 1 : -1));
      state.topsTeams[state.category] = sortTopTeam;
      state.topsPlayers[state.category] = sortTopPlayer;
      return state.topsPlayers;
    } else {
      return null;
    }
  },
};

const mutations = {
  setTops(state, stats) {
    let catPlays = [];
    let teamData = {};
    let playersData = {};
    stats.data.map(data => {
      catPlays[data.category] = [];
      catPlays[data.category]['name'] = data.name;
      catPlays[data.category]['data'] = data.data.map(play => {
        return formatStats(state.data_headers, play);
      });
    });
    stats.teams.map(data => {
      teamData[data[0]] = {};
      teamData[data[0]]['name'] = data[1];
      teamData[data[0]]['img'] = data[3];
      teamData[data[0]]['abrv'] = data[2];
    });
    stats.players.map(data => {
      playersData[data[0]] = {};
      playersData[data[0]]['name'] = data[1];
      playersData[data[0]]['img'] = data[2];
    });
    state.teamInfo = teamData;
    state.formatStats = catPlays;
    state.playersInfo = playersData;
  },
  setCategory(state, category) {
    state.category = category;
  },
};

const actions = {
  /**
   * @author Fernando Mellone
   * @description Get all the plays with their header
   * @param commit
   * @param gameId
   * @return {Promise<never>}
   */
  async getTopsStats({ commit }, data) {
    try {
      var response = await getStatsTops(data.season);
      commit('setCategory', data.category);
      commit('setTops', response);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
