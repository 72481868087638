import Vue from 'vue';
import Vuex from 'vuex';

import account from '@/store/account';
import tips from '@/store/tips';
import general from '@/store/general';
import media from '@/store/modules/media';
import tops from '@/store/modules/tops';
import bets from '@/store/modules/bets';
import next from '@/store/modules/next';
import league from '@/store/modules/league';
import profile from '@/store/modules/profile';
import login from '@/store/modules/login';
import register from '@/store/modules/register';
import alert from '@/store/modules/alert';
import routes from '@/store/routes';
import auth from '@/store/auth';
import terms from '@/store/terms';
import recover from '@/store/modules/recover';
import xg from '@/store/modules/xg';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSectionLoading: false,
    displayDrawerMenu: false,
    displayWidth: 769,
    pauseVideos: false,
    displayPremiumMessage: false,
    displayPremiumMessageV2: false,
    isModalInfoVisible: false,
    modalInfoTitle: 'GOLSTATS',
    modalInfoContentComponent: '',
  },
  mutations: {
    setIsSectionLoading(state, isSectionLoading) {
      state.isSectionLoading = isSectionLoading;
    },
    SET_MENU_DRAWER(state) {
      state.displayDrawerMenu = !state.displayDrawerMenu;
    },
    SET_DISPLAY_SIZE(state, value) {
      state.displayWidth = value;
    },
    SET_PAUSE_VIDEO(state, value) {
      state.pauseVideos = value;
    },
    SET_PREMIUM_MESSAGE(state, value) {
      state.displayPremiumMessage = value;
    },
    SET_PREMIUM_MESSAGE_V2(state, value) {
      state.displayPremiumMessageV2 = value;
    },
    setModalInfoVisible(state, { isVisible, title, componentName }) {
      window.parent.postMessage({ message: 'modalShown', isShown: isVisible }, '*');
      state.modalInfoTitle = title;
      state.modalInfoContentComponent = componentName;
      state.isModalInfoVisible = isVisible;
    },
  },
  getters: {
    displayDrawerMenu: state => state.displayDrawerMenu,
    pauseVideos: state => state.pauseVideos,
    displayPremiumMessage: state => state.displayPremiumMessage,
    displayPremiumMessageV2: state => state.displayPremiumMessageV2,
  },
  modules: {
    account,
    tips,
    general,
    routes,
    auth,
    terms,
    ...next,
    ...league,
    ...profile,
    ...login,
    ...register,
    ...alert,
    ...recover,
    ...xg,
    ...tops,
    ...bets,
    ...media,
  },
});
