/**
 * @author José Rafael Gutierrez
 * @param play
 * @param indexesTLP
 * @param gameForAnalysis
 * @param categoriesMap
 * @returns {*[]}
 */
export function receivedLivePlay(play, indexesTLP, gameForAnalysis, categoriesMap) {
  play[indexesTLP.teamId] =
    gameForAnalysis.home_team === play[indexesTLP.teamId] ? gameForAnalysis.visiting_team : gameForAnalysis.home_team;
  const CAT = categoriesMap.find(cat => cat.categoryValue.includes(play[indexesTLP.categoryId]));
  play[indexesTLP.categoryId] = CAT ? CAT.categoriesOpponent[0] : null;
  play[indexesTLP.playId] = play[indexesTLP.playId] + '_R';
  play[indexesTLP.length] = true;
  return play;
}
