import { fetchRetry } from '@/utils/api/fetchRetry';

/**
 * @author Ricardo Manzano
 */
export async function getStatsTops() {
  try {
    const url = `https://8grl16o77j.execute-api.us-west-2.amazonaws.com/prod/tops/1401`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
