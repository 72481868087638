export function decryptTournaments(tournaments) {
  return tournaments.map(tournament => {
    tournament.scenarios = tournament.scenarios.map(scenario => {
      scenario.bets = scenario.bets.map(bet => {
        bet.bet_suggested = window.atob(bet.bet_suggested);
        bet.bet_suggested = JSON.parse(bet.bet_suggested);
        bet.percentage_maximized = window.atob(bet.percentage_maximized);
        bet.prob_actual = window.atob(bet.prob_actual);
        bet.prob_initial = window.atob(bet.prob_initial);
        return bet;
      });
      scenario.prob_maximized = window.atob(scenario.prob_maximized);
      return scenario;
    });
    return tournament;
  });
}
