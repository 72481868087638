import { getPlays, getPlaysPostMatch } from '@/utils/api/xg';
import { formatPlay, filterPlays } from '@/utils/xg';
import { normalPlayer } from '@/data/xg/chalkboardCategories';
import { ceilMod } from '@/utils/maths.js';

const state = {
  teamSelected: 'Local',
  xGLocalPlays: [],
  xGVisitingPlays: [],
};

const getters = {
  plays: (state, getters, rootState) => {
    const selectedTeamPlays = state[`xG${state.teamSelected}Plays`];
    const filterToBeApplied = state.teamSelected === 'Local' ? 'homeTeamFilter' : 'visitingTeamFilter';

    const filters = rootState.nextGeneral[filterToBeApplied];

    const filteredTeamPlays = filterPlays(selectedTeamPlays, filters);

    return filteredTeamPlays;
  },

  goalsQty: (state, getters) => {
    const category = normalPlayer.find(c => c.name === 'Goles');

    return getters.plays.filter(play => category.categories.includes(play.category_id)).length;
  },

  shotsOnGoalQty: (state, getters) => {
    return getters.plays.filter(play => play.category_id === 510).length;
  },

  xG: (state, getters) => {
    const momentOfPlaySet = new Set();
    let acc = getters.plays.reduce((accumulator, play) => {
      if (!momentOfPlaySet.has(play.moment_of_play)) {
        momentOfPlaySet.add(play.moment_of_play);
        // eslint-disable-next-line no-param-reassign
        accumulator += play.xG2;
      }
      return accumulator;
    }, 0);

    return ceilMod(acc, 2);
  },
};

const mutations = {
  setTeamSelected(state, team) {
    state.teamSelected = team;
  },

  setPlays(state, plays) {
    state.xGLocalPlays = plays.local_team.map(play => {
      return formatPlay(plays.data_headers, play);
    });

    state.xGVisitingPlays = plays.visiting_team.map(play => {
      return formatPlay(plays.data_headers, play);
    });
  },

  cleanXgStore(state) {
    state.localStorage = 'Local';
    state.xGLocalPlays = [];
    state.xGVisitingPlays = [];
  },
};

const actions = {
  /**
   * @author Fernando Mellone
   * @description Get all the plays with their header
   * @param commit
   * @param gameId
   * @return {Promise<never>}
   */
  async getXgPlays({ commit }, { seasonId, localTeamId, visitingTeamId, postMatch = false }) {
    try {
      var response = {};
      if (postMatch) {
        response = await getPlaysPostMatch({ seasonId, localTeamId, visitingTeamId });
      } else {
        response = await getPlays({ seasonId, localTeamId, visitingTeamId });
      }
      commit('setPlays', response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
