import { getCurrentRows } from '@/data/teamRows';
import { getScenarios, getTournamentsWithScenarios, getBetsPerTeam } from '@/utils/api/tips';
import { getTipsPercentage } from '@/utils/calculations';
import loginGeneral from '@/store/modules/login/general';

const getSuggestedTeam = bet_suggested => {
  if (bet_suggested.home_team_logo && bet_suggested.away_team_logo) {
    return {
      logo1: bet_suggested.home_team_logo,
      logo2: bet_suggested.away_team_logo,
      suggestion: bet_suggested.suggestion,
    };
  } else if (!bet_suggested.home_team_logo) {
    return { logo1: bet_suggested.away_team_logo, suggestion: bet_suggested.suggestion };
  } else {
    return { logo1: bet_suggested.home_team_logo, suggestion: bet_suggested.suggestion };
  }
};

export default {
  namespaced: true,
  state: {
    showFiltersMobile: false,
    selectedRisk: null,
    selectedBet: null,
    selectedTeams: null,
    qps: false,
    currentTeams: [],
    resetButtonVisibility: false,
    betAmount: '100',
    riskType: '',
    userType: '',
    scenarios: [],
    tournaments: [],
    tournamentsToSelect: [],
    selectedTournament: null,
    initialScenarios: [],
    loading: false,
    isWidget: false,
  },
  getters: {
    /**
     * @author Crishian Gómez
     * @description Return chosen user type
     * @param {Object} state
     * @return {number} userType
     */
    getUserType: state => state.userType,
    /**
     * @author Crishian Gómez
     * @description Return chosen risk type
     * @param {Object} state
     * @return {number} riskType
     */
    getRiskType: state => state.riskType,
    /**
     * @author Crishian Gómez
     * @description Return bet amount
     * @param {Object} state
     * @return {number} betAmount
     */
    getBetAmount: state => state.betAmount,
    /**
     * @author Crishian Gómez
     * @description Total sum of investment realized
     * @param {Object} state
     * @return {number} total
     */
    totalInvestment(state) {
      let total = state.currentTeams.reduce((a, b) => {
        const INVESTMENT = Number(b.investment.toFixed(2));
        return a + INVESTMENT;
      }, 0);
      return total.toFixed(2);
    },
    /**
     * @author Crishian Gómez
     * @description Current formula to determine the total amount provided by the invesment realized
     * @param {Object} state
     * @return {float} total returned investment
     */
    returnInvestment(state) {
      let val = state.currentTeams.reduce((a, b) => {
        let symbol = b.winAmount[0];
        let momio = b.winAmount.substring(1);
        const INVESTMENT = Number(b.investment);
        if (symbol === '+') {
          return a + (parseInt(momio) / 100 + 1) * INVESTMENT;
        } else {
          if (parseInt(b.winAmount) > 0) {
            return a + (INVESTMENT * parseInt(b.winAmount)) / 100 + INVESTMENT;
          } else {
            return a + (INVESTMENT * 100) / Math.abs(b.winAmount) + INVESTMENT;
          }
          // return a + INVESTMENT / (parseInt(b.winAmount) / 100) + INVESTMENT;
        }
      }, 0);
      return val.toFixed(2);
    },
    filteredTeams(state) {
      return getCurrentRows(state.selectedRisk);
    },
    /**
     * @author Crishian Gómez
     * @description Current formula to determine the total amount won given parameter 'qps'
     * @param {Object} state
     * @return {float} total returned investment
     */
    returnQpsInvestment(state) {
      let totalInvestment = state.currentTeams.reduce((a, b) => {
        let symbol = b.winAmount[0];
        let momio = b.winAmount.substring(1);
        const INVESTMENT = Number(b.investment.toFixed(2));
        if (b.qps === false) {
          if (symbol === '+') {
            return a + (parseInt(momio) / 100 + 1) * INVESTMENT;
          } else {
            if (parseInt(b.winAmount) > 0) {
              return a + (INVESTMENT * parseInt(b.winAmount)) / 100 + INVESTMENT;
            } else {
              return a + (INVESTMENT * 100) / Math.abs(b.winAmount) + INVESTMENT;
            }
          }
        } else {
          return a;
        }
      }, 0);
      return totalInvestment.toFixed(2);
    },
    /**
     * @author Crishian Gómez
     * @description Current formula to determine the risk precentage
     * @param {Object} state
     * @return {float} current risk
     */
    freePercentage(state) {
      const selectedScenario = state.scenarios.find(scenario => {
        return scenario._id == state.selectedRisk;
      });

      let percentage;

      if (selectedScenario) {
        percentage = getTipsPercentage(
          selectedScenario.bets.map(bet => {
            return { momio: bet.odd, bet: Number((state.betAmount / selectedScenario.bets.length).toFixed(2)) };
          }),
        );
      }
      return Math.round(percentage);
    },
    premiumPercentage(state) {
      const percentage = getTipsPercentage(
        state.currentTeams.map(match => {
          return { momio: match.odd, bet: match.investment };
        }),
      );
      return Math.round(percentage);
    },
  },
  mutations: {
    setShowFiltersMobile(state, showFiltersMobile) {
      state.showFiltersMobile = showFiltersMobile;
    },
    setSelectedRisk(state, selectedRisk) {
      state.selectedRisk = selectedRisk;
    },
    setSelectedBet(state, selectedBet) {
      state.selectedBet = selectedBet;
    },
    setSelectedTeams(state, selectedTeams) {
      state.selectedTeams = selectedTeams;
    },
    toggleQps(state, value) {
      state.qps = value;
    },
    setInvestment(state, { index, amount }) {
      state.currentTeams[index].investment = amount || 1;
    },
    /**
     * @author Fernando Mellone
     * @description If user make changes to the suggested investments, turns TRUE
     * @param {Object} state
     * @param {Boolean} newStatus
     */
    setResetButtonVisibility(state, newStatus) {
      if (state.resetButtonVisibility !== newStatus) {
        state.resetButtonVisibility = newStatus;
      }
    },
    setInitialScenarios(state, data) {
      if (data) {
        state.initialScenarios = data;
      } else if (state.selectedTournament !== null && state.tournaments && state.tournaments.length > 0) {
        const { scenarios } = state.tournaments.find(tournament => {
          return tournament.tournament_id === state.selectedTournament.id;
        });
        state.initialScenarios = scenarios;
      }
    },
    setScenarios(state, newScenarios) {
      let scenariosAux = newScenarios;
      if (!scenariosAux) {
        if (state.selectedTournament !== null && state.tournaments && state.tournaments.length > 0) {
          const { scenarios } = state.tournaments.find(tournament => {
            return tournament.tournament_id === state.selectedTournament.id;
          });
          scenariosAux = scenarios;
        } else {
          scenariosAux = [];
        }
      }
      const formattedScenarios = [];

      scenariosAux.forEach(scenario => {
        const auxBets = scenario.bets.map(bet => {
          const suggestedTeam = getSuggestedTeam(bet.bet_suggested, bet.bet_type_id);
          let newBetRow = {
            ...bet,
            prob_actual: bet.prob_actual,
            logo1: suggestedTeam.logo1,
            logo2: suggestedTeam.logo2,
            suggestion: suggestedTeam.suggestion,
            percentage_maximized: bet.percentage_maximized,
            matchId: bet.game_id,
            qps: false,
            winrate: `${bet.prob_actual}%`,
            winAmount: String(bet.odd),
          };

          const isPremium = loginGeneral.state.user.premiumAccount;

          const auxInvestment =
            isPremium || state.isWidget
              ? state.betAmount * (bet.percentage_maximized / 100)
              : state.betAmount / scenario.bets.length;

          newBetRow.investment = Number(auxInvestment.toFixed(2));

          return newBetRow;
        });
        const formattedScenario = { ...scenario, bets: auxBets };

        formattedScenarios.push(formattedScenario);
      });

      // Se debe cargar los datos completos
      state.scenarios = formattedScenarios;
    },
    setQps(state, { index, value }) {
      state.currentTeams[index].qps = value;
    },
    setCurrentTeams(state) {
      let selectedScenario = state.scenarios.find(scenario => {
        return scenario._id == state.selectedRisk;
      });
      if (selectedScenario) {
        state.currentTeams = [...selectedScenario.bets];
      }
    },
    setBetAmount(state, amount) {
      state.betAmount = amount;
    },
    updateBets(state) {
      let updatedScenarios = state.scenarios.map(scenario => {
        let updatedBets = scenario.bets.map(bet => {
          const isPremium = loginGeneral.state.user.premiumAccount;
          return {
            ...bet,
            investment:
              isPremium || state.isWidget
                ? Number((state.betAmount * (bet.percentage_maximized / 100)).toFixed(2))
                : Number((state.betAmount / scenario.bets.length).toFixed(2)),
          };
        });
        return { ...scenario, bets: updatedBets };
      });
      state.scenarios = updatedScenarios;
    },
    setUserType(state, userType) {
      state.userType = userType;
    },
    setRiskType(state, riskType) {
      state.riskType = riskType;
    },
    toggleLoading(state) {
      state.loading = true;
      setTimeout(() => {
        state.loading = false;
      }, 400);
    },
    setTournaments(state, tournaments) {
      state.tournaments = tournaments;
      state.tournamentsToSelect = tournaments.map(tournament => {
        return {
          id: tournament.tournament_id,
          name: tournament.tournament_name,
        };
      });
      state.tournamentsToSelect.sort((a, b) => {
        return a.id - b.id;
      });
      if (state.tournamentsToSelect[0].id === 0) {
        state.tournamentsToSelect.push(state.tournamentsToSelect.shift());
      }
      state.selectedTournament = state.tournamentsToSelect[0];
    },
    setSelectedTournament(state, tournament) {
      state.selectedTournament = tournament;
    },
    setIsWidget(state, isWidget) {
      state.isWidget = isWidget;
    },
  },
  actions: {
    /**
     * @author Crishian Gómez
     * @description Find and update the amount invested on games table
     * @param {Object} state
     * @param {Object} commit
     * @param {Object} amount | quantity to update
     * @param {Object} id | row's id to find row
     */
    updateInvestment({ state, commit }, { amount, id }) {
      let index = state.currentTeams.findIndex(r => id === r.game_id);
      commit('setInvestment', { index, amount: Number(amount) });
      commit(
        'setBetAmount',
        Number(
          state.currentTeams
            .reduce((acc, curr) => {
              return Number(acc) + Number(curr.investment);
            }, 0)
            .toFixed(2),
        ),
      );
      commit('setResetButtonVisibility', true);
    },
    /**
     * @author Crishian Gómez
     * @description Find out and update the qps on games table boolean
     * @param {Object} state
     * @param {Object} commit
     * @param {Boolean} value
     * @param {Object} id | row's id to find row
     */
    updateQps({ state, commit }, { value, id }) {
      let index = state.currentTeams.findIndex(r => id === r.game_id);
      commit('setQps', { index, value });
    },
    async fetchScenarios({ state, commit }, newRiskType) {
      let scenarios = await getScenarios();
      const isPremium = loginGeneral.state.user.premiumAccount;
      commit('setUserType', isPremium || state.isWidget ? 2 : 1);
      if (newRiskType) {
        commit('setSelectedRisk', newRiskType);
      } else if (!state.selectedRisk) {
        commit('setSelectedRisk', isPremium || state.isWidget ? 4 : 1);
      }
      commit('setInitialScenarios', scenarios);
      commit('setScenarios', scenarios);
      commit('setCurrentTeams');
    },
    resetScenarios({ state, commit }) {
      commit('setScenarios', state.initialScenarios);
      commit('setCurrentTeams');
    },
    async fetchTournamentsWithScenarios({ state, commit }, newRiskType) {
      const tournaments = await getTournamentsWithScenarios();
      const isPremium = loginGeneral.state.user.premiumAccount;
      commit('setUserType', isPremium || state.isWidget ? 2 : 1);
      commit('setTournaments', tournaments);
      if (newRiskType) {
        commit('setSelectedRisk', newRiskType);
      } else if (!state.selectedRisk) {
        commit('setSelectedRisk', isPremium || state.isWidget ? 4 : 1);
      }
      commit('setInitialScenarios');
      commit('setScenarios');
      commit('setCurrentTeams');
    },
    async fetchTournamentsPerTeam({ state, commit }, { newRiskType, teamId }) {
      const tournaments = await getBetsPerTeam({ teamId });
      const isPremium = loginGeneral.state.user.premiumAccount;
      commit('setUserType', isPremium ? 2 : 1);
      commit('setTournaments', tournaments);
      if (newRiskType) {
        commit('setSelectedRisk', newRiskType);
      } else if (!state.selectedRisk) {
        commit('setSelectedRisk', isPremium || state.isWidget ? 4 : 1);
      }
      commit('setInitialScenarios');
      commit('setScenarios');
      commit('setCurrentTeams');
    },
  },
};
