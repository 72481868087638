import axios from 'axios';
import store from '@/store';

axios.interceptors.request.use(
  /**
   * @author Alejandro
   * @description This interceptor checks user's time to live session before each request to the server.
   * @param {AxiosRequestConfig} config
   * @returns {Promise<AxiosRequestConfig>}
   */
  async config => {
    if (window.location.pathname.includes('team-players')) {
      if (config.url.includes('pa3kubpuhg.execute-api.us-west-2')) {
        config.headers['Authorization'] = '';
      }
    }
    return config;
  },
  e => {
    return Promise.reject(e);
  },
);

axios.interceptors.response.use(
  response => response,
  e => {
    if (e.request && e.request.status && (e.request.status === 401 || e.request.status === 403)) {
      store.dispatch('loginGeneral/doLogout', { reloadPage: true, requestStatus: e.request.status });
    }
    return Promise.reject(e);
  },
);
