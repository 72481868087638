import { getPlayValueIndexesFromArray } from '@/utils/misc';

/**
 * Defines the index position of each value inside a team stat play.
 */
const leagueStatisticsValues = ['teamId', 'categoryId', 'matchlapse', 'section', 'matchResult', 'playingAs', 'total'];

let leagueStatisticsIndex = null;

export default function getLeagueStatisticsIndexes() {
  if (!leagueStatisticsIndex) {
    leagueStatisticsIndex = getPlayValueIndexesFromArray(leagueStatisticsValues);
  }
  return leagueStatisticsIndex;
}
