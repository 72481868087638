import { fetchRetry } from '@/utils/api/fetchRetry';

/**
 * @author Alejandro
 * @param {Object} params
 * @param {number} params.seasonId
 * @return {Promise<{type: ArrayConstructor, required: boolean}|{type: Array | ArrayConstructor, required: boolean}|[{score: number[], data: string, visiting_team: number, season_id: number, home_team: number, game_id: number}, {score: number[], data: string, visiting_team: number, season_id: number, home_team: number, game_id: number}]>}
 */
export async function getGamesBySeason({ seasonId }) {
  try {
    const url = `https://8tvo5bp9ai.execute-api.us-west-2.amazonaws.com/prod/gamesBySeason/${seasonId}`;
    const { data } = await fetchRetry({ url: url });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}
export async function getGamesBySeasonV2({ seasonId, gameId }) {
  try {
    const url = `https://iwxfzq6e2h.execute-api.us-west-2.amazonaws.com/prod/statisticsLeagueBySeason/${seasonId}/games/${gameId}`;
    const { data } = await fetchRetry({ url: url });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getCurrentLiveGamePlays({ gameId }) {
  try {
    const url = `https://2uj7ojhmt5.execute-api.us-west-2.amazonaws.com/prod/statisticsLive/${gameId}`;
    const response = await fetchRetry({ url: url });
    return response.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @author Cristhian
 * @param {Object} params
 * @param {number} params.gameId
 * @return {Promise<{type: Object}},
 */
export async function getSpecialMentionsByGame({ gameId }) {
  try {
    const url = `https://55sbpmq0f5.execute-api.us-west-2.amazonaws.com/prod/specialMentions/${gameId}`;
    const response = await fetchRetry({ url: url });
    return response.data.data || [];
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function fetchGamesByTournament({ tournamentId }) {
  try {
    const { data } = await fetchRetry({
      url: `https://jiao2kwfc0.execute-api.us-west-2.amazonaws.com/prod/gamesByTournament/${tournamentId}`,
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchLiveGames() {
  try {
    const response = await fetchRetry({
      url: `https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live`,
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
}
