import router from '@/router';

export default {
  namespaced: true,
  actions: {
    goToGame({ commit }, { name, gameId, currentSeasonId, hideHeader, page = false, isCup = false, backRoute }) {
      commit('setIsSectionLoading', true, { root: true });
      setTimeout(() => {
        const query = {
          currentSeason: currentSeasonId,
        };
        if (page) {
          query.pages = 'calendar-gs-2';
        }
        if (hideHeader) {
          query.is_iframe = true;
        }
        if (isCup) {
          query.isCup = true;
        }
        if (backRoute) {
          query.backRoute = backRoute;
        }
        router.push({
          name: name || 'match-analysis',
          params: { gameId },
          query,
        });
      }, 10);
    },
  },
};
