/**
 * @author Alejandro
 * @default this class contains data structure for each team inside of each next games subcategory
 */
export default class NGTeamData {
  maximumLiveSection = 1;
  accomplished = {
    totalPostGame: 0,
    avgPostGame: 0,
    totalLive: 0,
    avgPostGameTrendLive: {
      accumulated: this.getZeroTotalsTrendSections(),
      sections: this.getZeroTotalsTrendSections(),
    },
    totalsLiveTrendLive: {
      accumulated: this.getZeroTotalsTrendSections(),
      sections: this.getZeroTotalsTrendSections(),
    },
    ranking: null,
    leader: {},
  };
  received = {
    totalPostGame: 0,
    avgPostGame: 0,
    totalLive: 0,
    avgPostGameTrendLive: {
      accumulated: this.getZeroTotalsTrendSections(),
      sections: this.getZeroTotalsTrendSections(),
    },
    totalsLiveTrendLive: {
      accumulated: this.getZeroTotalsTrendSections(),
      sections: this.getZeroTotalsTrendSections(),
    },
    ranking: null,
    leader: {},
  };

  setMaximumLiveSection(playSection) {
    if (playSection > this.maximumLiveSection) this.maximumLiveSection = playSection;
  }

  getZeroTotalsTrendSections() {
    return [0, 0, 0, 0, 0, 0];
  }

  resetTotals() {
    this.accomplished.totalPostGame = 0;
    this.accomplished.avgPostGame = 0;
    this.received.totalPostGame = 0;
    this.received.avgPostGame = 0;
    this.accomplished.avgPostGameTrendLive.sections = this.getZeroTotalsTrendSections();
    this.accomplished.avgPostGameTrendLive.accumulated = this.getZeroTotalsTrendSections();
    this.received.avgPostGameTrendLive.sections = this.getZeroTotalsTrendSections();
    this.received.avgPostGameTrendLive.accumulated = this.getZeroTotalsTrendSections();
  }

  resetTotalsLive() {
    this.accomplished.totalLive = 0;
    this.received.totalLive = 0;
    this.accomplished.totalsLiveTrendLive.sections = this.getZeroTotalsTrendSections();
    this.accomplished.totalsLiveTrendLive.accumulated = this.getZeroTotalsTrendSections();
    this.received.totalsLiveTrendLive.sections = this.getZeroTotalsTrendSections();
    this.received.totalsLiveTrendLive.accumulated = this.getZeroTotalsTrendSections();
  }

  /**
   * @author Alejandro
   * @description Increase accomplished total or total live by one
   * @param {number} playSection - The play's time section, can be any integer between 1 and 6
   * @param {number} total
   * @param {boolean=} isLive
   */
  increaseAccomplishedByOne(playSection, total, isLive) {
    if (isLive) {
      this.setMaximumLiveSection(playSection);
      this.accomplished.totalLive += total;
      this.accomplished.totalsLiveTrendLive.sections[playSection - 1] += total;
      this.increaseTrendLiveAccumulatedByOne(this.accomplished.totalsLiveTrendLive, playSection, total);
    }
    this.accomplished.totalPostGame += total;
    this.accomplished.avgPostGameTrendLive.sections[playSection - 1] += total;
    this.increaseTrendLiveAccumulatedByOne(this.accomplished.avgPostGameTrendLive, playSection, total);
  }

  /**
   * @author Alejandro
   * @description Increase received total post game or total live by one
   * @param {number} playSection - The play's time section, can be any integer between 1 and 6
   * @param {number} total
   * @param {boolean=} isLive
   */
  increaseReceivedByOne(playSection, total, isLive) {
    if (isLive) {
      this.setMaximumLiveSection(playSection);
      this.received.totalLive += total;
      this.received.totalsLiveTrendLive.sections[playSection - 1] += total;
      this.increaseTrendLiveAccumulatedByOne(this.received.totalsLiveTrendLive, playSection, total);
    }
    this.received.totalPostGame += total;
    this.received.avgPostGameTrendLive.sections[playSection - 1] += total;
    this.increaseTrendLiveAccumulatedByOne(this.received.avgPostGameTrendLive, playSection, total);
  }

  /**
   * @author Alejandro
   * @param {Object} trendLive
   * @param {Array<number>} trendLive.accumulated
   * @param {number} playSection
   * @param {number} total
   */
  increaseTrendLiveAccumulatedByOne(trendLive, playSection, total) {
    for (let section = playSection - 1; section < trendLive.accumulated.length; section++) {
      trendLive.accumulated[section] += total;
    }
  }

  // todo: decrease methods for live plays in totals trend live

  /**
   * @author Alejandro
   * @param {number} playSection
   * @param {number} total
   */
  decreaseAccomplishedLiveByOne(playSection, total) {
    if (this.accomplished.totalLive > 0) {
      this.accomplished.totalLive -= total;
    }
    if (this.accomplished.totalsLiveTrendLive.sections[playSection - 1] > 0) {
      this.accomplished.totalsLiveTrendLive.sections[playSection - 1] -= total;
      this.decreaseTotalsLiveTrendLiveAccumulatedByOne(this.accomplished.totalsLiveTrendLive, playSection, total);
    }
  }

  /**
   * @author Alejandro
   * @param playSection
   * @param total
   */
  decreaseReceivedLiveByOne(playSection, total) {
    if (this.received.totalLive > 0) {
      this.received.totalLive -= total;
    }
    if (this.received.totalsLiveTrendLive.sections[playSection - 1] > 0) {
      this.received.totalsLiveTrendLive.sections[playSection - 1] -= total;
      this.decreaseTotalsLiveTrendLiveAccumulatedByOne(this.received.totalsLiveTrendLive, playSection, total);
    }
  }

  /**
   * @author Alejandro
   * @param {Object} totalsLiveTrendLive
   * @param {Array<number>} totalsLiveTrendLive.accumulated
   * @param {number} playSection
   * @param {number} total
   */
  decreaseTotalsLiveTrendLiveAccumulatedByOne(totalsLiveTrendLive, playSection, total) {
    for (let section = totalsLiveTrendLive.accumulated.length - 1; section >= playSection - 1; section--) {
      totalsLiveTrendLive.accumulated[section] -= total;
    }
  }

  /**
   * @author Alejandro
   * @description Calculate avg values in every data, except for live data
   * @param {number} totalFinishedGames
   */
  calculateAvg(totalFinishedGames) {
    // division by zero does not exist
    if (!totalFinishedGames) return;
    this.accomplished.avgPostGame = this.accomplished.totalPostGame / totalFinishedGames;
    this.received.avgPostGame = this.received.totalPostGame / totalFinishedGames;
    this.accomplished.avgPostGameTrendLive.sections = this.getAvgPostGameTrendLiveAvg(
      this.accomplished.avgPostGameTrendLive.sections,
      totalFinishedGames,
    );
    this.accomplished.avgPostGameTrendLive.accumulated = this.getAvgPostGameTrendLiveAvg(
      this.accomplished.avgPostGameTrendLive.accumulated,
      totalFinishedGames,
    );
    this.received.avgPostGameTrendLive.sections = this.getAvgPostGameTrendLiveAvg(
      this.received.avgPostGameTrendLive.sections,
      totalFinishedGames,
    );
    this.received.avgPostGameTrendLive.accumulated = this.getAvgPostGameTrendLiveAvg(
      this.received.avgPostGameTrendLive.accumulated,
      totalFinishedGames,
    );
  }

  /**
   * @author Alejandro
   * @description Calculate avg value for each section value inside of each totals trend live types,
   * and returns a new array with avg values
   * @param {Array<number>} avgPostGameTrendLiveType - Can be sections or accumulated type
   * @param {number} totalFinishedGames
   * @return {Array<number>}
   */
  getAvgPostGameTrendLiveAvg(avgPostGameTrendLiveType, totalFinishedGames) {
    return avgPostGameTrendLiveType.map(sectionTotal => sectionTotal / totalFinishedGames);
  }

  /**
   * @author José Rafael Gutierrez
   * @param rankingData
   * @param indexesLS
   * @param filter
   * @param {number} accomplishedCategoryId
   * @param {number} receivedCategoryId
   * @param {Array<Object>} teams
   * @param {boolean} isPremiumAccount
   * @param {string} teamType - values can be 'homeTeam' or 'visitingTeam'
   * @return void
   */
  calculateRanking(
    rankingData,
    indexesLS,
    filter,
    accomplishedCategoryId,
    receivedCategoryId,
    teams,
    isPremiumAccount,
    teamType,
  ) {
    let rankingGeneralData = rankingData ? [...rankingData] : [];
    if (isPremiumAccount) {
      if (rankingData) {
        rankingGeneralData = rankingData.filter(
          statistics =>
            filter.playingAs.includes(statistics[indexesLS.playingAs]) &&
            filter.matchResult.includes(statistics[indexesLS.matchResult]) &&
            filter.sections.includes(statistics[indexesLS.section]),
        );
      }
    }
    this.setTeamRanking(
      rankingGeneralData,
      accomplishedCategoryId,
      indexesLS,
      filter.teamId,
      teams,
      'accomplished',
      teamType,
    );
    this.setTeamRanking(rankingGeneralData, receivedCategoryId, indexesLS, filter.teamId, teams, 'received', teamType);
  }

  /**
   * @author José Rafael Gutierrez
   * @param rankingGeneralData
   * @param {number} categoryId
   * @param indexesLS
   * @param teamId
   * @param {Array<Object>} teams
   * @param type
   * @param {string} teamType - values can be 'homeTeam' or 'visitingTeam'
   * @return void
   */
  setTeamRanking(rankingGeneralData, categoryId, indexesLS, teamId, teams, type, teamType) {
    const POSITIONS = teams
      .sort((a, b) => a.team_id - b.team_id)
      .map(team => {
        return {
          teamId: team.team_id,
          points: team[`${teamType}FilterGames`]
            ? rankingGeneralData.reduce((total, statistics) => {
                if (statistics[indexesLS.teamId] === team.team_id && categoryId === statistics[indexesLS.categoryId]) {
                  return total + statistics[indexesLS.total];
                }
                return total;
              }, 0) / team[`${teamType}FilterGames`]
            : 0,
        };
      })
      .sort((a, b) => b.points - a.points);
    this[type].ranking = POSITIONS.findIndex(team => team.teamId === teamId) + 1;
    this[type].leader = POSITIONS[0];
  }
}
