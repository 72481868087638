export const getCurrentRows = id => {
  let rows = [];
  switch (id) {
    case 1:
      rows = [
        {
          id: 1,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Chivas',
          winAmount: '-118',
          winrate: '57.13%',
          investment: '100',
          qps: false,
          matchId: 62220,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/1.png',
        },
        {
          id: 5,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Cruz Azul',
          winAmount: '-188',
          winrate: '66.28%',
          investment: '100',
          qps: false,
          matchId: 62221,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/5.png',
        },
        {
          id: 2,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'América',
          winAmount: '-167',
          winrate: '64.55%',
          investment: '100',
          qps: false,
          matchId: 62232,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/2.png',
        },
        {
          id: 16,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Tigres UANL',
          winAmount: '+100',
          winrate: '55%',
          investment: '100',
          qps: false,
          matchId: 62231,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/16.png',
        },
      ];
      break;
    case 2:
      rows = [
        {
          id: 3014,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Juárez',
          winAmount: '+155',
          winrate: '41.22%',
          investment: '100',
          qps: false,
          matchId: 62219,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/3014.png',
        },
        {
          id: 11,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Pumas',
          winAmount: '+140',
          winrate: '43.67%',
          investment: '100',
          qps: false,
          matchId: 62234,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/11.png',
        },
        {
          id: 14,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Santos Laguna',
          winAmount: '+140',
          winrate: '43.67%',
          investment: '100',
          qps: false,
          matchId: 62229,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/14.png',
        },
      ];
      break;
    case 3:
      rows = [
        {
          id: 205,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Necaxa',
          winAmount: '+220',
          winrate: '34.25%',
          investment: '100',
          qps: false,
          matchId: 62233,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/205.png',
        },
        {
          id: 173,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'León',
          winAmount: '+200',
          winrate: '37.33%',
          investment: '100',
          qps: false,
          matchId: 62230,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/173.png',
        },
        {
          id: 9,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Pachuca',
          winAmount: '+300',
          winrate: '27%',
          investment: '100',
          qps: false,
          matchId: 62226,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/9.png',
        },
      ];
      break;
    case 4:
      rows = [
        {
          id: 5,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Cruz Azul',
          winAmount: '-188',
          winrate: '66.28%',
          investment: '100',
          qps: false,
          matchId: 62221,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/5.png',
        },
        {
          id: 2,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'América',
          winAmount: '-167',
          winrate: '64.55%',
          investment: '100',
          qps: false,
          matchId: 62232,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/2.png',
        },
        {
          id: 11,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'Pumas',
          winAmount: '+140',
          winrate: '43.67%',
          investment: '100',
          qps: false,
          matchId: 62234,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/11.png',
        },
        {
          id: 173,
          homeTeam: {
            imgUrl: '',
            name: '',
          },
          awayTeam: {
            imgUrl: '',
            name: '',
          },
          teamName: 'León',
          winAmount: '+200',
          winrate: '37.33%',
          investment: '100',
          qps: false,
          matchId: 62230,
          imgUrl: 'https://az755631.vo.msecnd.net/teams-150/173.png',
        },
      ];
      break;
  }
  return rows;
};
