export default {
  namespace: true,
  state: {
    isLogin: false,
    username: '',
    email: '',
  },
  mutations: {},
  actions: {},
  getters: {
    isLogin: state => {
      return state.isLogin;
    },
  },
};
