import { getTeamsBySeasonV2, getPlayersBySeason } from '@/utils/api/teams';
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    positions: [
      { name: 'Goalkeeper', nameEs: 'Portero' },
      { name: 'Central Defender', nameEs: 'Defensa central' },
      { name: 'Left Back', nameEs: 'Lateral por izquierda' },
      { name: 'Right Back', nameEs: 'Lateral por derecha' },
      { name: 'Left Midfielder', nameEs: 'Volante por izquierda' },
      { name: 'Right Midfielder', nameEs: 'Volante por derecha' },
      { name: 'Defensive Midfielder', nameEs: 'Volante defensivo' },
      { name: 'Attacking Midfielder', nameEs: 'Volante ofensivo' },
      { name: 'Forward', nameEs: 'Delantero' },
      { name: 'Unknown', nameEs: 'Desconocida' },
    ],
    tournamentID: null,
    seasonID: null,
    teamID: null,
    playerID: null,
    teams: [],
    teamsMobile: [],
    players: [],
    playersByTeam: [],
    playersByTeamMobile: [],
    tournaments: [],
    player: null,
    retroSeason: null,
    retroTournament: {
      seasons: [],
    },
    lastSeason: null,
    playSeekerMaxIndex: -1,
  },
  getters: {
    isPlayerGoalkeeper: state => {
      if (state.player) {
        return state.player.position_name === 'Goalkeeper' || state.player.positionId === 1;
      }
      return false;
    },
    getPlayers: state => {
      return state.playersByTeam;
    },
    getPlayersMobile: state => {
      return state.playersByTeamMobile;
    },
    getTeams: state => {
      return state.teams;
    },
    getTeamsMobile: state => {
      return state.teamsMobile;
    },
    getTeamId: state => {
      return state.teamID;
    },
    getTournamentId: state => {
      return state.tournamentID;
    },
    getSeasonId: state => {
      return state.seasonID;
    },
    getSeasonIdOnly: state => {
      return state.seasonID ? state.seasonID.id : null;
    },
    getPlayerId: state => {
      return state.playerID;
    },
    playersByTeam: state => {
      return state.players.reduce((accumulator, player) => {
        const playerAux = {
          id: player.player_id,
          name: player.player_name,
          shortName: player.shortName,
          photo: player.player_photo,
          teamId: player.team_id,
          positionId: player.position_id,
        };
        const teamPlayers = accumulator.get(player.team_id);
        if (teamPlayers) {
          teamPlayers.push(playerAux);
        } else {
          accumulator.set(player.team_id, [playerAux]);
        }
        return accumulator;
      }, new Map());
    },
    seasonPlayers: state => {
      return state.players.reduce((accumulator, player) => {
        accumulator.set(player.player_id, {
          id: player.player_id,
          name: player.player_name,
          shortName: player.shortName,
          photo: player.player_photo,
          teamId: player.team_id,
          positionId: player.position_id,
        });
        return accumulator;
      }, new Map());
    },
    selectedTournament: state => {
      return state.tournamentID ? state.tournaments.find(tournament => tournament._id === state.tournamentID.id) : null;
    },
  },
  mutations: {
    serPlaySeekerMaxIndex(state, playSeekerMaxIndex) {
      state.playSeekerMaxIndex = playSeekerMaxIndex;
    },
    setLastSeason(state, lastSeason) {
      state.lastSeason = lastSeason;
    },
    setRetroTournament(state, retroTournament) {
      state.retroTournament = retroTournament;
    },
    setRetroSeason(state, retroSeason) {
      state.retroSeason = retroSeason;
    },
    setTournaments(state, tournaments) {
      tournaments.forEach(tournament => {
        tournament.seasons.forEach((season, index) => {
          season.index = index;
        });
      });
      state.tournaments = tournaments;
    },
    setPlayersByTeam(state, id) {
      state.playersByTeam = id;
    },
    resetPlayer(state) {
      state.playerID = null;
    },
    setPlayersByTeamMobile(state, id) {
      state.playersByTeamMobile = id;
    },
    setPlayers(state, players) {
      state.players = players;
    },
    setTournament(state, id) {
      state.tournamentID = id;
    },
    setSeason(state, id) {
      state.seasonID = id;
    },
    setTeam(state, id) {
      state.teamID = id;
    },
    setPlayer(state, player) {
      state.player = player;
      state.playerID = player ? player.player_id || player.id : null;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setTeamsMobile(state, teams) {
      state.teamsMobile = teams;
    },
  },
  actions: {
    async fetchTournaments({ commit }) {
      try {
        const { data } = await axios.get('https://i90k4l74ik.execute-api.us-east-2.amazonaws.com/prod/tournaments');
        commit('setTournaments', data);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getPlayersByTeam({ commit, state }) {
      let position = 0;
      let playersArray = {};
      let playersMobileArray = {};
      const playersByTeam = state.players.filter(player => player.team_id === state.teamID);
      if (playersByTeam.length > 0) {
        for (let index = 0; index < playersByTeam.length; index++) {
          let indexInit = index;
          playersArray[indexInit] = [];
          let key =
            index + 1 > 6
              ? index + 1 > 12
                ? index + 1 > 18
                  ? index + 1 > 24
                    ? index + 1 > 30
                      ? index + 1 > 36
                        ? index + 1 > 42
                          ? index + 1 > 42
                          : index + 1 - 36
                        : index + 1 - 30
                      : index + 1 - 24
                    : index + 1 - 18
                  : index + 1 - 12
                : index + 1 - 6
              : index + 1;
          playersByTeam[indexInit].position_name = state.positions[playersByTeam[indexInit].position_id - 1].name;
          playersArray[indexInit]['index'] = key;
          let dataNamePlayer = playersByTeam[indexInit].player_name.split(',');
          playersArray[indexInit]['data'] = playersByTeam[indexInit];
          playersArray[indexInit]['name'] = dataNamePlayer[0];
          playersArray[indexInit]['surname'] = dataNamePlayer[1];

          let ageDifMs = Date.now() - new Date(playersByTeam[indexInit].dob);
          let ageDate = new Date(ageDifMs); // miliseconds from epoch

          playersByTeam[indexInit].dob = Math.abs(ageDate.getUTCFullYear() - 1970);

          if (key === 1) {
            position++;
            playersMobileArray[position] = [];
            playersMobileArray[position].push(playersArray[indexInit]);
          } else {
            playersMobileArray[position].push(playersArray[indexInit]);
          }
        }
        commit('setPlayersByTeamMobile', playersMobileArray);
        commit('setPlayersByTeam', playersArray);
      }
    },

    async getPlayersBySeasons({ commit, getters }, payload) {
      try {
        const response = await getPlayersBySeason({
          seasonId: payload && payload.seasonId ? payload.seasonId : getters.selectedTournament.seasons[0].id,
        });
        response.data.players.forEach(player => {
          const [lastName, name] = player.player_name.split(',');
          const lastNameArr = lastName.split(' ');
          let lastNameShort = '';
          for (const word of lastNameArr) {
            lastNameShort += ` ${word}`;
            if (word.length > 3) {
              break;
            }
          }
          player.shortName = name && lastName ? `${name.trim().charAt(0)}.${lastNameShort}` : '';
        });
        commit('setPlayers', response.data.players);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getTeamsBySeasons({ commit, state }, payload = { seasonId: 0 }) {
      try {
        var position = 0;
        var teamsArray = {};
        var teamsMobileArray = {};
        const teams = await getTeamsBySeasonV2({
          seasonId: payload.seasonId || state.seasonID.id,
        });
        if (teams.data.teams) {
          for (let index = 0; index < teams.data.teams.length; index++) {
            var indexInit = index;
            teamsArray[indexInit] = [];
            var key =
              index + 1 > 6
                ? index + 1 > 12
                  ? index + 1 > 18
                    ? index + 1 > 24
                      ? index + 1 > 30
                        ? index + 1 > 36
                          ? index + 1 > 42
                            ? index + 1 > 42
                            : index + 1 - 36
                          : index + 1 - 30
                        : index + 1 - 24
                      : index + 1 - 18
                    : index + 1 - 12
                  : index + 1 - 6
                : index + 1;

            teamsArray[indexInit]['index'] = key;
            teamsArray[indexInit]['data'] = teams.data.teams[indexInit];
            if (key == 1) {
              position++;
              teamsMobileArray[position] = [];
              teamsMobileArray[position].push(teamsArray[indexInit]);
            } else {
              teamsMobileArray[position].push(teamsArray[indexInit]);
            }
          }
          commit('setTeams', teamsArray);
          commit('setTeamsMobile', teamsMobileArray);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /**
     * @author Alejandro Martinez
     * @param {Object} payload
     * @param {Array<number>} payload.seasons - seasons ids
     * @return {Promise<{seasonsAndGames: *, seasonsAndTeams: *}|*|undefined>}
     */
    async fetchSeasonsGamesAndTeams(_, payload) {
      try {
        let gamesUrl = 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?';
        payload.seasons.forEach((seasonId, index) => {
          const prefix = index === 0 ? '' : '&';
          gamesUrl += `${prefix}seasons=${seasonId}`;
        });
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
