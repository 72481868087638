import { fetchRetry } from '@/utils/api/fetchRetry';

export async function getPlays({ seasonId, localTeamId, visitingTeamId }) {
  try {
    const url = `https://bzznnzehtf.execute-api.us-west-2.amazonaws.com/prod/xg/seasons/${seasonId}/teams/${localTeamId}/${visitingTeamId}`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getPlaysPostMatch({ seasonId, localTeamId, visitingTeamId }) {
  try {
    const url = `https://bzznnzehtf.execute-api.us-west-2.amazonaws.com/prod/xg/game/${seasonId}/teams/${localTeamId}/${visitingTeamId}`;
    const response = await fetchRetry({ url: url });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
