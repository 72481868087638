import {
  getClashes,
  getFeaturedPlayers,
  getFacetoFace,
  fetchTeamStatsBySeason,
  getBetPerResult,
  fetchLastFiveGames,
  getBetParlayFree,
  getBetsByTypeApi,
  getTipsParlayFree,
} from '@/utils/api/bets';
// import Vue from 'vue';
import getTeamStatPlayIndexes from '@/data/team/teamStatsPlayIndexes';
import { getStatsByCategoryIdsAndPlayingAs } from '@/utils/gol-tipster';
import { cornersGoalsCategories } from '@/data/gol-tipster/categories';

const teamStatPlayIndexes = getTeamStatPlayIndexes();
const allGameSections = [1, 2, 3, 4, 5, 6];
const firstTimeSections = [1, 2, 3];
const secondTimeSections = [4, 5, 6];
const state = {
  teamsInfo: {},
  rightBetComponent: '',
  featuredPlayersPastSeason: false,
  selectedGame: {
    awayTeam: {},
    awayLastFiveGames: {},
    homeTeam: {},
    homeLastFiveGames: {},
  },
  lastFiveGamesAll: {},
  lastFiveGamesFiltered: {},
  betGames: {},
  bet: {
    Doble: [
      { name: '', odd: 0, prob: 0 },
      { name: '', odd: 0, prob: 0 },
    ],
    Moneyline: [
      { name: '', odd: 0, prob: 0 },
      { name: '', odd: 0, prob: 0 },
      { name: '', odd: 0, prob: 0 },
    ],
    MaxProb: [{ name: '', odd: 0, prob: 0, logo: '' }],
  },
  gamesClashesAll: [],
  gamesClashesFilter: [],
  gamesLast5GameAll: {
    home: {},
    away: {},
  },
  gamesLast5GameFilter: {
    home: {},
    away: {},
  },
  playsPlayerFeatured: [],
  playsPerTeamOrigins: [],
  infoPlayerFeatured: [],
  infoTeamsPlayerFeatured: [],
  playersFeaturedGoals: { home: [], away: [], movil: [] },
  playersFeaturedShots: { home: [], away: [], movil: [] },
  playersFeaturedCenters: { home: [], away: [], movil: [] },
  playersFeaturedPass: { home: [], away: [], movil: [] },
  originHomeF: [],
  originHomeC: [],
  originAwayF: [],
  originAwayC: [],
  filter: true,
  leyendIsPastSeason: false,
  leyendIsGamesPastSeason: false,
  filterGamePeriod: 0, // 0 is for Total, 1 is for 1T and 2 is for 2T
  filterPlayingAs: 1, // 1 is for home team as local and away team as visit, 2 is for all games
  teamStatPlayIndexes: teamStatPlayIndexes,
  parlayFreeGain: 0,
  parlayFreeInvestment: 0,
  parlayFreeSeason: 0,
  parlayFreeBreakdown: [],
  betsByGameType: {},
  tipsFreeGain: 0,
  tipsFreeInvestment: 0,
  tipsFreeSeason: 0,
  tipsFreeBreakdown: 0,
  refreshIntervalCounter: 0,
};

const getters = {
  getBetsByGameType(state) {
    return state.betsByGameType;
  },
  getFeaturedPlayersPastSeason(state) {
    return state.featuredPlayersPastSeason;
  },
  getBet(state) {
    return state.bet;
  },
  getBetGames(state) {
    return state.betGames;
  },
  getClashes(state) {
    return state.filterPlayingAs === 1 ? state.gamesClashesFilter : state.gamesClashesAll;
  },
  getTeamsInfo(state) {
    return state.teamsInfo;
  },
  getLastGame(state) {
    return state.filterPlayingAs === 1 ? state.gamesLast5GameFilter : state.gamesLast5GameAll;
  },
  lastFiveGames(state) {
    if (!state.selectedGame.homeLastFiveGames || !state.selectedGame.awayLastFiveGames) {
      return { home: {}, away: {} };
    }
    return state.filterPlayingAs === 1
      ? {
          homeTeam: state.selectedGame.homeLastFiveGames.filtered,
          awayTeam: state.selectedGame.awayLastFiveGames.filtered,
        }
      : {
          homeTeam: state.selectedGame.homeLastFiveGames.all,
          awayTeam: state.selectedGame.awayLastFiveGames.all,
        };
  },
  cornersOrGoalsByPlayingAs: state => {
    // * @param {string} teamType - values can be 'home' or 'away'
    // * @param {string} statsType - values can be 'corners' or 'goals'
    return (teamType, statsType) => {
      if (state.selectedGame && state.selectedGame.cornersAndGoalsStats) {
        const statType = statsType === 'corners' ? 'corners' : 'goals';
        return getStatsByCategoryIdsAndPlayingAs(
          cornersGoalsCategories[statType].general,
          teamType === 'home'
            ? state.selectedGame.cornersAndGoalsStats.homeTeam.stats
            : state.selectedGame.cornersAndGoalsStats.awayTeam.stats,
          state.filterPlayingAs,
          teamType === 'home',
          teamStatPlayIndexes,
        );
      }
      return [];
    };
  },
  cornersDataByPeriod(state) {
    const dataByPeriod = {
      homeTeam: {
        firstTime: 0,
        secondTime: 0,
      },
      awayTeam: {
        firstTime: 0,
        secondTime: 0,
      },
    };
    if (state.selectedGame && state.selectedGame.cornersAndGoalsStats) {
      const homePlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [1];
      const awayPlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [2];
      const homeTotalGames =
        homePlayingAs.length === 2
          ? state.selectedGame.cornersAndGoalsStats.homeTeam.games.played
          : state.selectedGame.cornersAndGoalsStats.homeTeam.games.local.total;
      const awayTotalGames =
        awayPlayingAs.length === 2
          ? state.selectedGame.cornersAndGoalsStats.awayTeam.games.played
          : state.selectedGame.cornersAndGoalsStats.awayTeam.games.visit.total;
      state.selectedGame.cornersAndGoalsStats.homeTeam.stats.forEach(stat => {
        if (
          cornersGoalsCategories.corners.general.includes(stat[teamStatPlayIndexes.categoryId]) &&
          homePlayingAs.includes(stat[teamStatPlayIndexes.playingAs])
        ) {
          if (stat[teamStatPlayIndexes.matchLapse] === 1) {
            dataByPeriod.homeTeam.firstTime += stat[teamStatPlayIndexes.total];
          } else if (stat[teamStatPlayIndexes.matchLapse] === 2) {
            dataByPeriod.homeTeam.secondTime += stat[teamStatPlayIndexes.total];
          }
        }
      });
      dataByPeriod.homeTeam.firstTime = dataByPeriod.homeTeam.firstTime / homeTotalGames;
      dataByPeriod.homeTeam.secondTime = dataByPeriod.homeTeam.secondTime / homeTotalGames;
      if (Number.isNaN(dataByPeriod.homeTeam.firstTime)) {
        dataByPeriod.homeTeam.firstTime = 0;
      }
      if (Number.isNaN(dataByPeriod.homeTeam.secondTime)) {
        dataByPeriod.homeTeam.secondTime = 0;
      }
      state.selectedGame.cornersAndGoalsStats.awayTeam.stats.forEach(stat => {
        if (
          cornersGoalsCategories.corners.general.includes(stat[teamStatPlayIndexes.categoryId]) &&
          awayPlayingAs.includes(stat[teamStatPlayIndexes.playingAs])
        ) {
          if (stat[teamStatPlayIndexes.matchLapse] === 1) {
            dataByPeriod.awayTeam.firstTime += stat[teamStatPlayIndexes.total];
          } else if (stat[teamStatPlayIndexes.matchLapse] === 2) {
            dataByPeriod.awayTeam.secondTime += stat[teamStatPlayIndexes.total];
          }
        }
      });
      dataByPeriod.awayTeam.firstTime = dataByPeriod.awayTeam.firstTime / awayTotalGames;
      dataByPeriod.awayTeam.secondTime = dataByPeriod.awayTeam.secondTime / awayTotalGames;
      if (Number.isNaN(dataByPeriod.awayTeam.firstTime)) {
        dataByPeriod.awayTeam.firstTime = 0;
      }
      if (Number.isNaN(dataByPeriod.awayTeam.secondTime)) {
        dataByPeriod.awayTeam.secondTime = 0;
      }
    }

    return dataByPeriod;
  },
  goalsDataByPeriod(state) {
    const dataByPeriod = {
      homeTeam: {
        firstTime: 0,
        secondTime: 0,
      },
      awayTeam: {
        firstTime: 0,
        secondTime: 0,
      },
    };
    if (state.selectedGame && state.selectedGame.cornersAndGoalsStats) {
      const homePlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [1];
      const awayPlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [2];
      const homeTotalGames =
        homePlayingAs.length === 2
          ? state.selectedGame.cornersAndGoalsStats.homeTeam.games.played
          : state.selectedGame.cornersAndGoalsStats.homeTeam.games.local.total;
      const awayTotalGames =
        awayPlayingAs.length === 2
          ? state.selectedGame.cornersAndGoalsStats.awayTeam.games.played
          : state.selectedGame.cornersAndGoalsStats.awayTeam.games.visit.total;
      state.selectedGame.cornersAndGoalsStats.homeTeam.stats.forEach(stat => {
        if (
          cornersGoalsCategories.goals.general.includes(stat[teamStatPlayIndexes.categoryId]) &&
          homePlayingAs.includes(stat[teamStatPlayIndexes.playingAs])
        ) {
          if (stat[teamStatPlayIndexes.matchLapse] === 1) {
            dataByPeriod.homeTeam.firstTime += stat[teamStatPlayIndexes.total];
          } else if (stat[teamStatPlayIndexes.matchLapse] === 2) {
            dataByPeriod.homeTeam.secondTime += stat[teamStatPlayIndexes.total];
          }
        }
      });
      dataByPeriod.homeTeam.firstTime = dataByPeriod.homeTeam.firstTime / homeTotalGames;
      dataByPeriod.homeTeam.secondTime = dataByPeriod.homeTeam.secondTime / homeTotalGames;
      if (Number.isNaN(dataByPeriod.homeTeam.firstTime)) {
        dataByPeriod.homeTeam.firstTime = 0;
      }
      if (Number.isNaN(dataByPeriod.homeTeam.secondTime)) {
        dataByPeriod.homeTeam.secondTime = 0;
      }
      state.selectedGame.cornersAndGoalsStats.awayTeam.stats.forEach(stat => {
        if (
          cornersGoalsCategories.goals.general.includes(stat[teamStatPlayIndexes.categoryId]) &&
          awayPlayingAs.includes(stat[teamStatPlayIndexes.playingAs])
        ) {
          if (stat[teamStatPlayIndexes.matchLapse] === 1) {
            dataByPeriod.awayTeam.firstTime += stat[teamStatPlayIndexes.total];
          } else if (stat[teamStatPlayIndexes.matchLapse] === 2) {
            dataByPeriod.awayTeam.secondTime += stat[teamStatPlayIndexes.total];
          }
        }
      });
      dataByPeriod.awayTeam.firstTime = dataByPeriod.awayTeam.firstTime / awayTotalGames;
      dataByPeriod.awayTeam.secondTime = dataByPeriod.awayTeam.secondTime / awayTotalGames;
      if (Number.isNaN(dataByPeriod.awayTeam.firstTime)) {
        dataByPeriod.awayTeam.firstTime = 0;
      }
      if (Number.isNaN(dataByPeriod.awayTeam.secondTime)) {
        dataByPeriod.awayTeam.secondTime = 0;
      }
    }

    return dataByPeriod;
  },
  getPlayersFeatureGoals(state) {
    return state.playersFeaturedGoals;
  },
  getPlayersFeaturedShots(state) {
    return state.playersFeaturedShots;
  },
  getPlayersFeaturedCenters(state) {
    return state.playersFeaturedCenters;
  },
  getPlayersFeaturedPass(state) {
    return state.playersFeaturedPass;
  },
  getOriginHomeF(state) {
    return state.originHomeF;
  },
  getOriginHomeC(state) {
    return state.originHomeC;
  },
  getOriginAwayF(state) {
    return state.originAwayF;
  },
  getOriginAwayC(state) {
    return state.originAwayC;
  },
  getLeyendIsPastSeason(state) {
    return state.leyendIsPastSeason;
  },
  getLeyendIsGamesPastSeason(state) {
    return state.leyendIsGamesPastSeason;
  },
  getParlayFreeGain(state) {
    return state.parlayFreeGain;
  },
  getParlayFreeInvestment(state) {
    return state.parlayFreeInvestment;
  },
  getParlayFreeSeason(state) {
    return state.parlayFreeSeason;
  },
  getParlayFreeBreakdown(state) {
    return state.parlayFreeBreakdown;
  },
  getRightBetComponent(state) {
    return state.rightBetComponent;
  },
  getTipsFreeGain(state) {
    return state.tipsFreeGain;
  },
  getTipsFreeInvestment(state) {
    return state.tipsFreeInvestment;
  },
  getTipsFreeSeason(state) {
    return state.tipsFreeSeason;
  },
  getTipsFreeBreakdown(state) {
    return state.tipsFreeBreakdown;
  },
};

const mutations = {
  setFeaturedPlayersPastSeason(state, value) {
    state.featuredPlayersPastSeason = value;
  },
  setRefreshIntervalCounter(state, refreshIntervalCounter) {
    state.refreshIntervalCounter = refreshIntervalCounter;
  },
  setBetsByGameType(state, value) {
    state.betsByGameType = value;
  },
  setTipsFreeGain(state, value) {
    state.tipsFreeGain = value;
  },
  setTipsFreeInvestment(state, value) {
    state.tipsFreeInvestment = value;
  },
  setTipsFreeSeason(state, value) {
    state.tipsFreeSeason = value;
  },
  setTipsFreeBreakdown(state, value) {
    state.tipsFreeBreakdown = value;
  },
  setParlayFreeGain(state, value) {
    state.parlayFreeGain = value;
  },
  setParlayFreeInvestment(state, value) {
    state.parlayFreeInvestment = value;
  },
  setParlayFreeSeason(state, value) {
    state.parlayFreeSeason = value;
  },
  setParlayFreeBreakdown(state, value) {
    state.parlayFreeBreakdown = value;
  },
  setRightBetComponent(state, component) {
    state.rightBetComponent = component;
  },
  setGamesClashesAll(state, games) {
    state.gamesClashesAll = games;
  },
  setGamesClashesFiltered(state, games) {
    state.gamesClashesFilter = games;
  },
  setTeamsInfo(state, info) {
    state.teamsInfo[info.type] = info.data;
  },
  setBetsGames(state, bets) {
    state.betGames = bets;
  },
  setGamesLast5GameAll(state, games) {
    state.gamesLast5GameAll = games;
  },
  setGamesLast5GameFilter(state, games) {
    state.gamesLast5GameFilter = games;
  },
  setFiltered(state, filter) {
    state.filter = filter;
  },
  setSelectedGame(state, selectedGame = { homeTeam: {}, awayTeam: {} }) {
    state.selectedGame = selectedGame;
    if (selectedGame.isCup) {
      state.filterPlayingAs = 2;
    }
  },
  /**
   * cornersAndGoals has the following structure:
   * cornersAndGoalsStats: {
    homeTeam: {
      stats: Array<number>,
      games: {},
    },
    awayTeam: {
      stats: Array<number>,
      games: {},
    },
  },
   * @param state
   * @param cornersAndGoalsStats
   */
  setGameCornersAndGoalsStats(state, cornersAndGoalsStats) {
    if (state.selectedGame) {
      state.selectedGame.cornersAndGoalsStats = cornersAndGoalsStats;
    }
  },
  setLeyendIsPastSeason(state, value) {
    state.leyendIsPastSeason = value;
  },
  setLeyendIsGamesPastSeason(state, value) {
    state.leyendIsGamesPastSeason = value;
  },
  setFilterPlayingAs(state, playingAs) {
    state.filterPlayingAs = playingAs;
  },
  setFilterGamePeriod(state, gamePeriod) {
    state.filterGamePeriod = gamePeriod;
  },
  setPlaysPerTeamOrigins(state, plays) {
    state.playsPerTeamOrigins[plays.id] = plays.plays;
  },
  setBet(state, data) {
    state.bet = data;
  },
  setPlaysPlayerFeatured(state, plays) {
    state.playsPlayerFeatured[plays.category] = plays.catPlays;
  },
  setInfoPlayerFeatured(state, info) {
    state.infoPlayerFeatured[info.category] = info.playersData;
  },
  setInfoTeamsPlayerFeatured(state, info) {
    state.infoTeamsPlayerFeatured[info.category] = info.teamData;
  },
  setPlayersFeaturedGoals(state, plays) {
    state.playersFeaturedGoals = plays;
  },
  setPlayersFeaturedShots(state, plays) {
    state.playersFeaturedShots = plays;
  },
  setPlayersFeaturedCenters(state, plays) {
    state.playersFeaturedCenters = plays;
  },
  setPlayersFeaturedPass(state, plays) {
    state.playersFeaturedPass = plays;
  },
  setOriginHomeF(state, origin) {
    state.originHomeF = origin;
  },
  setOriginHomeC(state, origin) {
    state.originHomeC = origin;
  },
  setOriginAwayF(state, origin) {
    state.originAwayF = origin;
  },
  setOriginAwayC(state, origin) {
    state.originAwayC = origin;
  },
};
const actions = {
  async getClashesPerGame({ commit }, game) {
    try {
      let gamesAll = [];
      let gamesFilter = [];
      const clashes = await getClashes({ game });
      if (clashes) {
        clashes.all_games.forEach(game => {
          let gameData = {
            homeTeam: game.home_team_id == clashes.home_team_id ? clashes.home_logo : clashes.away_logo,
            awayTeam: game.away_team_id == clashes.away_team_id ? clashes.away_logo : clashes.home_logo,
            score: game.score,
            season: game.season_name,
          };
          gamesAll.push(gameData);
        });
        clashes.games_filtered.forEach(game => {
          let gameData = {
            homeTeam: game.home_team_id == clashes.home_team_id ? clashes.home_logo : clashes.away_logo,
            awayTeam: game.away_team_id == clashes.away_team_id ? clashes.away_logo : clashes.home_logo,
            score: game.score,
            season: game.season_name,
          };
          gamesFilter.push(gameData);
        });
      }
      commit('setGamesClashesAll', gamesAll);
      commit('setGamesClashesFiltered', gamesFilter);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getLastGamePerGame({ commit, state }, game) {
    try {
      let h_team = {
        logo_home: '',
        name_home: '',
        points: '',
        position: '',
        games: [],
      };
      let a_team = {
        logo_away: '',
        name_away: '',
        points: '',
        position: '',
        games: [],
      };
      let h_team_f = {
        logo_home: '',
        name_home: '',
        points: '',
        position: '',
        games: [],
      };
      let a_team_f = {
        logo_away: '',
        name_away: '',
        points: '',
        position: '',
        games: [],
      };
      let lastgames = null;
      if (state.selectedGame.game_id === game && state.selectedGame.lastFiveGames) {
        lastgames = state.selectedGame.lastFiveGames.raw || state.selectedGame.lastFiveGames;
      } else {
        state.selectedGame.lastFiveGames = await fetchLastFiveGames({ gameId: game });
        lastgames = state.selectedGame.lastFiveGames.raw || state.selectedGame.lastFiveGames;
        lastgames.all_games.home_team.reverse();
        lastgames.all_games.away_team.reverse();
        lastgames.games_filtered.home_team.reverse();
        lastgames.games_filtered.away_team.reverse();
      }

      if (lastgames) {
        lastgames.all_games.home_team;
        lastgames.all_games.away_team;
        lastgames.games_filtered.home_team;
        lastgames.games_filtered.away_team;
        h_team.logo_home = lastgames.home_logo;
        h_team.name_home = lastgames.home_team_name;
        lastgames.all_games.home_team.forEach(game => {
          h_team.points = game.position;
          h_team.position = game.points;
          let infogame = { logo: game.rival_logo, match_result: game.match_result };
          h_team.games.push(infogame);
        });
        a_team.logo_away = lastgames.away_logo;
        a_team.name_away = lastgames.away_team_name;
        lastgames.all_games.away_team.forEach(game => {
          a_team.points = game.position;
          a_team.position = game.points;
          let infogame = { logo: game.rival_logo, match_result: game.match_result };
          a_team.games.push(infogame);
        });
        h_team_f.logo_home = lastgames.home_logo;
        h_team_f.name_home = lastgames.home_team_name;
        lastgames.games_filtered.home_team.forEach(game => {
          h_team_f.points = game.position;
          h_team_f.position = game.points;
          let infogame = { logo: game.rival_logo, match_result: game.match_result };
          h_team_f.games.push(infogame);
        });
        a_team_f.logo_away = lastgames.away_logo;
        a_team_f.name_away = lastgames.away_team_name;
        lastgames.games_filtered.away_team.forEach(game => {
          a_team_f.points = game.position;
          a_team_f.position = game.points;
          let infogame = { logo: game.rival_logo, match_result: game.match_result };
          a_team_f.games.push(infogame);
        });
      }
      commit('setGamesLast5GameAll', { home: h_team, away: a_team });
      commit('setGamesLast5GameFilter', { home: h_team_f, away: a_team_f });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async setPlayersTops({ state, commit }, category) {
    let statsFilterHome = [];
    let statsFilterAway = [];
    let statsFilter = [];
    if (state.playsPlayerFeatured[category]) {
      const homePlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [1];
      const awayPlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [2];
      const filterGameSections =
        state.filterGamePeriod === 0
          ? allGameSections
          : state.filterGamePeriod === 1
          ? firstTimeSections
          : secondTimeSections;
      state.playsPlayerFeatured[category].forEach(play => {
        const isHomeTeamPlay = state.selectedGame.homeTeam.team_id === play.team_id;
        const isAwayTeamPlay = state.selectedGame.awayTeam.team_id === play.team_id;
        const isHomePlayingAs = homePlayingAs.includes(play.playing_as);
        const isAwayPlayingAs = awayPlayingAs.includes(play.playing_as);
        const isGamePeriod = filterGameSections.includes(play.section);
        if ((isHomeTeamPlay || isAwayTeamPlay) && isGamePeriod) {
          if (isHomeTeamPlay && isHomePlayingAs) {
            statsFilterHome.push(play);
          }
          if (isAwayTeamPlay && isAwayPlayingAs) {
            statsFilterAway.push(play);
          }
        }
      });
    }

    statsFilter = statsFilterHome.concat(statsFilterAway);

    var topPlayer = {};
    var sortTopPlayer = [];
    statsFilter.forEach(play => {
      if (topPlayer[play.player_id]) {
        if (play.player_id != 0 && play.player_id != 62117) {
          topPlayer[play.player_id]['total'] = topPlayer[play.player_id]['total'] + play.total;
        }
      } else {
        if (play.player_id != 0 && play.player_id != 62117) {
          topPlayer[play.player_id] = {};
          topPlayer[play.player_id]['total'] = play.total;
          if (play.player_id) {
            var nameAbr = '';
            var names = state.infoPlayerFeatured[category][play.player_id].name.split(',');
            if (names[1]) {
              names[1] = names[1].trim();
              let namesProp = names[1].split(' ');
              if (namesProp[0]) {
                namesProp[0] = namesProp[0].trim();
                nameAbr = nameAbr + namesProp[0][0] + '. ';
              }
              if (namesProp[1]) {
                namesProp[1] = namesProp[1].trim();
                nameAbr = nameAbr + namesProp[1][0] + '. ';
              }
              if (namesProp[2]) {
                namesProp[2] = namesProp[2].trim();
                nameAbr = nameAbr + namesProp[2][0] + '. ';
              }
            }
            if (names[0]) {
              var lastAbr = '';
              names[0] = names[0].trim();
              let namesLProp = names[0].split(' ');
              for (let indexLN = 0; indexLN < namesLProp[0].length; indexLN++) {
                if (namesLProp[indexLN].length > 3) {
                  lastAbr = lastAbr + ' ' + namesLProp[indexLN];
                  break;
                } else {
                  lastAbr = lastAbr + ' ' + namesLProp[indexLN];
                }
              }
            }
            nameAbr = nameAbr + lastAbr;

            topPlayer[play.player_id]['name'] = nameAbr;
            topPlayer[play.player_id]['img'] =
              'https://az755631.vo.msecnd.net/players-profile/' + play.player_id + '.png';
            topPlayer[play.player_id]['id'] = state.infoPlayerFeatured[category][play.player_id].id;
            topPlayer[play.player_id]['team'] = play.team_id;
            topPlayer[play.player_id]['team_img'] = 'https://az755631.vo.msecnd.net/teams-80/' + play.team_id + '.png';
          } else {
            topPlayer[play.player_id]['id'] = '0';
            topPlayer[play.player_id]['name'] = '-';
            topPlayer[play.player_id]['img'] = 'SIN IMAGEN';
            topPlayer[play.player_id]['team'] = '0';
            topPlayer[play.player_id]['team_img'] = 'https://az755631.vo.msecnd.net/teams-80/0.png';
          }
        }
      }
    });
    for (const idPlayer in topPlayer) {
      sortTopPlayer.push(topPlayer[idPlayer]);
    }
    sortTopPlayer.sort((a, b) => (a.total < b.total ? 1 : -1));
    sortTopPlayer = sortTopPlayer.slice(0, 60);
    var arrayStatsFinal = {};
    var arrayStatsFinalHome = [];
    var arrayStatsFinalAway = [];
    var arrayStatsFinalMovil = [];
    if (sortTopPlayer.length > 0) {
      var maxHomeTeam = 0;
      var maxAwayTeam = 0;
      sortTopPlayer.forEach(element => {
        if (element.team == state.selectedGame.homeTeam.team_id) {
          if (element.total > maxHomeTeam) {
            maxHomeTeam = element.total;
          }
        }
        if (element.team == state.selectedGame.awayTeam.team_id) {
          if (element.total > maxAwayTeam) {
            maxAwayTeam = element.total;
          }
        }
      });

      sortTopPlayer.forEach(element => {
        if (element.team == state.selectedGame.homeTeam.team_id) {
          if (element.total == maxHomeTeam) {
            arrayStatsFinalHome.push(element);
          }
        }
        if (element.team == state.selectedGame.awayTeam.team_id) {
          if (element.total == maxAwayTeam) {
            arrayStatsFinalAway.push(element);
          }
        }
      });
      arrayStatsFinalMovil = arrayStatsFinalHome.concat(arrayStatsFinalAway);
      arrayStatsFinalMovil.sort((a, b) => (a.total < b.total ? 1 : -1));
    }
    arrayStatsFinal = { home: arrayStatsFinalHome, away: arrayStatsFinalAway, movil: arrayStatsFinalMovil };

    if (category == 1) {
      commit('setPlayersFeaturedGoals', arrayStatsFinal);
    }
    if (category == 6) {
      commit('setPlayersFeaturedShots', arrayStatsFinal);
    }
    if (category == 10) {
      commit('setPlayersFeaturedCenters', arrayStatsFinal);
    }
    if (category == 2) {
      commit('setPlayersFeaturedPass', arrayStatsFinal);
    }
  },

  async getFeaturedPlayersPerGame({ commit, dispatch }, params) {
    let catPlays = [];
    let teamData = {};
    let playersData = {};
    let data_headers = ['team_id', 'player_id', 'playing_as', 'match_result', 'section', 'total', 'origen', 'destino'];
    const players = await getFeaturedPlayers(params.season_id, params.category_id, params.away_team, params.home_team);

    if (players) {
      catPlays = players.data.map(play => {
        let formattedPlay = {};
        if (play[1] == 0 && play[2] == 1) {
          play[2] = 2;
        } else {
          if (play[1] == 0 && play[2] == 2) {
            play[2] = 1;
          }
        }
        if (play[1] == 0 && play[3] == 1) {
          play[3] = 2;
        } else {
          if (play[1] == 0 && play[3] == 2) {
            play[3] = 1;
          }
        }
        data_headers.forEach((header, index) => {
          formattedPlay[header] = play[index];
        });
        return formattedPlay;
      });
      players.teams.map(data => {
        teamData[data[0]] = {};
        teamData[data[0]]['id'] = data[0];
        teamData[data[0]]['name'] = data[1];
        teamData[data[0]]['img'] = data[3];
        teamData[data[0]]['abrv'] = data[2];
      });
      players.players.map(data => {
        playersData[data[0]] = {};
        playersData[data[0]]['id'] = data[0];
        playersData[data[0]]['name'] = data[1];
        playersData[data[0]]['img'] = data[2];
      });
    }
    await commit('setFeaturedPlayersPastSeason', players.is_past_season == 1 ? true : false);
    await commit('setInfoPlayerFeatured', { playersData: playersData, category: params.category_id });
    await commit('setInfoTeamsPlayerFeatured', { teamData: teamData, category: params.category_id });
    await commit('setPlaysPlayerFeatured', { catPlays: catPlays, category: params.category_id });
    await dispatch('setPlayersTops', params.category_id);
  },

  async getFaceToFacePerGame({ commit, dispatch }, params) {
    var statsPlaysFilterF = [];
    var statsPlaysFilterE = [];
    var statsPlays = [];
    var categoryF = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    var categoryE = [32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42];
    const stats = await getFacetoFace(params.season_id, params.team_id);
    statsPlays = stats.data.map(play => {
      let data_headers = ['category_id', 'team_id', 'match_result', 'playing_as', 'matchlapse', 'section', 'total'];
      let formattedPlay = {};
      if (play[1] == 0 && play[2] == 1) {
        play[2] = 2;
      } else {
        if (play[1] == 0 && play[2] == 2) {
          play[2] = 1;
        }
      }
      if (play[1] == 0 && play[3] == 1) {
        play[3] = 2;
      } else {
        if (play[1] == 0 && play[3] == 2) {
          play[3] = 1;
        }
      }
      data_headers.forEach((header, index) => {
        formattedPlay[header] = play[index];
      });
      return formattedPlay;
    });
    statsPlaysFilterF = statsPlays.filter(play => {
      return categoryF.includes(play.category_id);
    });
    statsPlaysFilterE = statsPlays.filter(play => {
      return categoryE.includes(play.category_id);
    });
    commit('setTeamsInfo', { type: params.type, id: stats.team.id, data: stats.team });
    commit('setPlaysPerTeamOrigins', { id: stats.team.id, plays: { F: statsPlaysFilterF, C: statsPlaysFilterE } });
    await dispatch('setOriginsPerTeam', { type: params.type, team: stats.team.id });
  },

  async setOriginsPerTeam({ state, commit }, data) {
    let statsFilterF = [];
    let statsFilterC = [];
    const homePlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [1];
    const awayPlayingAs = state.filterPlayingAs === 2 ? [1, 2] : [2];
    const filterPlayingAs = data.type === 'L' ? homePlayingAs : awayPlayingAs;
    statsFilterF = state.playsPerTeamOrigins[data.team].F.filter(play => {
      return filterPlayingAs.includes(play.playing_as);
    });
    statsFilterC = state.playsPerTeamOrigins[data.team].C.filter(play => {
      return filterPlayingAs.includes(play.playing_as);
    });
    let sortTopOriginF = [];
    let sortTopOriginC = [];
    let topOriginF = {};
    let topOriginC = {};
    statsFilterF.map(play => {
      if (topOriginF[play.category_id]) {
        topOriginF[play.category_id]['total'] = topOriginF[play.category_id]['total'] + play.total;
      } else {
        topOriginF[play.category_id] = {};
        topOriginF[play.category_id]['total'] = play.total;
        topOriginF[play.category_id]['category'] = play.category_id;
        topOriginF[play.category_id]['team'] = play.team_id;
      }
    });
    statsFilterC.map(play => {
      if (topOriginC[play.category_id]) {
        topOriginC[play.category_id]['total'] = topOriginC[play.category_id]['total'] + play.total;
      } else {
        topOriginC[play.category_id] = {};
        topOriginC[play.category_id]['total'] = play.total;
        topOriginC[play.category_id]['category'] = play.category_id;
        topOriginC[play.category_id]['team'] = play.team_id;
      }
    });
    for (const idCategory in topOriginF) {
      sortTopOriginF.push(topOriginF[idCategory]);
    }
    for (const idCategory in topOriginC) {
      sortTopOriginC.push(topOriginC[idCategory]);
    }
    sortTopOriginF.sort((a, b) => (a.total < b.total ? 1 : -1));
    sortTopOriginC.sort((a, b) => (a.total < b.total ? 1 : -1));
    var arrayOriginFinalC = [];
    var arrayOriginFinalF = [];
    if (sortTopOriginF.length > 0) {
      var maxTopOriginF = 0;
      sortTopOriginF.forEach(element => {
        if (element.total > maxTopOriginF) {
          maxTopOriginF = element.total;
        }
      });
      sortTopOriginF.forEach(element => {
        if (element.total == maxTopOriginF && arrayOriginFinalF.length < 3) {
          arrayOriginFinalF.push(element);
        }
      });
    }
    if (sortTopOriginC.length > 0) {
      var maxTopOriginC = 0;
      sortTopOriginC.forEach(element => {
        if (element.total > maxTopOriginC) {
          maxTopOriginC = element.total;
        }
      });
      sortTopOriginC.forEach(element => {
        if (element.total == maxTopOriginC && arrayOriginFinalC.length < 3) {
          arrayOriginFinalC.push(element);
        }
      });
    }
    if (data.type === 'L') {
      commit('setOriginHomeF', arrayOriginFinalF);
      commit('setOriginHomeC', arrayOriginFinalC);
    } else {
      commit('setOriginAwayF', arrayOriginFinalF);
      commit('setOriginAwayC', arrayOriginFinalC);
    }
  },
  async fetchTeamStatsBySeason(_, { seasonId, teamId, query }) {
    try {
      return fetchTeamStatsBySeason({ seasonId, teamId, query });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async fetchLastFiveGames({ state }, { gameId }) {
    try {
      let lastFiveGames = null;
      if (state.selectedGame.game_id === gameId && state.selectedGame.lastFiveGames) {
        lastFiveGames = state.selectedGame.lastFiveGames;
      } else {
        lastFiveGames = await fetchLastFiveGames({ gameId });
        if (lastFiveGames) {
          lastFiveGames.all_games.home_team.reverse();
          lastFiveGames.all_games.away_team.reverse();
          lastFiveGames.games_filtered.home_team.reverse();
          lastFiveGames.games_filtered.away_team.reverse();
        } else {
          lastFiveGames = {
            all_games: {
              home_team: [],
              away_team: [],
            },
            games_filtered: {
              home_team: [],
              away_team: [],
            },
          };
          lastFiveGames.all_games.home_team.reverse();
          lastFiveGames.all_games.away_team.reverse();
          lastFiveGames.games_filtered.home_team.reverse();
          lastFiveGames.games_filtered.away_team.reverse();
        }
      }
      const homeTeam = {
        all: [],
        filtered: [],
      };
      const awayTeam = {
        all: [],
        filtered: [],
      };
      let isPastGamesSeason = 0;
      if (lastFiveGames) {
        homeTeam.all = lastFiveGames.all_games.home_team;
        awayTeam.all = lastFiveGames.all_games.away_team;
        homeTeam.filtered = lastFiveGames.games_filtered.home_team;
        awayTeam.filtered = lastFiveGames.games_filtered.away_team;
        isPastGamesSeason = lastFiveGames.is_past_season;
      }
      return { homeTeam, awayTeam, isPastGamesSeason, raw: lastFiveGames };
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getBetPerResult({ commit }) {
    const bets = await getBetPerResult();
    var betsGamesArray = {};
    var betGames = {};
    for (const idTournament in bets.tournaments) {
      for (const idbet in bets.tournaments[idTournament].bets) {
        if (betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id]) {
          betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id].push(
            bets.tournaments[idTournament].bets[idbet],
          );
        } else {
          betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id] = [];
          betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id].push(
            bets.tournaments[idTournament].bets[idbet],
          );
        }
      }
    }
    for (const idGame in betsGamesArray) {
      var maxima = 0;
      for (const idbet in betsGamesArray[idGame]) {
        if (!betGames[idGame]) {
          betGames[idGame] = {};
          betGames[idGame]['Doble'] = [];
          betGames[idGame]['Moneyline'] = [];
          betGames[idGame]['MaxProb'] = [];
          betGames[idGame]['Doble'][0] = {};
          betGames[idGame]['Doble'][1] = {};
          betGames[idGame]['Moneyline'][0] = {};
          betGames[idGame]['Moneyline'][1] = {};
          betGames[idGame]['Moneyline'][2] = {};
        }
        if (betsGamesArray[idGame][idbet].bet_type_id == 2) {
          if (betsGamesArray[idGame][idbet].bet_subtype_id == 3) {
            if (betsGamesArray[idGame][idbet].bet_suggested.away_team_logo != '') {
              betGames[idGame]['Doble'][0]['result'] = 'Gana/Empata';
              betGames[idGame]['Doble'][0]['teamAcronym'] = betsGamesArray[idGame][idbet].away_team_acronym;
              betGames[idGame]['Doble'][0]['teamLogo'] = betsGamesArray[idGame][idbet].away_team_logo;
              betGames[idGame]['Doble'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['Doble'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['Doble'][0]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                maxima = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0] = {};
                betGames[idGame]['MaxProb'][0]['result'] = 'Gana/Empata';
                betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].away_team_logo;
                betGames[idGame]['MaxProb'][0]['type_team'] = 0;
              }
            }
            if (betsGamesArray[idGame][idbet].bet_suggested.home_team_logo != '') {
              betGames[idGame]['Doble'][1]['result'] = 'Gana/Empata ';
              betGames[idGame]['Doble'][1]['teamAcronym'] = betsGamesArray[idGame][idbet].home_team_acronym;
              betGames[idGame]['Doble'][1]['teamLogo'] = betsGamesArray[idGame][idbet].home_team_logo;
              betGames[idGame]['Doble'][1]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['Doble'][1]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['Doble'][1]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                maxima = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0] = {};
                betGames[idGame]['MaxProb'][0]['result'] = 'Gana/Empata';
                betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].home_team_logo;
                betGames[idGame]['MaxProb'][0]['type_team'] = 1;
              }
            }
          }
        }
        if (betsGamesArray[idGame][idbet].bet_type_id == 1) {
          if (betsGamesArray[idGame][idbet].bet_subtype_id == 1) {
            if (betsGamesArray[idGame][idbet].bet_suggested.away_team_logo != '') {
              betGames[idGame]['Moneyline'][0]['result'] = 'Gana';
              betGames[idGame]['Moneyline'][0]['teamAcronym'] = betsGamesArray[idGame][idbet].away_team_acronym;
              betGames[idGame]['Moneyline'][0]['teamLogo'] = betsGamesArray[idGame][idbet].away_team_logo;
              betGames[idGame]['Moneyline'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['Moneyline'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['Moneyline'][0]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                maxima = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0] = {};
                betGames[idGame]['MaxProb'][0]['result'] = 'Gana ';
                betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].away_team_logo;
                betGames[idGame]['MaxProb'][0]['type_team'] = 0;
              }
            }
            if (betsGamesArray[idGame][idbet].bet_suggested.home_team_logo != '') {
              betGames[idGame]['Moneyline'][1]['result'] = 'Gana';
              betGames[idGame]['Moneyline'][1]['teamAcronym'] = betsGamesArray[idGame][idbet].home_team_acronym;
              betGames[idGame]['Moneyline'][1]['teamLogo'] = betsGamesArray[idGame][idbet].home_team_logo;
              betGames[idGame]['Moneyline'][1]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['Moneyline'][1]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['Moneyline'][1]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                maxima = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0] = {};
                betGames[idGame]['MaxProb'][0]['result'] = 'Gana';
                betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].home_team_logo;
                betGames[idGame]['MaxProb'][0]['type_team'] = 1;
              }
            }
          }
          if (betsGamesArray[idGame][idbet].bet_subtype_id == 2) {
            betGames[idGame]['Moneyline'][2]['result'] = 'Empate';
            betGames[idGame]['Moneyline'][2]['odd'] = betsGamesArray[idGame][idbet].odd;
            betGames[idGame]['Moneyline'][2]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
            betGames[idGame]['Moneyline'][2]['bet_ref'] =
              'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
              betsGamesArray[idGame][idbet].bet_ref;
            if (maxima < betsGamesArray[idGame][idbet].golstats_probability) {
              maxima = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['MaxProb'][0] = {};
              betGames[idGame]['MaxProb'][0]['result'] = 'Empate';
              betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['MaxProb'][0]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              betGames[idGame]['MaxProb'][0]['logo'] = 'none';
              betGames[idGame]['MaxProb'][0]['type_team'] = 3;
            }
          }
        }
      }
    }
    commit('setBetsGames', betGames);
  },
  getBetGame({ state, commit }, game) {
    commit('setBet', state.betGames[game]);
  },
  async getTipsFree({ commit }) {
    var arrayTips = [];
    const freeInfo = await getTipsParlayFree();
    commit('setTipsFreeGain', freeInfo.breakdown.gain);
    commit('setTipsFreeInvestment', freeInfo.breakdown.investment);
    commit('setTipsFreeSeason', freeInfo.breakdown.season_name);
    var count = 0;
    freeInfo.breakdown.breakdown.forEach(game => {
      arrayTips[count] = [];
      var gainstring = game.gain.toFixed(2);
      var gainvalue = gainstring.split('.');
      arrayTips[count]['gain'] = gainvalue[0];
      arrayTips[count]['gaindeci'] = gainvalue[1];
      arrayTips[count]['match'] = game.matchday_abbreviation.replace('M', 'J');
      arrayTips[count]['matchday'] = game.matchday_name.replace('Matchday', 'Jornada');
      arrayTips[count]['investment'] = game.investment;
      arrayTips[count]['payout'] = game.payout;
      arrayTips[count]['parlay'] = [];
      arrayTips[count]['parlay'][0] = [];
      arrayTips[count]['parlay'][0]['logo'] =
        game.tip[0].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[0].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[0].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][0]['logo_away'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[0].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][0]['name_away'] = game.tip[0].away_team_acronym;
      arrayTips[count]['parlay'][0]['logo_home'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[0].bet_suggested.home_team_logo;
      arrayTips[count]['parlay'][0]['name_home'] = game.tip[0].home_team_acronym;
      arrayTips[count]['parlay'][0]['name'] =
        game.tip[0].bet_suggested.home_team_logo != '' ? game.tip[0].home_team_acronym : game.tip[0].away_team_acronym;
      arrayTips[count]['parlay'][0]['investment'] = game.tip[0].investment.toFixed(2);
      arrayTips[count]['parlay'][0]['roi'] = game.tip[0].roi.toFixed(2);
      arrayTips[count]['parlay'][0]['bet'] =
        game.tip[0].bet_suggested.home_team_logo != ''
          ? game.tip[0].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : game.tip[0].bet_suggested.suggestion == 'Gana'
            ? 'Gana'
            : 'Empata'
          : game.tip[0].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : game.tip[0].bet_suggested.suggestion == 'Gana'
          ? 'Gana'
          : 'Empata';
      arrayTips[count]['parlay'][1] = [];
      arrayTips[count]['parlay'][1]['logo'] =
        game.tip[1].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[1].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[1].bet_suggested.away_team_logo;

      arrayTips[count]['parlay'][1]['logo_away'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[1].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][1]['name_away'] = game.tip[1].away_team_acronym;
      arrayTips[count]['parlay'][1]['logo_home'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[1].bet_suggested.home_team_logo;
      arrayTips[count]['parlay'][1]['name_home'] = game.tip[1].home_team_acronym;

      arrayTips[count]['parlay'][1]['name'] =
        game.tip[1].bet_suggested.home_team_logo != '' ? game.tip[1].home_team_acronym : game.tip[1].away_team_acronym;
      arrayTips[count]['parlay'][1]['roi'] = game.tip[1].roi.toFixed(2);
      arrayTips[count]['parlay'][1]['investment'] = game.tip[1].investment.toFixed(2);
      arrayTips[count]['parlay'][1]['bet'] = arrayTips[count]['parlay'][0]['bet'] =
        game.tip[1].bet_suggested.home_team_logo != ''
          ? game.tip[1].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : game.tip[1].bet_suggested.suggestion == 'Gana'
            ? 'Gana'
            : 'Empata'
          : game.tip[1].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : game.tip[1].bet_suggested.suggestion == 'Gana'
          ? 'Gana'
          : 'Empata';
      arrayTips[count]['parlay'][2] = [];
      arrayTips[count]['parlay'][2]['logo'] =
        game.tip[2].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[2].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[2].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][2]['logo_away'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[2].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][2]['name_away'] = game.tip[2].away_team_acronym;
      arrayTips[count]['parlay'][2]['logo_home'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[2].bet_suggested.home_team_logo;
      arrayTips[count]['parlay'][2]['name_home'] = game.tip[2].home_team_acronym;

      arrayTips[count]['parlay'][2]['name'] =
        game.tip[2].bet_suggested.home_team_logo != '' ? game.tip[2].home_team_acronym : game.tip[2].away_team_acronym;
      arrayTips[count]['parlay'][2]['roi'] = game.tip[2].roi.toFixed(2);
      arrayTips[count]['parlay'][2]['investment'] = game.tip[2].investment.toFixed(2);
      arrayTips[count]['parlay'][2]['bet'] =
        game.tip[2].bet_suggested.home_team_logo != ''
          ? game.tip[2].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : game.tip[2].bet_suggested.suggestion == 'Gana'
            ? 'Gana'
            : 'Empata'
          : game.tip[2].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : game.tip[2].bet_suggested.suggestion == 'Gana'
          ? 'Gana'
          : 'Empata';
      arrayTips[count]['parlay'][3] = [];
      arrayTips[count]['parlay'][3]['logo'] =
        game.tip[3].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[3].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.tip[3].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][3]['logo_away'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[3].bet_suggested.away_team_logo;
      arrayTips[count]['parlay'][3]['name_away'] = game.tip[3].away_team_acronym;
      arrayTips[count]['parlay'][3]['logo_home'] =
        'https://az755631.vo.msecnd.net/teams-80/' + game.tip[3].bet_suggested.home_team_logo;
      arrayTips[count]['parlay'][3]['name_home'] = game.tip[3].home_team_acronym;
      arrayTips[count]['parlay'][3]['name'] =
        game.tip[3].bet_suggested.home_team_logo != '' ? game.tip[3].home_team_acronym : game.tip[3].away_team_acronym;
      arrayTips[count]['parlay'][3]['roi'] = game.tip[3].roi.toFixed(2);
      arrayTips[count]['parlay'][3]['investment'] = game.tip[3].investment.toFixed(2);
      arrayTips[count]['parlay'][3]['bet'] =
        game.tip[3].bet_suggested.home_team_logo != ''
          ? game.tip[3].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : game.tip[3].bet_suggested.suggestion == 'Gana'
            ? 'Gana'
            : 'Empata'
          : game.tip[3].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : game.tip[3].bet_suggested.suggestion == 'Gana'
          ? 'Gana'
          : 'Empata';
      count++;
    });

    commit('setTipsFreeBreakdown', arrayTips);
  },
  async getParlayFree({ commit }) {
    var arrayParlay = [];
    const freeInfo = await getBetParlayFree();
    commit('setParlayFreeGain', freeInfo.gain);
    commit('setParlayFreeInvestment', freeInfo.investment);
    commit('setParlayFreeSeason', freeInfo.season_name);
    var count = 0;
    freeInfo.breakdown.forEach(game => {
      arrayParlay[count] = [];
      var gainstring = game.gain.toFixed(2);
      var gainvalue = gainstring.split('.');
      arrayParlay[count]['gain'] = gainvalue[0];
      arrayParlay[count]['payout'] = game.payout.toFixed(2);
      arrayParlay[count]['gaindeci'] = gainvalue[1];
      arrayParlay[count]['match'] = game.matchday_abbreviation.replace('M', 'J');
      arrayParlay[count]['matchday'] = game.matchday_name.replace('Matchday', 'Jornada');
      arrayParlay[count]['parlay'] = [];
      arrayParlay[count]['parlay'][0] = [];
      arrayParlay[count]['parlay'][0]['logo'] =
        game.parlay[0].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.parlay[0].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.parlay[0].bet_suggested.away_team_logo;
      arrayParlay[count]['parlay'][0]['name'] =
        game.parlay[0].bet_suggested.home_team_logo != ''
          ? game.parlay[0].home_team_acronym
          : game.parlay[0].away_team_acronym;
      arrayParlay[count]['parlay'][0]['investment'] = game.investment;
      arrayParlay[count]['parlay'][0]['bet'] =
        game.parlay[0].bet_suggested.home_team_logo != ''
          ? game.parlay[0].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : 'Empata'
          : game.parlay[0].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : 'Empata';
      arrayParlay[count]['parlay'][1] = [];
      arrayParlay[count]['parlay'][1]['logo'] =
        game.parlay[1].bet_suggested.home_team_logo != ''
          ? 'https://az755631.vo.msecnd.net/teams-80/' + game.parlay[1].bet_suggested.home_team_logo
          : 'https://az755631.vo.msecnd.net/teams-80/' + game.parlay[1].bet_suggested.away_team_logo;
      arrayParlay[count]['parlay'][1]['name'] =
        game.parlay[1].bet_suggested.home_team_logo != ''
          ? game.parlay[1].home_team_acronym
          : game.parlay[1].away_team_acronym;
      arrayParlay[count]['parlay'][1]['investment'] = game.investment;
      arrayParlay[count]['parlay'][1]['bet'] =
        game.parlay[1].bet_suggested.home_team_logo != ''
          ? game.parlay[1].bet_suggested.suggestion == 'Gana / Empata'
            ? 'Gana / Empata'
            : 'Empata'
          : game.parlay[1].bet_suggested.suggestion == 'Gana / Empata'
          ? 'Gana / Empata'
          : 'Empata';
      count++;
    });

    commit('setParlayFreeBreakdown', arrayParlay);
  },
  async getBetsByType({ commit }, data) {
    var arrayBets = {};
    const bets = await getBetsByTypeApi(data.game, data.type);
    var count = 0;
    bets.markets.forEach(market => {
      arrayBets[count] = {};
      arrayBets[count]['name'] = market.bet_type_name;
      var countBet = 0;
      arrayBets[count]['bets'] = {};
      market.bets.forEach(bet => {
        arrayBets[count]['bets'][countBet] = {};
        arrayBets[count]['bets'][countBet]['odd'] = bet.odd > 0 ? '+' + bet.odd : bet.odd;
        arrayBets[count]['bets'][countBet]['suggestion'] = bet.bet_suggested.suggestion;
        arrayBets[count]['bets'][countBet]['bet_ref'] =
          typeof bet.bet_ref != 'undefined' && bet.bet_ref != ''
            ? 'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
              bet.bet_ref
            : '';
        countBet = countBet + 1;
      });
      count = count + 1;
    });
    commit('setBetsByGameType', arrayBets);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
