import {
  validateEmail,
  registerUser,
  registerPayment,
  validateRegisteredUser,
  checkoutTokens,
} from '@/utils/api/register';
import planStyles from '@/data/profile/planStyles';
// import router from '@/router';

const state = {
  txtErrorState: 0,
  msgError: false,
  msgTxtError: '',
  registering: false,
  error: '',
  tokenCheckoutId: 0,
  tokenCard: null,
  typePayment: 1, //conecta 2, stripe 1
  ui: null,
  idempotency_key_old: null,
  token_card: null,
  idempotency_key: null,
  plan: null,
  name: null,
  pvID: null,
  styles: planStyles,
  planVisibilityRegister: [0, 1],
  codeErrors: [1, 2, 3, 4],
};

const getters = {
  getCheckoutID: state => {
    return state.tokenCheckoutId;
  },
  getTypePayment: state => {
    return state.typePayment;
  },
  getTokenCard: state => {
    return state.tokenCard;
  },
  getRegisterPlans: (state, getters, rootState, rootGetters) => {
    return rootGetters['profileGeneral/getAllPlans']
      .filter(plan => state.planVisibilityRegister.includes(plan.visibility))
      .map(plan => {
        return {
          id: plan.id,
          title: plan.subtitle,
          type: plan.title,
          description: plan.description,
          additionalInfo: plan.additional_comments,
          price: plan.price,
          currency: plan.currency,
          style: state.styles[plan.style],
          btnName: plan.btnName,
        };
      });
  },
  getMsgError: state => {
    return state.msgError;
  },
  getMsgTxtError: state => {
    return state.msgTxtError;
  },
  getTxtErrorState: state => {
    return state.txtErrorState;
  },
  getIdempotencyKeyOld: state => {
    return state.idempotency_key_old;
  },
};

const mutations = {
  SET_CHECKOUT_ID(state, token) {
    state.tokenCheckoutId = token;
  },
  SET_TYPE_PAYMENT_ID(state, token) {
    state.typePayment = token;
  },
  SET_TOKEN_CARD(state, token) {
    state.tokenCard = token;
  },
  setRegistering(state, registering) {
    state.registering = registering;
  },
  setError(state, error) {
    state.error = error;
  },
  setMsgError(state, error) {
    state.msgError = error;
  },
  setMsgTxtError(state, error) {
    state.msgTxtError = error;
  },
  setTxtErrorState(state, error) {
    state.txtErrorState = error;
  },
  setUi(state, ui) {
    state.ui = ui;
  },
  setTokenCard(state, token_card) {
    state.token_card = token_card;
  },
  setIdempotencyKeyOld(state, idempotency_key) {
    state.idempotency_key_old = idempotency_key;
  },
  setPlan(state, plan) {
    state.plan = plan;
  },
  set3pvid(state, plan) {
    state.pvID = plan;
  },
  setName(state, name) {
    state.name = name;
  },
  setIdempotencyKey(state, value) {
    state.idempotency_key = value;
  },
};

const actions = {
  async fetchCheckoutID({ commit }) {
    try {
      const checkoutID = await checkoutTokens();
      commit('SET_TOKEN_CARD', checkoutID.data.id);
      commit('SET_CHECKOUT_ID', checkoutID.data.checkout.id);
    } catch (e) {
      throw new Error(e);
    }
  },
  async verifyEmail({ state, commit, dispatch }, email) {
    commit('setRegistering', true);
    await commit('setIsSectionLoading', true, { root: true });
    let body = { user: email };
    try {
      const response = await validateEmail(body);
      if (response.data === null) {
        return true;
      } else if (state.codeErrors.includes(response.data.code)) {
        throw response.data;
      }
    } catch (e) {
      let message = '';
      if (state.codeErrors.includes(e.code)) {
        message = e.message;
        commit('setError', e.message);
      } else if (e.response.data === null) {
        return Promise.reject(e);
      } else if (e.response.status === 409) {
        message = e.message;
        commit('setError', e.response.data.message);
      }
      const ALERT = {
        type: 'error',
        message: message || '',
      };
      dispatch('alertGeneral/setAlert', ALERT, { root: true });
      return Promise.reject(e);
    } finally {
      commit('setRegistering', false);
      await commit('setIsSectionLoading', false, { root: true });
    }
  },
  async typePaymentNext({ commit }) {
    await commit('auth/SET_AUTH_COMPONENT', 'CardForm', { root: true });
  },
  async registerUser({ commit, dispatch }, body) {
    commit('setRegistering', true);
    await commit('setIsSectionLoading', false, { root: true });
    try {
      const response = await registerUser(body);
      const ui = await response.data.ui;
      commit('setUi', ui);
      commit('setName', body.account_name);
      if (response.status === 201) {
        // await commit('auth/SET_AUTH_COMPONENT', 'CardForm', { root: true });
        await commit('auth/SET_AUTH_COMPONENT', 'SelectBank', { root: true });
      }
    } catch (e) {
      const ALERT = {
        type: 'error',
        message: e.response.data.message || '',
      };
      dispatch('alertGeneral/setAlert', ALERT, { root: true });
      return Promise.reject(e);
    } finally {
      commit('setRegistering', false);
      await commit('setIsSectionLoading', false, { root: true });
    }
  },
  async validateRegisteredUser({ commit, dispatch }, body) {
    commit('setRegistering', true);
    await commit('setIsSectionLoading', false, { root: true });
    try {
      const response = await validateRegisteredUser(body);
      const ui = await response.data.ui;
      commit('setUi', ui);
      if (response.status === 200) {
        // await commit('auth/SET_AUTH_COMPONENT', 'CardForm', { root: true });
        await commit('auth/SET_AUTH_COMPONENT', 'SelectBank', { root: true });
      }
    } catch (error) {
      const ALERT = {
        type: 'error',
        message: error.response.data.message || '',
      };
      dispatch('alertGeneral/setAlert', ALERT, { root: true });
      return Promise.reject(error);
    } finally {
      commit('setRegistering', false);
      await commit('setIsSectionLoading', false, { root: true });
    }
  },
  async registerPayment({ state, dispatch, commit }) {
    commit('setMsgError', false);
    let body = {};
    var configJson = {};
    if (localStorage.getItem('colorsConfig') !== null) {
      configJson = JSON.parse(localStorage.getItem('colorsConfig'));
    }
    if (state.typePayment == 1) {
      if (configJson.partner_id) {
        body = {
          ui: state.ui,
          token_card: state.token_card,
          plan: state.plan,
          partner_id: configJson.partner_id,
          type: state.typePayment,
          idempotency_key: state.idempotency_key,
        };
      } else {
        body = {
          ui: state.ui,
          token_card: state.token_card,
          plan: state.plan,
          type: state.typePayment,
          idempotency_key: state.idempotency_key,
        };
      }
      commit('setIdempotencyKeyOld', state.idempotency_key);
    } else {
      if (configJson.partner_id) {
        body = {
          ui: state.ui,
          token_card: state.tokenCard,
          plan: state.plan,
          partner_id: configJson.partner_id,
          type: state.typePayment,
        };
      } else {
        body = {
          ui: state.ui,
          token_card: state.tokenCard,
          plan: state.plan,
          type: state.typePayment,
        };
      }
    }

    try {
      const response = await registerPayment(body);
      commit('setMsgError', false);
      const data = {
        token: response.data.token,
        name: state.name,
      };
      dispatch('loginGeneral/loginPostRegister', data, { root: true });
      const ALERT = {
        type: 'success',
        message: '&#161;Felicidades! Tu cuenta ha sido creada con &eacute;xito.',
      };
      dispatch('alertGeneral/setAlert', ALERT, { root: true });
    } catch (e) {
      if (e.response.status == 402 || e.response.status == 409) {
        if (e.response.data.code) {
          commit('setTxtErrorState', e.response.data.code);
        } else {
          commit('setTxtErrorState', 4);
        }

        commit('setMsgError', true);
        if (state.txtErrorState) {
          commit('setMsgTxtError', e.response.data.message);
        } else {
          commit('setMsgTxtError', '');
        }

        return Promise.reject(e);
      } else {
        const ALERT = {
          type: 'error',
          message: e.response.data.message || '',
        };
        dispatch('alertGeneral/setAlert', ALERT, { root: true });
        return Promise.reject(e);
      }
    } finally {
      await commit('setIsSectionLoading', false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
