/**
 *
 * @param {Array<{momio: number, bet: number}>} games
 */
export function getTipsPercentage(games) {
  let tipsPercentage = 0;
  if (games && games.length > 0) {
    let totalBets = 0;
    games.forEach(game => {
      game.probability =
        game.momio > 0 ? 100 / (game.momio + 100) : Math.abs(game.momio) / (Math.abs(game.momio) + 100);
      game.payout =
        game.momio > 0 ? (game.bet * game.momio) / 100 + game.bet : (game.bet * 100) / Math.abs(game.momio) + game.bet;
      totalBets += game.bet;
    });
    const maxPossibleResults = Math.pow(2, games.length);
    if (maxPossibleResults) {
      for (let i = 1; i <= maxPossibleResults; i++) {
        const reversedBinaryNumber = getBinaryFromDecimal(i)
          .split('')
          .reverse()
          .join('')
          .substring(0, games.length);
        let resultPayout = 0;
        let possibleProbabilities = [];
        games.forEach((game, index) => {
          if (reversedBinaryNumber.charAt(index) === '1') {
            resultPayout += game.payout;
            possibleProbabilities.push(game.probability);
          } else {
            possibleProbabilities.push(1 - game.probability);
          }
        });
        resultPayout -= totalBets;
        if (resultPayout > totalBets * 0.005 * -1) {
          tipsPercentage += possibleProbabilities.reduce((accumulator, probability) => accumulator * probability, 1);
        }
      }
    }
  }
  return tipsPercentage * 100;
}

/**
 * Convert a decimal number to binary string
 * @param {number} x - decimal number to convert to binary
 * @return {string}
 */
function getBinaryFromDecimal(x) {
  let aux = x;
  let bin = 0;
  let rem;
  let i = 1;
  while (aux !== 0) {
    rem = aux % 2;
    aux = parseInt(aux / 2);
    bin = bin + rem * i;
    i = i * 10;
  }
  return bin.toString();
}
