import App from './App.vue';
import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import { getSocket } from '@/utils/socketConnection';
import VueMq from 'vue-mq';
import router from '@/router';
import store from './store';
import VueTagManager from 'vue-tag-manager';
import '@/utils/api/interceptors';
// buefy styles
import '@/styles/main.scss';

Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: 1024,
    widescreen: 1216,
    fullhd: 1408,
  },
});
Vue.use(VueSocketIOExt, getSocket(), { store });
Vue.config.productionTip = false;

Vue.use(VueTagManager, {
  gtmId: 'GTM-NJNL2BT',
});

new Vue({
  router,
  store,
  beforeCreate() {
    const analyticsStorage = sessionStorage.getItem('analytics');
    const urlParams = new URLSearchParams(window.location.search);
    const analytics = urlParams.get('analytics');
    const hostname = window.location.hostname;
    const href = window.location.href;
    if (
      hostname === 'localhost' ||
      ((href.includes('/widget') || !href.includes('/soyreferee')) && hostname.includes('dev')) ||
      ((href.includes('/widget') || !href.includes('/soyreferee')) && hostname.includes('qa')) ||
      analytics === 'disable' ||
      analyticsStorage == 'disable'
    ) {
      sessionStorage.setItem('analytics', 'disable');
      window['ga-disable-UA-184891682-1'] = true;
      window['ga-disable-G-2RLW3EWWQD'] = true;
    }
  },
  created() {
    import('@/utils/filters');
    import('@/vee-validate');
  },
  render: h => h(App),
}).$mount('#app');
