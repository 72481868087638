import { fetchRetry } from '@/utils/api/fetchRetry';

export async function getTournaments() {
  try {
    // GS20
    // https://kefloixzy1.execute-api.us-west-2.amazonaws.com/prod/tournaments
    // B2C
    // https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments
    const { data } = await fetchRetry({
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchTournaments() {
  try {
    const response = await fetchRetry({
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      // url: 'https://25d2d6f5ad.execute-api.us-west-2.amazonaws.com/qa/tournaments',
    });
    return response && response.data ? response.data : [];
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchRightBetTournaments() {
  try {
    // https://wiy8vumyh2.execute-api.us-east-2.amazonaws.com/qa/tournaments-right-bet
    const response = await fetchRetry({
      url: 'https://cd2rabrly8.execute-api.us-east-2.amazonaws.com/prod/tournaments-right-bet',
    });
    return response && response.data ? response.data : [];
  } catch (e) {
    throw new Error(e);
  }
}
