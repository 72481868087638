import { fetchRetry } from '@/utils/api/fetchRetry';
/**
 * @author Alejandro
 * @description Get the team seasons by its id
 * @param {Object} params
 * @param {string} params.teamId
 * @return {Promise}
 */
export async function getSeasonsByTeam({ teamId }) {
  try {
    const url = `https://z4xv9k9igf.execute-api.us-west-2.amazonaws.com/prod/seasonsByTeam/${teamId}`;
    const response = await fetchRetry({ url: url });
    return response.data.seasons;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @author Alejandro
 * @description Get all teams by a seasonId
 * @param {Object} params
 * @param {string|number} params.seasonId
 * @return {Promise<AxiosResponse<T>>}
 */
export async function getTeamsBySeason(params) {
  try {
    const url = `https://d2oco6mqmc.execute-api.us-west-2.amazonaws.com/prod/teamsBySeason/${params.seasonId}`;
    return await fetchRetry({ url: url });
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @author Ricardo
 * @description Get all players by a seasonId
 * @param {Object} params
 * @param {string|number} params.seasonId
 * @return {Promise<AxiosResponse<T>>}
 */
export async function getPlayersBySeason(params) {
  try {
    const url = ` https://ts7thxzaik.execute-api.us-west-2.amazonaws.com/prod/players/seasons/${params.seasonId}`;
    return await fetchRetry({ url: url });
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @author Alejandro
 * @description Get all teams by a seasonId version 2
 * @param {Object} params
 * @param {string|number} params.seasonId
 * @return {Promise<AxiosResponse<T>>}
 */
export async function getTeamsBySeasonV2(params) {
  try {
    const url = `https://yjeig444bb.execute-api.us-west-2.amazonaws.com/prod/teamsBySeason/${params.seasonId}`;
    return await fetchRetry({ url: url });
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @param {Object} params
 * @param {(number|string)} seasonId
 * @param {(number|string)} teamId
 */
export async function getTeamStatisticsBySeason({ seasonId, teamId }) {
  try {
    return await fetchRetry({
      url: `https://a4dcb6avog.execute-api.us-west-2.amazonaws.com/prod/statisticsTeamBySeason/${seasonId}/${teamId}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @param {Object} params
 * @param {string|number} params.gameId
 * @return {Promise<AxiosResponse<T>>}
 */
export async function getTeamStatisticsByGame(params) {
  try {
    return await fetchRetry({
      url: `https://nckawv8v3b.execute-api.us-west-2.amazonaws.com/prod/statisticsByGame/${params.gameId}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @param {Object} params
 * @param {string|number} params.seasonId
 * @return {Promise<AxiosResponse<T>>}
 */
export async function getStatsTop(params) {
  try {
    return await fetchRetry({
      url: `https://8grl16o77j.execute-api.us-west-2.amazonaws.com/prod/topsBySeasonAndCategory/${params.seasonId}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
}
