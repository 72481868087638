import axios from 'axios';

/**
 * @author José R. Gutierrez
 */
export function getPaymentMethods() {
  return axios.get(process.env.VUE_APP_PAYMENT_METHOD_API_URL);
}

/**
 * @author José R. Gutierrez
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createPaymentMethod(body) {
  return axios.post(process.env.VUE_APP_PAYMENT_METHOD_API_URL, body);
}

/**
 * @author José R. Gutierrez
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updatePaymentMethod(id) {
  return axios.put(`${process.env.VUE_APP_PAYMENT_METHOD_API_URL}/${id}`);
}

/**
 * @author José R. Gutierrez
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deletePaymentMethod(id) {
  return axios.delete(`${process.env.VUE_APP_PAYMENT_METHOD_API_URL}/${id}`);
}
