import NGSubcategoryData from '@/utils/classes/NGSubcategoryData';

export default {
  gameResult: {
    parentInfo: {
      // See inside src/data/next/options.js, id property inside constant analysisTypeOptions
      // and
      // block property inside constant filterOptions to see where these numbers come from
      parentAnalysis: [1],
      analysisTypeBlocks: [1],
    },
    subcategories: {
      goals: new NGSubcategoryData(
        [12, 55, '35_r', '54_r'],
        [40],
        { accomplished: 1, received: 2 },
        'Goles',
        'Goles recibidos',
        'Goles realizados / recibidos',
        'Goles recibidos / realizados',
        1,
      ),
      shotsOnGoal: new NGSubcategoryData(
        [510],
        [510],
        { accomplished: 3, received: 4 },
        'Tiros a gol',
        'Tiros a gol recibidos',
        'Tiros a gol realizados / recibidos',
        'Tiros a gol recibidos / realizados',
        2,
      ),
      leftCenteringPass: new NGSubcategoryData(
        [13],
        [25],
        { accomplished: 5, received: 9 },
        'Centros por izquierda',
        'Centros por derecha recibidos',
        'Centros por izquierda realizados / derecha recibidos',
        'Centros por derecha recibidos / izquierda realizados',
        3,
      ),
      rightCenteringPass: new NGSubcategoryData(
        [25],
        [13],
        { accomplished: 6, received: 10 },
        'Centros por derecha',
        'Centros por izquierda recibidos',
        'Centros por derecha realizados / izquierda recibidos',
        'Centros por izquierda recibidos / derecha realizados',
        4,
      ),
      successfulOneOnOne: new NGSubcategoryData(
        [4, 611],
        [5, 612],
        { accomplished: 7, received: 11 },
        '1vs1 exitosos ofensivos',
        '1v1 exitosos defensivos',
        '1vs1 exitosos ofensivos / defensivos',
        '1vs1 exitosos defensivos / ofensivos',
        5,
      ),
      recoveredBallsRivalField: new NGSubcategoryData(
        [11],
        [23],
        { accomplished: 8, received: 12 },
        'Balones ganados en area rival',
        'Balones ganados en area propia',
        'Balones ganados en area rival / propia',
        'Balones ganados en area propia / rival',
        6,
      ),
    },
  },
  possession: {
    parentInfo: {
      // See inside src/data/next/options.js, id property inside constant analysisTypeOptions
      // and
      // block property inside constant filterOptions to see where these numbers come from
      parentAnalysis: [1],
      analysisTypeBlocks: [2],
    },
    subcategories: {
      totalPasses: new NGSubcategoryData([2, 20], null, { accomplished: 13, received: 0 }, 'Pases acertados totales'),
      ownFieldPasses: new NGSubcategoryData(
        [2],
        null,
        { accomplished: 14, received: 0 },
        'Pases acertados cancha propia',
      ),
      rivalPasses: new NGSubcategoryData([20], null, { accomplished: 15, received: 0 }, 'Pases acertados cancha rival'),
      firstQuarterPasses: new NGSubcategoryData([213], null, { accomplished: 16, received: 0 }, 'Pases acertados 1/4'),
      secondQuarterPasses: new NGSubcategoryData([214], null, { accomplished: 17, received: 0 }, 'Pases acertados 2/4'),
      thirdQuarterPasses: new NGSubcategoryData([215], null, { accomplished: 69, received: 0 }, 'Pases acertados 3/4'),
      fourthQuarterPasses: new NGSubcategoryData([216], null, { accomplished: 18, received: 0 }, 'Pases acertados 4/4'),
      recoveredBallsRivalField: new NGSubcategoryData(
        [487, 488, 491, 492],
        null,
        { accomplished: 19, received: 0 },
        'Balones recuperados cancha rival',
      ),
      recoveredBallsOwnField: new NGSubcategoryData(
        [485, 486, 489, 490],
        null,
        { accomplished: 20, received: 0 },
        'Balones recuperados cancha propia',
      ),
    },
  },
  goalOrigin: {
    parentInfo: {
      // See inside src/data/next/options.js, id property inside constant analysisTypeOptions
      // and
      // block property inside constant filterOptions to see where these numbers come from
      parentAnalysis: [1, 2],
      analysisTypeBlocks: [2, 3],
    },
    subcategories: {
      centerByLeft: new NGSubcategoryData(
        [62],
        [63],
        { accomplished: 21, received: 32 },
        'O. centro por izquierda',
        'O. centro por derecha recibido',
        'O. centro por izquierda realizado / derecha recibido',
        'O. centro por derecha recibido / izquierda realizado',
        7,
      ),
      centerByRight: new NGSubcategoryData(
        [63],
        [62],
        { accomplished: 22, received: 33 },
        'O. centro por derecha',
        'O. centro por izquierda recibido',
        'O. centro por derecha realizado / izquierda recibido',
        'O. centro por izquierda recibido / derecha realizado',
        8,
      ),
      cornerByLeft: new NGSubcategoryData(
        [64],
        [68],
        { accomplished: 23, received: 34 },
        'O. corner por izquierda',
        'O. corner por derecha recibido',
        'O. corner por izquierda realizado / derecha recibido',
        'O. corner por derecha recibido / izquierda realizado',
        9,
      ),
      cornerByRight: new NGSubcategoryData(
        [68],
        [64],
        { accomplished: 24, received: 35 },
        'O. corner por derecha',
        'O. corner por izquierda recibido',
        'O. corner por derecha realizado / izquierda recibido',
        'O. corner por izquierda recibido / derecha realizado',
        10,
      ),
      filteredBallLastLine: new NGSubcategoryData(
        [73],
        [73],
        { accomplished: 25, received: 36 },
        'O. balon filtrado ultima linea',
        'O. balon filtrado ultima linea recibido',
        'O. balon filtrado ultima linea realizado / recibido',
        'O. balon filtrado ultima linea recibido / realizado',
        11,
      ),
      longPass: new NGSubcategoryData(
        [74],
        [74],
        { accomplished: 26, received: 37 },
        'O. pase largo',
        'O. pase largo recibido',
        'O. pase largo realizado / recibido',
        'O. pase largo recibido / realizado',
        12,
      ),
      successfulOffensiveOneOnOne: new NGSubcategoryData(
        [88],
        [88],
        { accomplished: 27, received: 38 },
        'O. 1vs1 exitoso ofensivo',
        'O. 1vs1 exitoso ofensivo recibido',
        'O. 1vs1 exitoso ofensivo realizado / recibido',
        'O. 1vs1 exitoso ofensivo recibido / realizado',
        13,
      ),
      freeKick: new NGSubcategoryData(
        [81],
        [81],
        { accomplished: 28, received: 39 },
        'O. tiro libre',
        'O. tiro libre recibido',
        'O. tiro libre realizado / recibido',
        'O. tiro libre recibido / realizado',
        14,
      ),
      recoveredBallRivalField: new NGSubcategoryData(
        [76],
        [76],
        { accomplished: 29, received: 40 },
        'O. balon recuperado en cancha rival',
        'O. balon recuperado en cancha rival recibido',
        'O. balon recuperado en cancha rival realizado / recibido',
        'O. balon recuperado en cancha rival recibido / realizado',
        15,
      ),
      throwIn: new NGSubcategoryData(
        [89],
        [89],
        { accomplished: 30, received: 41 },
        'O. saque de banda',
        'O. saque de banda recibido',
        'O. saque de banda realizado / recibido',
        'O. saque de banda recibido / realizado',
        16,
      ),
      penalty: new NGSubcategoryData(
        [389],
        [389],
        { accomplished: 31, received: 42 },
        'O. penal',
        'O. penal recibido',
        'O. penal realizado / recibido',
        'O. penal recibido / realizado',
        17,
      ),
    },
  },
  goals: {
    parentInfo: {
      // See inside src/data/next/options.js, id property inside constant analysisTypeOptions
      // and
      // block property inside constant filterOptions to see where these numbers come from
      parentAnalysis: [2],
      analysisTypeBlocks: [1],
    },
    subcategories: {
      fromInsidePenaltyArea: new NGSubcategoryData(
        [57],
        [57],
        { accomplished: 43, received: 48 },
        'Gol pie dentro del area',
        'Gol pie dentro del area recibido',
        'Gol pie dentro del area realizado / recibido',
        'Gol pie dentro del area recibido / realizado',
        18,
      ),
      fromOutsidePenaltyArea: new NGSubcategoryData(
        [326],
        [326],
        { accomplished: 44, received: 49 },
        'Gol pie desde fuera del area en jugada',
        'Gol pie desde fuera del area en jugada recibido',
        'Gol pie desde fuera del area en jugada realizado / recibido',
        'Gol pie desde fuera del area en jugada recibido / realizado',
        19,
      ),
      head: new NGSubcategoryData(
        [56],
        [56],
        { accomplished: 45, received: 50 },
        'Gol de cabeza',
        'Gol de cabeza recibido',
        'Gol de cabeza realizado / recibido',
        'Gol de cabeza recibido / realizado',
        20,
      ),
      freeKick: new NGSubcategoryData(
        [60],
        [60],
        { accomplished: 46, received: 51 },
        'Gol de tiro libre',
        'Gol de tiro libre recibido',
        'Gol de tiro libre realizado / recibido',
        'Gol de tiro libre recibido / realizado',
        21,
      ),
      penalty: new NGSubcategoryData(
        [59],
        [59],
        { accomplished: 47, received: 52 },
        'Gol de penal',
        'Gol de penal recibido',
        'Gol de penal realizado / recibido',
        'Gol de penal recibido / realizado',
        22,
      ),
    },
  },
  cornerKicks: {
    parentInfo: {
      // See inside src/data/next/options.js, id property inside constant analysisTypeOptions
      // and
      // block property inside constant filterOptions to see where these numbers come from
      parentAnalysis: [3],
      analysisTypeBlocks: [1],
    },
    subcategories: {
      cornerKick: new NGSubcategoryData(
        [31, 32],
        [31, 32],
        { accomplished: 53, received: 61 },
        'Tiros de esquina',
        'Tiros de esquina recibidos',
        'Tiros de esquina realizados / recibidos',
        'Tiros de esquina recibidos / realizados',
        31,
      ),
      firstQuarterFaults: new NGSubcategoryData(
        [348, 478],
        [354, 476],
        { accomplished: 54, received: 65 },
        'Faltas 1/4',
        'Faltas 4/4 recibidas',
        'Faltas 1/4 realizadas / Faltas 4/4 recibidas',
        'Faltas 4/4 recibidas / Faltas 1/4 realizadas',
        23,
      ),
      secondQuarterFaults: new NGSubcategoryData(
        [349, 479],
        [353, 475],
        { accomplished: 55, received: 64 },
        'Faltas 2/4',
        'Faltas 3/4 recibidas',
        'Faltas 2/4 realizadas / Faltas 3/4 recibidas',
        'Faltas 3/4 recibidas / Faltas 2/4 realizadas',
        24,
      ),
      rivalFieldFaults: new NGSubcategoryData(
        [350, 480],
        [352, 474],
        { accomplished: 56, received: 62 },
        'Faltas cancha rival',
        'Faltas cancha propia recibidas',
        'Faltas cancha rival realizadas / Faltas cancha propia recibidas',
        'Faltas cancha propia recibidas / Faltas cancha rival realizadas',
        25,
      ),
      totalFaults: new NGSubcategoryData(
        [17, 50],
        [18, 49],
        { accomplished: 58, received: 66 },
        'Faltas totales',
        'Faltas totales recibidas',
        'Faltas totales realizadas / recibidas',
        'Faltas totales recibidas / realizadas',
        26,
      ),
      throwIn: new NGSubcategoryData(
        [210, 212],
        [210, 212],
        { accomplished: 59, received: 67 },
        'Saques de banda',
        'Saques de banda recibidos',
        'Saques de banda realizados / recibidos',
        'Saques de banda recibidos / realizados',
        27,
      ),
      penalty: new NGSubcategoryData(
        [465, 481],
        [466, 477],
        { accomplished: 60, received: 68 },
        'Penales',
        'Penales recibidos',
        'Penales realizados / recibidos',
        'Penales recibidos / realizados',
        28,
      ),
      save: new NGSubcategoryData(
        [39],
        [39],
        { accomplished: 70, received: 70 },
        'Atajada',
        'Atajada recibidas',
        'Atajada realizadas / recibidas',
        'Atajada recibidas / realizadas',
        29,
        true,
      ),
      goalKick: new NGSubcategoryData(
        [28, 264],
        [28, 264],
        { accomplished: 71, received: 71 },
        'Saque de meta',
        'Saque de meta recibidos',
        'Saque de meta realizados / recibidos',
        'Saque de meta recibidos / realizados',
        30,
        true,
      ),
    },
  },
};
