import axios from 'axios';

/**
 * @author Ricardo Manzano
 * @param {string} params.user
 * @param {int} params.recovery
 */
export function emailValidation(params) {
  return axios.post(process.env.VUE_APP_EMAIL_VALIDATION_URL, { ...params });
}

export function changePassword(params) {
  return axios.patch('https://qoi98svz0d.execute-api.us-west-2.amazonaws.com/prod/recovery-password', { ...params });
}

export function tokenValidation(params) {
  return axios.post('https://3pmwpnnk6d.execute-api.us-west-2.amazonaws.com/prod/token-validation', { ...params });
}
