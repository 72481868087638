import { getPlayValueIndexesFromArray } from '@/utils/misc';

/**
 * Defines the index position of each value inside a team stat play.
 */
const teamLivePlayIndexes = [
  'playId',
  'gameId',
  'teamId',
  'playerId',
  'momentOfPlay',
  'categoryId',
  'categoryType',
  'matchLapse',
  'section',
  'matchResult',
  'playingAs',
  'total',
  'coordinate1X',
  'coordinate1Y',
  'coordinate2X',
  'coordinate2Y',
  'coordinate3X',
  'coordinate3Y',
];

let teamLiveStatPlayIndex = null;

export default function getTeamLivePlayIndexes() {
  if (!teamLiveStatPlayIndex) {
    teamLiveStatPlayIndex = getPlayValueIndexesFromArray(teamLivePlayIndexes);
  }
  return teamLiveStatPlayIndex;
}
