import { getPlayValueIndexesFromArray } from '@/utils/misc';

/**
 * Defines the index position of each value inside a team stat play.
 */
const leaguePlayValues = ['categoryId', 'matchLapse', 'section', 'matchResult', 'playingAs', 'total'];

let leaguePlayIndex = null;

export default function getLeaguePlayIndexes() {
  if (!leaguePlayIndex) {
    leaguePlayIndex = getPlayValueIndexesFromArray(leaguePlayValues);
  }
  return leaguePlayIndex;
}
