export default class StoreNames {
  localNames = null;
  globalNames = null;
  baseName = null;
  constructor(names, baseName) {
    this.localNames = names;
    this.baseName = baseName;
    this.generateGlobalNames();
  }

  generateGlobalNames() {
    if (this.localNames && this.baseName) {
      this.globalNames = Object.entries(this.localNames).reduce((accumulator, [key, value]) => {
        accumulator[key] = Object.entries(value).reduce((accumulator2, [key2, value2]) => {
          accumulator2[key2] = `${this.baseName}${value2}`;
          return accumulator2;
        }, {});
        return accumulator;
      }, {});
    }
  }

  getStoreNames(isLocal = false) {
    return isLocal ? this.localNames : this.globalNames;
  }
}
