<template>
  <div id="app" ref="app" :class="modalsTop">
    <Notifications />
    <MessagePremium :title="'FUNCIONALIDAD PREMIUM'" />
    <MessagePremiumV2 :title="'FUNCIONALIDAD PREMIUM'" />
    <ModalInfo />
    <ModalLogin />
    <ProfileSidebar v-if="isLayoutVisible && showSidebar" />
    <BLoading is-full-page :active="isSectionLoading" />
    <!-- Navbar -->
    <Menu
      :show="isLayoutVisible && !isNavBarFreeVisible"
      :width="displayWidth"
      @create-account="SET_AUTH_COMPONENT('Register')"
      @login="SET_AUTH_COMPONENT('Login')"
    />
    <NavBarFree v-if="isNavBarFreeVisible" />
    <div v-if="isNavBarFreeMainVisible" class="navbar-free-main">
      <NavBarFreeMain />
    </div>

    <main
      id="main-container"
      :class="
        isMobile
          ? 'content is-fullheight-with-navbar-and-footer-mobile'
          : 'content is-fullheight-with-navbar-and-footer'
      "
    >
      <RouterView @soy-referee-change="onSoyRefereeChange" />
      <VideoSliderView v-if="false" />
    </main>

    <!-- Modal para los Videos -->
    <BModal
      :active.sync="showVideoModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="['outside', 'escape']"
    >
      <ShowSliderVideoModal />
    </BModal>

    <!-- Modal para los Videos -->
    <BModal
      :active.sync="isVideoModalVisibleComp"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="['outside', 'escape']"
    >
      <ShowVideoModal :src="videoSrc" @onClickClose="onClickCloseOnModalVideo" />
    </BModal>

    <!-- Footer -->
    <footer v-if="isLayoutVisible">
      <Footer @showSliderVideos="showSliderVideo" @showVideoModal="showVideoModal = true" />
    </footer>
  </div>
</template>

<script>
// Store
import store from '@/store';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { BLoading } from 'buefy/dist/esm/loading';
import { BModal } from 'buefy/dist/esm/modal';

export default {
  name: 'App',
  store,
  components: {
    // AuthIndex: () => import('@/views/Auth/index'),
    BLoading,
    BModal,
    ModalLogin: () => import('@/views/Auth/ModalIndex'),
    Footer: () => import('@/components/Layout/Footer'),
    Menu: () => import('@/components/Layout/Menu'),
    MessagePremium: () => import('@/components/Elements/MessagePremium'),
    MessagePremiumV2: () => import('@/components/Elements/MessagePremiumV2'),
    ModalInfo: () => import('@/components/Modals/ModalInfo'),
    NavBarFree: () => import('@/components/NavBars/NavBarFree'),
    NavBarFreeMain: () => import('@/components/Sections/Main/NavBarFreeMain'),
    Notifications: () => import('@/components/Modals/Notifications'),
    ProfileSidebar: () => import('@/components/Menu/ProfileSidebar'),
    ShowVideoModal: () => import('@/components/Modals/Video'),
    ShowSliderVideoModal: () => import('@/components/Modals/VideoSlider'),
    VideoSliderView: () => import('@/components/Views/VideoSlider'),
  },
  data() {
    return {
      showTooltip: false,
      showVideoModal: false,
      showCreateLoginModal: false,
      showLogin: true,
      profile: '/profile',
      mutationObserver: null,
      appHeight: 0,
      targetNode: null,
      firstChild: null,
      soyRefereeHeight: 0,
      routesWithoutLayout: [
        'home',
        'calendar',
        'table',
        'tops',
        'teamprofile',
        'login3pv',
        'main3pv',
        'playerprofile',
        'widget',
        'main',
        'gana',
        'goltipster',
        'goltipster-v2',
        'contact-main',
        'lineups',
        'calendarGS',
        'calendarGS2',
        'calendarCup',
        'mediaplayer',
        'mediaplayer2',
        'bettoolmenu',
        'bet',
        'extendedcalendar',
        'betperresult',
        'terms',
        'soyreferee',
        'playSeeker',
        'landing',
        'topsFull',
        'gsLogout',
        'viewAll',
        'golStatsData',
        'extendedCalendar',
        'topsSection',
        'teams',
        'playerTeams',
        'players',
        'teamProfile',
        'playerProfile',
      ],
    };
  },
  watch: {
    $route(to) {
      this.setLastRouteBeforeLogout(to);
    },
    isVideoModalVisibleComp(newValue) {
      window.parent.postMessage({ message: 'modalShown', isShown: newValue }, '*');
    },
  },
  created() {
    this.setUpHotJar();
    const unwatch = this.$watch(
      () => this.$route,
      route => {
        if (route.name != 'widget' && route.name != 'teamprofile' && route.name != 'playerprofile') {
          window.localStorage.removeItem('colorsConfig');
        }
        unwatch();
      },
    );
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.getUserInfoLocal();
    this.getDataUserGS1();
    this.getDataUser3pv();
    this.setSize();
    this.$nextTick(() => {
      this.setUpMutationObserver();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
    if (this.mutationObserver) {
      // Later, you can stop observing
      this.mutationObserver.disconnect();
    }
  },
  computed: {
    ...mapState(['isSectionLoading', 'displayWidth']),
    ...mapState('general', ['videoSrc', 'isVideoModalVisible']),
    ...mapGetters('auth', ['showAuthComponent']),
    isNavBarFreeMainVisible() {
      return this.$route.name === 'terms';
    },
    isMobile() {
      return this.displayWidth < 769;
    },
    currentPage() {
      return this.$route.path;
    },
    isProfile() {
      return this.currentPage.toLowerCase().includes(this.profile.toLowerCase());
    },
    showSidebar() {
      return !this.isProfile && this.displayWidth < 1210;
    },
    isVideoModalVisibleComp: {
      get() {
        return this.isVideoModalVisible;
      },
      set(nv) {
        this.setVideoModalVisibility(nv);
      },
    },
    isLayoutVisible() {
      if (!this.$route.name) {
        return false;
      }
      const viewParent = this.$route.name ? this.$route.name.split('/')[0] : '/';
      return !this.routesWithoutLayout.includes(viewParent) && this.isNotIframe;
    },
    isNotIframe() {
      return !this.$route.query.is_iframe;
    },
    isNavBarFreeVisible() {
      return this.$route.name === 'payment' || this.$route.name === 'plans';
    },
    modalsTop() {
      const isIframe = window.location !== window.parent.location;
      return isIframe ? ['modals-top'] : [];
    },
  },
  methods: {
    ...mapActions('loginGeneral', ['getUserInfoLocal', 'getDataUserGS1', 'getDataUser3pv']),
    ...mapMutations('loginGeneral', ['setLastRouteBeforeLogout']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('general', ['setVideoModalVisibility']),
    setUpHotJar() {
      const origin = window.location.origin;
      let hotJarId = null;
      if (origin.includes('//golstats.com')) {
        hotJarId = 2956789;
      } else if (origin.includes('//goltipster.com')) {
        hotJarId = 2956797;
      } else if (origin.includes('//bets.golstats.com')) {
        hotJarId = 2956793;
      } else if (origin.includes('//golstats-bets-qa.azurewebsites.net')) {
        hotJarId = 2958630;
      }
      if (hotJarId) {
        this.hotJarFunction(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', null, null, hotJarId);
      }
    },
    hotJarFunction(h, o, t, j, a, r, hotJarId) {
      h.hj =
        h.hj ||
        function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: hotJarId, hjsv: 6 };
      // eslint-disable-next-line no-param-reassign
      a = o.getElementsByTagName('head')[0];
      // eslint-disable-next-line no-param-reassign
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    },
    setSize() {
      const SIZE = typeof this.$refs.app === 'undefined' ? this.displayWidth : this.$refs.app.clientWidth;
      this.$store.commit('SET_DISPLAY_SIZE', SIZE);
      this.checkAppHeight();
    },
    showSliderVideo() {
      this.setVideoModalVisibility(true);
    },
    checkAppHeight() {
      if (this.targetNode && this.firstChild && this.$refs.app) {
        let id = 'golstats';
        const bodyPaddingTop = parseFloat(
          window.getComputedStyle(this.$refs.app.parentNode, null).getPropertyValue('padding-top'),
        );
        // Add 110 or 170 pixels to fix inner views overlay
        let appHeight = this.firstChild.clientHeight;
        if (this.$route.name !== 'soyreferee') {
          appHeight += bodyPaddingTop + (this.$route.name === 'terms' ? 180 : 110);
          if (appHeight < 1000) {
            appHeight = 1000;
          }
        } else {
          id = 'golstatsSlider';
        }
        if (this.appHeight !== appHeight) {
          window.parent.postMessage({ id, message: 'heightChanged', newHeight: appHeight }, '*');
          this.appHeight = appHeight;
        }
      }
    },
    setUpMutationObserver() {
      const vm = this;
      vm.targetNode = document.querySelector('#main-container');
      // Options for the observer (which mutations to observe)
      const config = { attributes: false, childList: true, subtree: true };
      // Callback function to execute when mutations are observed
      const callback = function(mutationsList) {
        // A child node has been added or removed.
        if (vm.targetNode) {
          if (mutationsList.some(mutation => mutation.type === 'childList')) {
            vm.firstChild = vm.targetNode.firstElementChild;
            vm.checkAppHeight();
          }
        }
      };
      // Create an observer instance linked to the callback function
      this.mutationObserver = new MutationObserver(callback);
      // Start observing the target node for configured mutations
      this.mutationObserver.observe(this.targetNode, config);
    },
    onClickCloseOnModalVideo() {
      this.isVideoModalVisibleComp = false;
    },
    onSoyRefereeChange($event) {
      if (this.soyRefereeHeight === 0) {
        this.soyRefereeHeight = $event;
        window.parent.postMessage({ id: 'golstatsSlider', message: 'heightChanged', newHeight: $event }, '*');
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-image: url('https://az755631.vo.msecnd.net/golstats-bets/fondopreload.png'),
    url('https://az755631.vo.msecnd.net/golstats-bets/fondopreload.png');
  background-size: 280px auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
html {
  overflow: hidden !important;
}
main {
  overflow: auto !important;
  background-color: white;
}
/* Estilos para titulos */
.title-wrapper {
  font-family: 'Circular-Std';
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-wrapper.black {
  color: black;
}
.title-wrapper.left {
  justify-content: flex-start;
}
.title-wrapper.right {
  justify-content: flex-end;
}
.title-wrapper.left span {
  margin-left: 0.4rem;
}
.title-wrapper img {
  width: 4.5rem;
  height: 4.5rem;
}

@media (max-width: 400px) {
  .title-wrapper img {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 4.5rem;
  }
  .navbar {
    min-height: 4.5rem !important;
  }
  .navbar-item img {
    min-height: 3rem;
  }
}
.is-clickable {
  cursor: pointer;
}

.field.is-uppercase > .label {
  font-family: 'Circular-Std-Bold' !important;
  text-align: start;
}

.navbar-free-main {
  width: 100%;
  margin-top: -4.5rem;
}

option {
  font-family: 'Avenir-Regular';
}

#app.modals-top {
  & div.modal.is-active {
    padding-top: 3rem;
    justify-content: start;
  }
}
</style>
