import { fetchRetry } from '@/utils/api/fetchRetry';
import { decryptTournaments } from '@/utils/decryptTournaments';

/**
 * @author Fernando Mellone
 */
export async function getScenarios() {
  try {
    const url = `https://aaydh54y1f.execute-api.us-west-2.amazonaws.com/prod/bets_per_scenario`;
    const response = await fetchRetry({ url: url });
    return response.data.scenarios;
  } catch (e) {
    return Promise.reject(e);
  }
}
/**
 * @author Fernando Mellone
 */
//  eslint-disable-next-line
export async function getBetsPerTeam({ teamId }) {
  try {
    // TODO cambiar por dato dinámico
    const url = `https://n4m820goz4.execute-api.us-east-2.amazonaws.com/qa/v2/bets_per_team/${teamId}`;
    const {
      data: { tournaments },
    } = await fetchRetry({ url: url });

    const decryptedTournaments = decryptTournaments(tournaments);

    return decryptedTournaments;
  } catch (e) {
    return Promise.reject(e);
  }
}
/**
 * @author Alejandro Martinez
 * @return {Promise<{type: ArrayConstructor, required: boolean}|{type: Array | ArrayConstructor, required: boolean}|[{score: number[], data: string, visiting_team: number, season_id: number, home_team: number, game_id: number}, {score: number[], data: string, visiting_team: number, season_id: number, home_team: number, game_id: number}]>}
 */
export async function getTournamentsWithScenarios() {
  try {
    // TODO cambiar por dato dinámico
    const url = `https://fkesfn7i5j.execute-api.us-west-2.amazonaws.com/prod/bets_per_team/${1}`;
    const {
      data: { tournaments },
    } = await fetchRetry({ url: url });

    const decryptedTournaments = decryptTournaments(tournaments);

    return decryptedTournaments;
  } catch (e) {
    return Promise.reject(e);
  }
  // try {
  //   const url = `https://fkesfn7i5j.execute-api.us-west-2.amazonaws.com/prod/bets_per_tournaments`;
  //   const {
  //     data: { tournaments },
  //   } = await fetchRetry({ url: url });
  //   return tournaments;
  // } catch (e) {
  //   return Promise.reject(e);
  // }
}

/**
 * @param {number} section
 * @param {number} filter
 * @return {Promise<*>}
 */
export async function getBetDefaultFilter({ /*section,*/ filter }) {
  try {
    const url = `https://n7ielynjxk.execute-api.us-east-2.amazonaws.com/qa/filters?filters=${filter}`;
    const { data } = await fetchRetry({ url: url });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}
