import { getTournaments } from '@/utils/api/tournaments';

export default {
  namespaced: true,
  state: {
    videoSrc: null,
    isVideoModalVisible: false,
    tournaments: [],
    seccionBetsGolTipster: 1,
    tournamentSelected: {},
    sectionAnalytics: 'Banner_Home',
    previewSectionAnalytics: '',
    sectionRef: '',
  },
  getters: {
    getSectionAnalytics(state) {
      return state.sectionAnalytics;
    },
    getPreviewSectionAnalytics(state) {
      return state.previewSectionAnalytics;
    },
    getSeccionBetsGolTipster(state) {
      return state.seccionBetsGolTipster;
    },
  },
  mutations: {
    setSectionRef(state, sectionRef) {
      state.sectionRef = sectionRef;
    },
    setSectionAnalytics(state, section) {
      state.sectionAnalytics = section;
    },
    setSeccionBetsGolTipster(state, section) {
      state.seccionBetsGolTipster = section;
    },
    setPreviewSectionAnalytics(state, section) {
      state.previewSectionAnalytics = section;
    },
    setVideoSrc(state, videoSrc) {
      state.videoSrc = videoSrc;
    },
    setVideoModalVisibility(state, isVideoModalVisible) {
      state.isVideoModalVisible = isVideoModalVisible;
    },
    setSelectedTournament(state, tournament) {
      state.selectedTournament = tournament;
    },
    setTournaments(state, tournaments) {
      state.tournaments = tournaments.map(tournament => {
        return {
          ...tournament.tournament,
          seasons: tournament.seasons,
        };
      });
      state.tournamentSelected = state.tournaments[0];
    },
    setTournamentSelected(state, tournament) {
      state.tournamentSelected = tournament;
    },
  },
  actions: {
    async fetchTournaments({ state, commit }) {
      try {
        if (state.tournaments.length === 0) {
          const tournaments = await getTournaments();
          commit('setTournaments', tournaments);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
