export default {
  namespaced: true,
  state: {
    type: 'success',
    message: '',
    duration: 0,
    alertKey: 0,
    passwordInvalid: false,
  },
  mutations: {
    setType(state, type) {
      state.type = type;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setDuration(state, duration) {
      state.duration = duration;
    },
    setErrorKey(state) {
      state.alertKey++;
    },
    setPasswordInvalid(state, passwordInvalid) {
      state.passwordInvalid = passwordInvalid;
    },
  },
  actions: {
    /**
     * @author José Rafael Gutierrez
     * @param commit
     * @param {String} type
     * @param {String} message
     * @param {Number} duration
     * @param {Boolean} passwordInvalid
     */
    setAlert({ commit }, { type, message, duration = 5000, passwordInvalid = false }) {
      commit('setType', type);
      commit('setMessage', message);
      commit('setDuration', duration);
      commit('setErrorKey');
      commit('setPasswordInvalid', passwordInvalid);
    },
  },
};
