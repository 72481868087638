export default {
  namespaced: true,
  state: {
    authComponent: null,
  },
  getters: {
    showAuthComponent(state) {
      return Boolean(state.authComponent);
    },
  },
  mutations: {
    SET_AUTH_COMPONENT(state, component) {
      state.authComponent = component;
    },
  },
};
